import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TeamRanking } from '../../components';
import {
  AppBarSubTitle,
  EmptyState,
  Loader,
  MainLayoutComponent,
} from '../../../../components';
import { injectIntl } from 'react-intl';
import * as Resources from '../../../../Resources';
import * as teamGeneralRankListActions from '../../../../services/TeamGeneralRanks/TeamGeneralRankList/actions';
import { Redirect } from 'react-router';

class TeamGeneralRanking extends MainLayoutComponent {
  componentDidMount() {
    const { intl } = this.props;
    this.props.activateReturn();
    this.props.handleTitle(intl.formatMessage({ id: 'ranking.title' }));
    this.props.handleSubHeader(
      <appbarsubtitle title="{intl.formatMessage({" id:="" 'ranking.team_general_title'="" })}=""></appbarsubtitle>,
    );
    this.props.handleMaxWidth('md');
    this.props.teamGeneralRankListActions.getTeamGeneralRankList(
      this.props.match.params.period,
    );
  }

  renderLoader() {
    return <loader centered=""></loader>;
  }

  renderEmptyState() {
    const { intl } = this.props;
    return (
      <emptystate title="{intl.formatMessage({" id:="" 'ranking.empty_state_title'="" })}=""></emptystate>
    );
  }

  renderData() {
    const { ranking } = this.props.teamGeneralRankList;
    return (
      <teamranking ranking="{ranking}" teamId="{this.props.match.params.team}"></teamranking>
    );
  }

  render() {
    const { account } = this.props.accountDetail;
    const { ranking, loading } = this.props.teamGeneralRankList;

    if (!account.hasRankingAccess) {
      return <redirect to="{`/`}"></redirect>;
    }

    if (!account.hasGeneralRankAccess) {
      return <redirect to="/"></redirect>;
    }

    return (
      <div>
        {loading && this.renderLoader()}
        {!loading && ranking && ranking.length > 0 && this.renderData()}
        {!loading && ranking && ranking.length == 0 && this.renderEmptyState()}
      </div>
    );
  }
}

const mapStateToProps = ({ accountDetail, teamGeneralRankList }) => ({
  accountDetail,
  teamGeneralRankList,
});

const mapDispatchToProps = (dispatch) => ({
  teamGeneralRankListActions: bindActionCreators(
    teamGeneralRankListActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(TeamGeneralRanking));
