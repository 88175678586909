import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ButtonBase, CardMedia, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, RewardImage } from './components';
import { AccentTag, DefaultText } from '../../../../components';
import * as Resources from '../../../../Resources';
import { useIntl } from 'react-intl';
import '../../../../helpers/StringHelper';

const styles = {
  icon: {
    width: 39,
    height: 39,
  },
  imageContainer: {
    width: '100%',
    position: 'relative',
  },
  name: {
    overflow: 'hidden',
    position: 'relative',
    lineHeight: '1.5em',
    maxHeight: '3em',
    textAlign: 'left',
    '&&:before': {
      content: '"..."',
      position: 'absolute',
      right: 0,
      bottom: 1,
      paddingLeft: 2,
      background: 'white',
    },
    '&&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      width: '1em',
      height: '1em',
      marginTop: '0.2em',
      background: 'white',
    },
  },
  timerContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
};

const Reward = ({
  detailDisabled = false,
  onAddClick,
  reward,
  collaborator,
  ...props
}) => {
  const intl = useIntl();
  const { classes } = props;
  const { account } = props.accountDetail;
  const image = reward.image ? reward.image.path : reward.customImage;

  return (
    <div>
      <grid container="" spacing="{2}">
        <grid item="" xs="{12}">
          <grid container="" spacing="{2}">
            <grid item="" xs="{12}">
              <buttonbase disabled="{detailDisabled}" onClick="{()" =="">
                  props.history.push(
                    `/rewards/detail/${reward.id}${
                      collaborator ? `/?collaborator_id=${collaborator.id}` : ''
                    }`
                  )
                }
                style={{ width: '100%' }}
              >
                <div className="{classes.imageContainer}">
                  <div className="{classes.timerContainer}">
                    <accenttag 5="" style="{{" borderRadius:="" }}="">
                      {intl
                        .formatMessage({ id: 'reward.point_tag' })
                        .format(reward.points)}
                    </accenttag>
                  </div>
                  <rewardimage image="{image}"></rewardimage>
                </div>
              </buttonbase>
            </grid>
            <grid item="" xs="{12}">
              <grid container="" spacing="{2}">
                <grid item="">
                  <buttonbase disabled="{detailDisabled}" onClick="{()" =="">
                      props.history.push(
                        `/rewards/detail/${reward.id}${
                          collaborator
                            ? `/?collaborator_id=${collaborator.id}`
                            : ''
                        }`
                      )
                    }
                    style={{ width: '100%' }}
                  >
                    <cardmedia image="{reward.category.icon.path}" className="{classes.icon}"></cardmedia>
                  </buttonbase>
                </grid>
                <grid item="" xs="">
                  <buttonbase disabled="{detailDisabled}" onClick="{()" =="">
                      props.history.push(
                        `/rewards/detail/${reward.id}${
                          collaborator
                            ? `/?collaborator_id=${collaborator.id}`
                            : ''
                        }`
                      )
                    }
                    style={{ width: '100%' }}
                  >
                    <defaulttext lowercase="" className="{classes.name}" style="{{" width:="" '100%',="" fontSize:="" 16,="" fontWeight:="" 'bold',="" }}="">
                      {reward.name}
                    </defaulttext>
                  </buttonbase>
                </grid>
                {onAddClick &&
                  ((account.role.code === 'C' && reward.type.code !== 'T') ||
                    account.role.code === 'A' ||
                    account.role.code === 'S' ||
                    (['M'].indexOf(account.role.code) >= 0 &&
                      (account.hasRewardCreationAccess ||
                        reward.type.code === 'T'))) && (
                    <grid item="">
                      <button onClick="{()" ==""> onAddClick(reward)}>
                        {intl.formatMessage({ id: 'common.add' })}
                      </button>
                    </grid>
                  )}
              </grid>
            </grid>
          </grid>
        </grid>
      </grid>
    </div>
  );
};

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default withStyles(styles)(connect(mapStateToProps)(withRouter(Reward)));
