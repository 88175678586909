import React, { useState, useRef, useEffect, useContext } from 'react';
import Formsy from 'formsy-react';
import { Grid, CardMedia, TextField, IconButton } from '@material-ui/core';
import { I18nWrapper } from '../../../../../../../';
import {
  Card,
  DefaultText,
  Dialog,
  Avatar,
  RichText,
  RichTextField,
  ProgressButton,
  FileInput,
  HiddenInput,
  PostCommentForm,
  Loader,
  TimeAgo,
  Tooltip,
} from '../../../../../';
import { PostCommentContent } from '../../components';
import { LikesAvatars } from '../../../../../';
import { LikeList } from '../../../LikeList';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImage,
  faPlayCircle,
  faComment,
  faThumbsUp,
  faClock,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { useIntl, injectIntl } from 'react-intl';

import _ from 'lodash';

const styles = {
  dialog: {
    width: 500,
  },
  iconButton: {
    width: 40,
    height: 40,
  },
  textField: {
    width: '100%',
  },
  commentButton: {
    cursor: 'pointer',
  },

  subCommentWrapper: {
    marginLeft: 50,
    marginBottom: 20,
  },
  commentReactionSeparator: {
    userSelect: 'none',
    color: '#ccc',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover p': {
      color: 'rgb(15,111,222)',
      textDecoration: 'underline',
    },
  },
};

const PostCommentReply = ({
  classes,
  reply: replyInput,
  onLike,
  onDislike,
  onDelete,
  onReport,
  onUpdate,
  ...props
}) => {
  const { account } = props.accountDetail;
  const context = useContext(I18nWrapper.Context);
  const timeAgo = new TimeAgo(context.locale);
  const [reply, setReply] = useState(replyInput);
  const [likesCount, setLikesCount] = useState(reply.likes_count);
  const [likeState, setLikeState] = useState(reply.current_user_like);
  const [likes, setLikes] = useState(reply.likes);
  const [initialized, setInitialized] = useState(false);
  const [likeListOpen, setLikeListOpen] = useState(false);
  const isAuthor =
    account.id === reply.author.id || _.get(account, 'role.code') === 'A';
  const intl = useIntl();

  const date = reply.date.toDate();

  useEffect(() => {
    setReply(replyInput);
    setLikesCount(replyInput.likes_count);
    setLikeState(replyInput.current_user_like);
    setLikes(replyInput.likes);
  }, [replyInput]);

  const commentLike = (state) => {
    setLikeState(state);
    setLikesCount(state ? likesCount + 1 : likesCount - 1);
    setLikes(
      state
        ? [...likes, { user: account }]
        : likes.filter((like) => like.id !== account.id),
    );

    if (state) {
      onLike(reply.id);
    } else {
      onDislike(reply.id);
    }
  };

  const updateComment = (reply) => {
    onUpdate(reply);
    setReply(reply);
  };
  const reportWarning =
    _.get(account, 'role.code') === 'A' && reply.report_count > 0;
  return (
    <div>
      <grid container="" spacing="{1}" justifyContent="flex-end">
        <postcommentcontent comment="{reply}" onDelete="{onDelete}" onReport="{onReport}" isAuthor="{isAuthor}" onUpdate="{updateComment}"></postcommentcontent>
        <grid item="" xs="{12}">
          <grid container="" spacing="{1}" justifyContent="flex-end">
            <grid item="" xs="{12}">
              <grid container="" spacing="{1}">
                <grid item="" xs="" container="">
                  <grid 50="" item="" style="{{" marginLeft:="" }}="">
                    {reportWarning ? (
                      <tooltip title="{" reportWarning="" ?="" intl.formatMessage({="" id:="" 'newsfeed.reported_comment',="" })="" :="" null="" }="">
                        <div>
                          <defaulttext lowercase="" style="{{" fontSize:="" 12,="" color:="" '#f44336'="" }}="">
                            <fontawesomeicon 5="" icon="{faExclamationTriangle}" style="{{" marginRight:="" }}=""></fontawesomeicon>
                            {timeAgo.format(date, 'twitter')}
                          </defaulttext>
                        </div>
                      </tooltip>
                    ) : (
                      <defaulttext 12="" lowercase="" style="{{" fontSize:="" }}="">
                        <fontawesomeicon 5="" icon="{faClock}" style="{{" marginRight:="" }}=""></fontawesomeicon>
                        {timeAgo.format(date, 'twitter')}
                      </defaulttext>
                    )}
                  </grid>
                </grid>
                {!reply.id && (
                  <grid item="">
                    <loader></loader>
                  </grid>
                )}
                {reply.id && (
                  <react.fragment>
                    <grid item="">
                      <defaulttext lowercase="" onClick="{()" ==""> commentLike(!likeState)}
                        className={classes.commentButton}
                        style={{ color: likeState ? '#00E58D' : '' }}
                      >
                        {intl.formatMessage({ id: 'newsfeed.like' })}
                      </defaulttext>
                    </grid>
                    {likesCount > 0 && (
                      <react.fragment>
                        <grid item="" className="{classes.commentReactionSeparator}">
                          ·
                        </grid>
                        <grid item="">
                          <grid container="" spacing="{1}" onClick="{()" ==""> setLikeListOpen(true)}
                            className={classes.link}
                          >
                            <grid item="">
                              <defaulttext lowercase="">
                                <fontawesomeicon icon="{faThumbsUp}"></fontawesomeicon>
                              </defaulttext>
                            </grid>
                            <grid item="">
                              <likesavatars likes="{likes}" limit="{3}"></likesavatars>
                            </grid>
                          </grid>
                        </grid>
                      </react.fragment>
                    )}
                  </react.fragment>
                )}
              </grid>
            </grid>
          </grid>
        </grid>
      </grid>
      <likelist open="{likeListOpen}" likes="{likes}" setOpen="{setLikeListOpen}"></likelist>
    </div>
  );
};

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(withStyles(styles)(PostCommentReply));
