import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import { withStyles } from '@material-ui/core/styles';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  AppBarSubTitle,
  Card,
  Loader,
  MainLayoutComponent,
  ProgressButton,
  Select,
  TextField,
  LanguageSelect,
  I18nWrapper,
  DefaultTitle,
  InfoText,
  PasswordField,
  HiddenInput,
  Switch,
} from '../../../../components';
import * as roleListActions from '../../../../services/Roles/RoleList/actions';
import * as teamDetailActions from '../../../../services/Teams/TeamDetail/actions';
import * as userCreationActions from '../../../../services/Users/UserCreation/actions';
import '../../../../helpers/FormsyHelper';
import * as Resources from '../../../../Resources';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { toast } from 'react-toastify';

const styles = {
  panel: {
    backgroundColor: 'initial',
    borderRadius: 'initial',
    boxShadow: 'none',
  },
  panelSummary: {
    padding: 0,
  },
  panelDetails: {
    padding: 0,
  },
};

class CollaboratorCreation extends MainLayoutComponent {
  constructor(props) {
    super(props);
    this.state = {
      display: true,
      role: null,
    };
    this.props.userCreationActions.clearUserCreation();
  }

  componentDidMount() {
    const { intl } = this.props;
    const id = this.props.match.params.teamId;

    this.props.handleTitle(intl.formatMessage({ id: 'menu.admin_button' }));
    this.props.handleSubHeader(
      <appbarsubtitle title="{intl.formatMessage({" id:="" 'admin.user.creation.title'="" })}=""></appbarsubtitle>
    );
    this.props.handleMaxWidth('sm');
    this.props.activateReturn();
    this.props.roleListActions.getRoleList();

    this.props.teamDetailActions.getTeamDetail(id);
  }

  handleValueChange = (name) => (value) => {
    this.setState(
      {
        ...this.state,
        [name]: value,
        display: false,
      },
      () => {
        this.setState({
          ...this.state,
          display: true,
        });
      }
    );
  };

  handleSubmit(model) {
    const payload = Object.assign({}, model, {
      identifiers: model.identifiers.map((identifier, index) => {
        return { order: index, value: identifier ? identifier : '' };
      }),
    });
    this.props.userCreationActions.createUser(payload);
  }

  renderLoader() {
    return <loader centered=""></loader>;
  }

  renderData() {
    const { intl, classes } = this.props;
    const { loading } = this.props.userCreation;
    const { roles } = this.props.roleList;
    const { team } = this.props.teamDetail;
    const collaboratorRole = roles.find((r) => r.code == 'C');
    const managerRole = roles.find((r) => r.code == 'M');

    const LanguageField = () => {
      const context = useContext(I18nWrapper.Context);
      return (
        <languageselect name="locale" label="{intl.formatMessage({" id:="" 'admin.user.locale'="" })}="" initial="{context.locale}" noCard=""></languageselect>
      );
    };

    return (
      <div>
        <formsy onValidSubmit="{this.handleSubmit.bind(this)}">
          <grid container="" spacing="{4}">
            <grid item="">
              <grid container="" spacing="{1}">
                <grid item="" xs="{12}">
                  <defaulttitle isContrast="">
                    {intl.formatMessage({ id: 'admin.user.information_title' })}
                  </defaulttitle>
                </grid>
                <grid item="" xs="{12}">
                  <card>
                    <grid container="" spacing="{2}">
                      <grid item="" xs="{6}">
                        <languagefield></languagefield>
                      </grid>
                      <grid item="" xs="{6}"></grid>
                      <grid item="" xs="{6}">
                        <textfield name="firstname" label="{intl.formatMessage({" id:="" 'admin.user.firstname',="" })}="" fullWidth="" required="" lowercase="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}=""></textfield>
                      </grid>
                      <grid item="" xs="{6}">
                        <textfield name="lastname" label="{intl.formatMessage({" id:="" 'admin.user.lastname',="" })}="" fullWidth="" required="" lowercase="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}=""></textfield>
                      </grid>
                      <grid item="" xs="{12}">
                        <textfield name="email" label="{intl.formatMessage({" id:="" 'admin.user.email'="" })}="" validations="isEmail" fullWidth="" required="" lowercase="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" isEmail:="" 'common.form.email_error',="" }}=""></textfield>
                      </grid>

                      <hiddeninput name="role" value="{roles.find((r)" ==""> r.code == 'C').id}
                      />
                      <grid item="" xs="{6}">
                        <select name="team" label="{intl.formatMessage({" id:="" 'admin.user.team'="" })}="" options="{[team]}" initial="{team.id}" optionValueName="id" optionTextName="name" fullWidth="" disabled=""></select>
                      </grid>
                    </hiddeninput></grid>
                  </card>
                </grid>
              </grid>
            </grid>

            <grid 0="" item="" xs="{12}" style="{{" paddingTop:="" 0,="" paddingBottom:="" }}="">
              <expansionpanel className="{classes.panel}">
                <expansionpanelsummary expandIcon="{<ExpandMoreIcon"></expansionpanelsummary>}
                  className={classes.panelSummary}
                >
                  <defaulttitle>
                    {intl.formatMessage({ id: 'admin.user.identifiers' })}
                  </defaulttitle>
                
                <expansionpaneldetails className="{classes.panelDetails}">
                  <card>
                    <grid container="" spacing="{4}">
                      {[...Array(5).keys()].map((order) => (
                        <grid item="" xs="{6}" key="{`identifier_${order}`}">
                          <textfield name="{`identifiers[${order}]`}" label="{intl" .formatMessage({="" id:="" 'admin.user.identifier'="" })="" .format(order="" +="" 1)}="" fullWidth="" lowercase=""></textfield>
                        </grid>
                      ))}
                    </grid>
                  </card>
                </expansionpaneldetails>
              </expansionpanel>
            </grid>
            <grid item="">
              <grid container="" spacing="{1}">
                <grid item="" xs="{12}">
                  <defaulttitle isContrast="">
                    {intl.formatMessage({
                      id: 'admin.user.creation.password_title',
                    })}
                  </defaulttitle>
                </grid>
                <grid item="" xs="{12}">
                  <card>
                    <grid container="" spacing="{2}">
                      <grid item="" xs="{12}">
                        <infotext lowercase="">
                          {intl.formatMessage({
                            id: 'admin.user.password_info',
                          })}
                        </infotext>
                      </grid>
                      <grid item="" xs="{6}">
                        <passwordfield type="password" name="password" onChange="{this.handleValueChange('password').bind(" this="" )}="" label="{intl.formatMessage({" id:="" 'admin.user.password_field',="" })}="" fullWidth="" lowercase="" validations="{{" hasUppercaseCharacter:="" true,="" hasLowercaseCharacter:="" hasSpecialCharacter:="" hasMoreCharactersThan:="" 8,="" hasDigitCharacter:="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" 'common.form.has_uppercase_character',="" 'common.form.has_lowercase_character',="" 'common.form.has_special_character',="" intl="" .formatMessage({="" 'common.form.has_more_characters_than',="" })="" .format(8),="" 'common.form.has_digit_character',=""></passwordfield>
                      </grid>
                      <grid item="" xs="{6}">
                        <passwordfield type="password" name="paswwordConfirm" label="{intl.formatMessage({" id:="" 'admin.user.password_confirm',="" })}="" fullWidth="" lowercase="" validations="{" this.state.password="" ?="" 'equalsField:password'="" :="" null="" }="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" equalsField:="" 'common.form.password_not_match_error',="" }}=""></passwordfield>
                      </grid>
                    </grid>
                  </card>
                </grid>
              </grid>
            </grid>

            <grid item="" xs="{12}">
              <switch name="{'send_email'}" initial="{false}" label="{intl.formatMessage({" id:="" 'admin.user.creation.send_email',="" })}=""></switch>
            </grid>

            <grid item="" xs="{12}">
              <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" centered="" loading="{loading}"></progressbutton>
            </grid>
          </grid>
        </formsy>
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    const { roles, loading: roleListLoading } = this.props.roleList;
    const { team, loading: teamLoading } = this.props.teamDetail;
    const { success } = this.props.userCreation;
    const loading = roleListLoading || teamLoading;

    if (success) {
      this.props.userCreationActions.clearUserCreation();
      this.props.history.goBack();
      toast.success(intl.formatMessage({ id: 'admin.user.creation.success' }));
    }

    return (
      <div>
        {loading && this.renderLoader()}
        {!loading && roles && team && this.renderData()}
      </div>
    );
  }
}

const mapStateToProps = ({ roleList, teamDetail, userCreation }) => ({
  roleList,
  teamDetail,
  userCreation,
});

const mapDispatchToProps = (dispatch) => ({
  roleListActions: bindActionCreators(roleListActions, dispatch),
  teamDetailActions: bindActionCreators(teamDetailActions, dispatch),
  userCreationActions: bindActionCreators(userCreationActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles)(CollaboratorCreation)));
