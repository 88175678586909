import React, {useEffect} from 'react';
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {CloseCircle} from 'iconsax-react';
import {Button, Drawer, CircularProgress, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

import {HierarchyNodeUser} from './components'
import {allHierarchyNodeUsersFetchingStart} from '../../../../../../../../../features/base/allPagesFetchingFromApi/slices';
import {setHierarchyNodeUser, useAuth} from "../../../../../../../../../../auth";
import {HierarchyNodeUserStatuses} from "../../../../../../../../../enums";

const useStyles = makeStyles((theme) => ({
    userList: {
        alignItems: 'center',
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
    },
}));

const HierarchyNodeUserSwitcher = ({open, setOpen}) => {
    const {hierarchyNodeUser} = useAuth();
    const dispatch = useDispatch();
    const intl = useIntl();
    const {contract} = useParams();
    const classes = useStyles();

    const allHierarchyNodeUsersFetchingState = useSelector(state => state.allHierarchyNodeUsersFetching);
    const {data, loading} = allHierarchyNodeUsersFetchingState;
    const hasUsers = Array.isArray(data) && data.length > 0;

    const handleClose = (force = false) => {
        if (force || hierarchyNodeUser != null) {
            setOpen(false);
        }
    }

    const handleUserClick = (user) => {
        const userActive = user.status !== HierarchyNodeUserStatuses.INACTIVE;

        if (!userActive) return;

        dispatch(setHierarchyNodeUser(user));
        handleClose(true);
    }

    const renderLoading = () => <circularprogress></circularprogress>;

    const renderUsers = () => {
        return (
            <div className="{classes.userList}">
                {data.map(user => (
                    <hierarchynodeuser key="{user.uuid}" user="{user}" onClick="{()" ==""> handleUserClick(user)}/>
                ))}
            </hierarchynodeuser></div>
        );
    }

    useEffect(() => {
        if ((hierarchyNodeUser === null || hierarchyNodeUser._context.hierarchy_node.code !== contract) && !open) {
            setOpen(true);
        }
    }, [contract, hierarchyNodeUser, open, setOpen]);

    useEffect(() => {
        dispatch(allHierarchyNodeUsersFetchingStart([contract, true]));
    }, [contract, dispatch]);

    return (
        <>
            <drawer anchor="{'right'}" open="{open}" onClose="{handleClose}">
                <div className="{'header'}">
                    <button variant="{'contained'}" color="{'primary'}" size="{'small'}" className="{'icon" light'}="" onClick="{handleClose}">
                        <closecircle></closecircle>
                    </button>
                </div>

                <div className="{'content'}">
                    <typography variant="{'h2'}" component="{'h2'}" className="{'title'}">
                        {intl.formatMessage({id: 'spider.hierarchy_node_user_switcher.title'})}
                    </typography>

                    {hasUsers ? renderUsers() :
                        loading ? renderLoading() : null}
                </div>
            </drawer>
        </>
    );
}

export default HierarchyNodeUserSwitcher;
