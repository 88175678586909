import React from 'react';
import {Button as MuiButton} from '@material-ui/core';

const Button = ({
                    children,
                    color,
                    disabled,
                    load = false,
                    onClick,
                    size,
                    type,
                    variant,
                    className,
                    style
                }) => {
    const renderContent = () => {
        if (load) {
            return '...';
        }

        return children;
    };

    return (
        <>
            <muibutton disabled="{disabled}" variant="{variant}" color="{color}" size="{size}" type="{type}" className="{className}" disableRipple="" style="{style}" onClick="{onClick}">
                {renderContent()}
            </muibutton>
        </>
    );
};

export default Button;
