import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faStar, faUsers } from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  DefaultText,
  DefaultTitle,
  GridLink,
  Loader,
  MainLayoutComponent,
  Select,
} from '../../../../components';
import { useIntl, injectIntl } from 'react-intl';
import * as currentPeriodDetailActions from '../../../../services/Periods/CurrentPeriodDetail/actions';
import * as nextPeriodListActions from '../../../../services/Periods/NextPeriodList/actions';

class AdminHome extends MainLayoutComponent {
  constructor(props) {
    super(props);
    this.period = null;
  }

  refresh(period) {
    var url = `/admin?period=${period}`;
    this.props.history.replace(url);
  }

  loadData() {
    const params = new URLSearchParams(window.location.search);
    const period = params.get('period');
    if (period != this.period) {
      this.period = period;
      this.setState({
        ...this.state,
      });
    }
  }

  componentDidMount() {
    const { intl } = this.props;
    this.props.handleTitle(intl.formatMessage({ id: 'admin.title' }));
    this.props.currentPeriodDetailActions.getCurrentPeriodDetail();
    this.props.nextPeriodListActions.getNextPeriodList();
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.loadData();
  }

  renderLoader() {
    return <loader centered=""></loader>;
  }

  handleYearChange(value) {
    this.refresh(value);
  }

  renderData() {
    const { intl } = this.props;
    const { period: currentPeriod } = this.props.currentPeriodDetail;
    const { periods: nextPeriods } = this.props.nextPeriodList;
    const periods = [currentPeriod].concat(nextPeriods);
    const periodId = this.period ? this.period : currentPeriod.id;
    const { account } = this.props.accountDetail;

    return (
      <div>
        <grid container="" spacing="{4}">
          <grid item="" xs="{6}" container="" spacing="{4}">
            <grid item="" xs="{12}" container="" spacing="{1}">
              <grid item="" xs="{12}">
                <defaulttitle isContrast="">
                  <fontawesomeicon icon="{faUsers}"></fontawesomeicon>{' '}
                  {intl.formatMessage({ id: 'admin.home.user_area' })}
                </defaulttitle>
              </grid>
              <grid item="" xs="{12}">
                <card>
                  <grid container="" spacing="{2}">
                    <gridlink item="" xs="{12}" component="{Link}" to="/admin/users">
                      <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                        {intl.formatMessage({ id: 'admin.home.user_link' })}
                      </defaulttext>
                    </gridlink>

                    <gridlink item="" xs="{12}" component="{Link}" to="/admin/organization">
                      <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                        {intl.formatMessage({
                          id: 'admin.home.organization_link',
                        })}
                      </defaulttext>
                    </gridlink>
                    <gridlink item="" xs="{12}" component="{Link}" to="/admin/access">
                      <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                        {intl.formatMessage({
                          id: 'admin.home.permission_link',
                        })}
                      </defaulttext>
                    </gridlink>
                    <gridlink item="" xs="{12}" component="{Link}" to="/admin/notifications">
                      <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                        {intl.formatMessage({
                          id: 'admin.home.notification_link',
                        })}
                      </defaulttext>
                    </gridlink>
                  </grid>
                </card>
              </grid>
            </grid>
            <grid item="" xs="{12}" container="" spacing="{1}" alignItems="flex-start">
              <grid item="" xs="{12}">
                <defaulttitle isContrast="">
                  <fontawesomeicon icon="{faDatabase}"></fontawesomeicon>{' '}
                  {intl.formatMessage({ id: 'admin.home.data_area' })}
                </defaulttitle>
              </grid>
              <grid item="" xs="{12}">
                <card>
                  <grid container="" spacing="{2}">
                    <gridlink item="" xs="{12}" component="{Link}" to="/admin/partners">
                      <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                        {intl.formatMessage({ id: 'admin.home.partner_link' })}
                      </defaulttext>
                    </gridlink>
                    <gridlink item="" xs="{12}" component="{Link}" to="/admin/toucan">
                      <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                        {intl.formatMessage({ id: 'admin.toucan' })}
                      </defaulttext>
                    </gridlink>
                    <gridlink item="" xs="{12}" component="{Link}" to="/admin/reports">
                      <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                        {intl.formatMessage({ id: 'admin.home.report_link' })}
                      </defaulttext>
                    </gridlink>
                    <gridlink item="" xs="{12}" component="{Link}" to="/admin/imports/logs">
                      <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                        {intl.formatMessage({ id: 'admin.home.import_link' })}
                      </defaulttext>
                    </gridlink>
                    <gridlink item="" xs="{12}" component="{Link}" to="/admin/logo">
                      <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                        {intl.formatMessage({
                          id: 'admin.logo_and_colors.title',
                        })}
                      </defaulttext>
                    </gridlink>
                  </grid>
                </card>
              </grid>
            </grid>
          </grid>
          <grid item="" xs="{6}">
            <div>
              <grid container="" spacing="{1}">
                <grid item="" xs="{12}">
                  <defaulttitle isContrast="">
                    <fontawesomeicon icon="{faStar}"></fontawesomeicon>{' '}
                    {intl.formatMessage({ id: 'admin.home.indicator_area' })}
                  </defaulttitle>
                </grid>
                <grid item="" xs="{12}">
                  <card>
                    <grid container="" spacing="{2}">
                      <grid item="" xs="{12}">
                        <formsy>
                          <select name="{'year'}" label="{intl.formatMessage({" id:="" 'admin.home.year_label',="" })}="" options="{periods}" emptyDisabled="" initial="{periodId}" optionValueName="{'id'}" optionTextName="{'name'}" fullWidth="" onChange="{this.handleYearChange.bind(this)}"></select>
                        </formsy>
                      </grid>
                      <gridlink item="" xs="{12}" component="{Link}" to="{`/admin/periods/${periodId}/points`}">
                        <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                          {intl.formatMessage({ id: 'admin.home.point_link' })}
                        </defaulttext>
                      </gridlink>
                      <gridlink item="" xs="{12}" component="{Link}" to="{`/admin/categories`}">
                        <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                          {intl.formatMessage({
                            id: 'admin.home.category_link',
                          })}
                        </defaulttext>
                      </gridlink>
                      <gridlink item="" xs="{12}" component="{Link}" to="{`/admin/periods/${periodId}/goals`}">
                        <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                          {intl.formatMessage({ id: 'admin.goal.title' })}
                        </defaulttext>
                      </gridlink>
                      <gridlink item="" xs="{12}" component="{Link}" to="{`/admin/periods/${periodId}/goal-levels/mode-select`}">
                        <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                          {intl.formatMessage({
                            id: 'admin.home.goal_level_link',
                          })}
                        </defaulttext>
                      </gridlink>
                      <gridlink item="" xs="{12}" component="{Link}" to="{`/admin/periods/${periodId}/challenges`}">
                        <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                          {account.challengeWording ||
                            intl.formatMessage({
                              id: 'challenge.title_plural',
                            })}
                        </defaulttext>
                      </gridlink>
                      <gridlink item="" xs="{12}" component="{Link}" to="{`/admin/periods/${periodId}/badges`}">
                        <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                          {intl.formatMessage({ id: 'admin.home.badge_link' })}
                        </defaulttext>
                      </gridlink>
                      <gridlink item="" xs="{12}" component="{Link}" to="{`/admin/periods/${periodId}/levels`}">
                        <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                          {intl.formatMessage({ id: 'admin.home.level_link' })}
                        </defaulttext>
                      </gridlink>
                      <gridlink item="" xs="{12}" component="{Link}" to="{`/admin/reward-categories`}">
                        <defaulttext 15="" lowercase="" style="{{" fontSize:="" }}="">
                          {intl.formatMessage({ id: 'admin.home.reward_link' })}
                        </defaulttext>
                      </gridlink>
                    </grid>
                  </card>
                </grid>
              </grid>
            </div>
          </grid>
        </grid>
      </div>
    );
  }

  render() {
    const { period: currentPeriod, loading: currentPeriodDetailLoading } =
      this.props.currentPeriodDetail;
    const { periods: nextPeriods, loading: nextPeriodListLoading } =
      this.props.nextPeriodList;
    const loading = currentPeriodDetailLoading || nextPeriodListLoading;

    return (
      <div>
        {loading && this.renderLoader()}
        {!loading && currentPeriod && nextPeriods && this.renderData()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  currentPeriodDetailActions: bindActionCreators(
    currentPeriodDetailActions,
    dispatch
  ),
  nextPeriodListActions: bindActionCreators(nextPeriodListActions, dispatch),
});

const mapStateToProps = ({
  currentPeriodDetail,
  nextPeriodList,
  accountDetail,
}) => ({
  currentPeriodDetail,
  nextPeriodList,
  accountDetail,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AdminHome));
