import React from 'react';
import {Dialog, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import Button from "../Button";
import {CloseSquare} from "iconsax-react";
import {useIntl} from "react-intl";

const PreventLeavingWithoutSavingDialog = ({ leaveWithoutRegister, submitModifications, onDialogClose, ...props }) => {
    const intl = useIntl()
    return (
        <dialog {...props}="" style="{{" width:="" '550px',="" margin:="" 'auto'="" }}="">
            <dialogtitle style="{{" display:="" 'flex="" !important',="" flexDirection:="" 'row-reverse="" !important'="" }}="">
                <button variant="{'contained'}" color="{'primary'}" className="{'size-tiny" icon="" light'}="" onClick="{onDialogClose}">
                    <closesquare></closesquare>
                </button>
            </dialogtitle>
            <dialogcontent style="{{" display:="" 'flex',="" flexDirection:="" 'column',="" textAlign:="" 'center',="" padding:="" '0rem="" 2rem="" 2rem',="" marginX:="" 'auto',="" gap:="" '1rem'="" }}="">
                <typography variant="{'h1'}" component="{'h1'}" className="{'underline-center'}">
                    {intl.formatMessage({id: 'spider.preventSaveDialog.title'})}
                </typography>

                <typography variant="{'subtitle2'}">
                    {intl.formatMessage({id: 'spider.preventSaveDialog.subtitle'})}
                </typography>

                <div style="{{" display:="" 'flex',="" justifyContent:="" 'space-evenly',="" gap:="" '1rem'="" }}="">
                    <button variant="{'outlined'}" size="{'small'}" onClick="{leaveWithoutRegister}">
                        {intl.formatMessage({id: 'spider.preventSaveDialog.leaveAction'})}
                    </button>
                    <button variant="{'contained'}" color="{'primary'}" size="{'small'}" onClick="{submitModifications}">
                        {intl.formatMessage({id: 'spider.preventSaveDialog.saveAction'})}
                    </button>
                </div>
            </dialogcontent>
        </dialog>
    )
}

export default PreventLeavingWithoutSavingDialog;
