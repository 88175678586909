import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  AccentText,
  Card,
  DefaultText,
  DefaultTitle,
  EmptyState,
  ErrorText,
  InfoText,
  Loader,
  ProgressButton,
  TextField,
} from '../../../../../../../../components';
import * as goalDetailActions from '../../../../../../../../services/Goals/GoalDetail/actions';
import * as teamGoalListActions from '../../../../../../../../services/TeamGoals/TeamGoalList/actions';
import * as teamGoalListUpdateActions from '../../../../../../../../services/TeamGoals/TeamGoalListUpdate/actions';
import '../../../../../../../../helpers/FormsyHelper';
import { injectIntl } from 'react-intl';
import * as Resources from '../../../../../../../../Resources';
import { toast } from 'react-toastify';

const styles = {
  title: {
    marginBottom: 16,
  },
  indicators: {
    marginBottom: 32,
  },
  formFooter: {
    marginTop: 32,
  },
  error: {
    marginBottom: 16,
  },
};

class TeamGoalList extends Component {
  constructor(props) {
    super(props);
    this.date = null;
    this.state = {
      targetSum: null,
    };
  }

  loadData(props) {
    const date = props.date;
    if (date != this.date) {
      this.date = date;
      const definitionId = props.goalDefinitionDetail.definition.id;
      props.goalDetailActions.getGoalDetail(definitionId, date);
      props.teamGoalListActions.getTeamGoalListByDefinition(definitionId, date);
    }
  }

  componentDidMount() {
    this.loadData(this.props);
  }

  componentWillReceiveProps(props) {
    this.loadData(props);
  }

  renderLoader() {
    return (
      <div>
        <loader centered=""></loader>
      </div>
    );
  }

  renderEmptyState() {
    const { intl } = this.props;
    return (
      <div>
        <emptystate title="{intl.formatMessage({" id:="" 'admin.goal.list.empty_state_title',="" })}="" message="{intl.formatMessage({" 'admin.goal.list.empty_state_message',=""></emptystate>
      </div>
    );
  }

  convertToGoals(model) {
    const goals = [];
    const keys = Object.keys(model);
    keys.map((key) => {
      if (key != 'remainingTarget') {
        const goal = { id: key, target: model[key] };
        goals.push(goal);
      }
    });
    return goals;
  }

  handleChange(model) {
    const goals = this.convertToGoals(model);
    var targetSum = goals
      .map((goal) => Number(goal.target))
      .reduce((a, b) => a + b);
    this.setState({
      ...this.state,
      targetSum: targetSum,
    });
  }

  handleSubmit(model) {
    const goals = this.convertToGoals(model);
    this.props.teamGoalListUpdateActions.updateTeamGoalList(goals);
  }

  renderForm() {
    const { intl } = this.props;
    const { classes } = this.props;
    const { goals } = this.props.teamGoalList;
    const { goal: parentGoal } = this.props.goalDetail;
    const { loading } = this.props.teamGoalListUpdate;
    const { account } = this.props.accountDetail;
    const goalCount = goals.length;
    const isRate = parentGoal.definition.kpi.unit.isRate;
    const maxTarget = parentGoal.target;
    var initialAllTarget = goals
      .map((goal) => Number(goal.target))
      .reduce((a, b) => a + b);
    if (isRate)
      initialAllTarget =
        goalCount > 0 ? Math.ceil(initialAllTarget / goalCount) : 0;
    var allTarget = initialAllTarget;
    if (this.state.targetSum != null && !isRate)
      allTarget = this.state.targetSum;
    if (this.state.targetSum != null && isRate)
      allTarget =
        goalCount > 0 ? Math.ceil(this.state.targetSum / goalCount) : 0;
    const remainingTarget = maxTarget - allTarget;
    const canSubmit =
      remainingTarget >= 0 || parentGoal.definition.allow_over_target;
    const now = new Date();
    const isPast = new Date(parentGoal.end * 1000) < now;
    const readonly = !parentGoal.definition.isActive;
    const editable =
      !isPast ||
      (parentGoal.definition.past_editable && account.role.code === 'A');

    return (
      <div>
        <defaulttitle className="{classes.title}">Indicateurs</defaulttitle>
        <div className="{classes.indicators}">
          <card>
            <grid container="" justify="space-between">
              <grid item="">
                <defaulttext>
                  {intl.formatMessage({
                    id: 'admin.goal.edit.max_target_label',
                  })}
                </defaulttext>
                <infotext>{maxTarget}</infotext>
              </grid>
              <grid item="">
                <defaulttext>
                  {intl.formatMessage({
                    id: 'admin.goal.edit.all_target_label',
                  })}
                </defaulttext>
                <infotext>{allTarget}</infotext>
              </grid>
              <grid item="">
                <defaulttext>
                  {intl.formatMessage({
                    id: 'admin.goal.edit.remaining_target_label',
                  })}
                </defaulttext>
                {remainingTarget >= 0 && (
                  <accenttext>{remainingTarget}</accenttext>
                )}
                {remainingTarget < 0 && (
                  <errortext>{remainingTarget}</errortext>
                )}
              </grid>
            </grid>
          </card>
        </div>
        <formsy onChange="{this.handleChange.bind(this)}" onValidSubmit="{this.handleSubmit.bind(this)}">
          <grid container="" spacing="{2}">
            {goals.map((goal) => {
              return (
                <grid key="{goal.id}" item="" xs="{3}">
                  <textfield type="number" name="{goal.id}" label="{goal.team.name}" initial="{goal.target}" fullWidth="" required="" disabled="{!editable" ||="" readonly}="" validations="{{" isMoreThanOrEquals:="" 0,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" id:="" 'common.form.required_error',="" }),="" "L'objectif="" doit="" être="" supérieur="" ou="" égal="" à="" 0.",=""></textfield>
                </grid>
              );
            })}
          </grid>
          {!readonly && (
            <div className="{classes.formFooter}">
              {!canSubmit && (
                <errortext className="{classes.error}" align="center">
                  Veuillez respecter l'objectif total alloué pour la période
                  sélectionnée
                </errortext>
              )}
              <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" loading="{loading}" disabled="{!canSubmit" ||="" !editable="" readonly}="" centered=""></progressbutton>
            </div>
          )}
        </formsy>
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    const { goals, loading: teamGoalListLoading } = this.props.teamGoalList;
    const { loading: goalDetailLoading } = this.props.goalDetail;
    const loading = teamGoalListLoading || goalDetailLoading;
    const hasGoals = goals && goals.length > 0;
    const { success, error } = this.props.teamGoalListUpdate;

    if (success) {
      // this.props.goalDefinitionUpdateActions.clearGoalDefinitionUpdate()
      // this.props.history.goBack()
      this.props.teamGoalListUpdateActions.updateTeamGoalListClear();
      toast.success(intl.formatMessage({ id: 'admin.goal.edit.success' }));
    }

    if (error) {
      this.props.teamGoalListUpdateActions.updateTeamGoalListClear();
      toast.error(intl.formatMessage({ id: 'admin.goal.edit.error' }));
    }

    return (
      <div>
        {loading && this.renderLoader()}
        {!loading && hasGoals && this.renderForm()}
        {!loading && !hasGoals && this.renderEmptyState()}
      </div>
    );
  }
}

const mapStateToProps = ({
  goalDefinitionDetail,
  goalDetail,
  teamGoalList,
  teamGoalListUpdate,
  accountDetail,
}) => ({
  goalDefinitionDetail,
  goalDetail,
  teamGoalList,
  teamGoalListUpdate,
  accountDetail,
});

const mapDispatchToProps = (dispatch) => ({
  goalDetailActions: bindActionCreators(goalDetailActions, dispatch),
  teamGoalListActions: bindActionCreators(teamGoalListActions, dispatch),
  teamGoalListUpdateActions: bindActionCreators(
    teamGoalListUpdateActions,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(TeamGoalList)));
