import React from 'react';
import { connect } from 'react-redux';
import { CardMedia, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ImageInput } from './components';
import {
  Card,
  DefaultTitle,
  InfoText,
  Select,
  TextField,
  RichTextField,
} from '../../../../../../components';
import * as Resources from '../../../../../../Resources';
import { useIntl } from 'react-intl';
import _ from 'lodash';

const useStyles = makeStyles({
  image: {
    height: '100%',
    width: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'top',
  },
});

const Infos = ({
  description,
  end,
  customImage,
  image,
  images,
  isUpdate,
  name,
  period,
  onTypeChange,
  start,
  type,
  types,
  ...props
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { account } = props.accountDetail;
  const hasManager = account.role.code === 'M';
  const [selectedImageId, setSelectedImageId] = React.useState(
    _.get(image, 'id', image)
  );

  const selectedImage = customImage
    ? { path: customImage }
    : images.find((x) => x.id === selectedImageId);

  const [selectedImagePath, setSelectedImagePath] = React.useState(
    selectedImage ? selectedImage.path : null
  );

  function handleImageChange(id) {
    if (id instanceof Blob) {
      var reader = new FileReader();
      reader.onloadend = function (e) {
        setSelectedImagePath(reader.result);
      }.bind(this);
      reader.readAsDataURL(id);
    } else {
      setSelectedImageId(Number(id));
      setSelectedImagePath(images.find((x) => x.id === Number(id)).path);
    }
  }
  return (
    <div>
      <grid container="" spacing="{1}">
        <grid item="" xs="{12}">
          <defaulttitle isContrast="">
            {intl.formatMessage({ id: 'challenge.form.info_area' })}
          </defaulttitle>
        </grid>
        <grid item="" xs="{12}">
          <card>
            <grid container="" spacing="{2}">
              <grid item="" xs="{8}">
                <div>
                  <grid container="" spacing="{2}">
                    <grid item="" xs="{12}">
                      <textfield lowercase="" fullWidth="" initial="{name}" label="{intl.formatMessage({" id:="" 'challenge.form.info_name_label',="" })}="" name="name" required="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}=""></textfield>
                    </grid>
                    <grid item="" xs="{12}">
                      <richtextfield fullWidth="" initial="{description}" label="{intl.formatMessage({" id:="" 'challenge.form.info_description_label',="" })}="" name="description" required="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}=""></richtextfield>
                    </grid>
                  </grid>
                </div>
              </grid>
              <grid item="" xs="{4}">
                {!selectedImagePath && (
                  <grid alignItems="{'center'}" container="" justify="{'center'}" style="{{" height:="" '100%'="" }}="">
                    <grid item="">
                      <infotext align="{'center'}">
                        {intl.formatMessage({
                          id: 'challenge.form.info_no_image_text',
                        })}
                      </infotext>
                    </grid>
                  </grid>
                )}
                {selectedImagePath && (
                  <div style="{{" minHeight:="" 210,="" height:="" '100%'="" }}="">
                    <cardmedia className="{classes.image}" image="{selectedImagePath}"></cardmedia>
                  </div>
                )}
              </grid>

              <grid item="" xs="{12}">
                <imageinput images="{images}" initial="{selectedImageId" ||="" customImage}="" label="{intl.formatMessage({" id:="" 'challenge.form.info_image_label',="" })}="" name="{'image'}" required="" onChange="{handleImageChange}"></imageinput>
              </grid>
            </grid>
          </card>
        </grid>
      </grid>
    </div>
  );
};

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(Infos);
