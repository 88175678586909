import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import {
  AppBarSubTitle,
  BlueText,
  Card,
  DefaultText,
  BigText,
  InfoText,
  Loader,
  MainLayoutComponent,
  ProgressButton,
  Select,
  Switch,
  TextField,
  Tooltip,
  Stepper,
  RichTextField,
  TransferList,
  GreenRadio,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DefaultTitle,
} from '../../../../../../../../components';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const KpiStep = ({ categories, kpis, initial }) => {
  const [kpiId, setKpiId] = useState(initial);
  const [category, setCategory] = useState();
  const intl = useIntl();
  const kpi = kpiId ? kpis.find((k) => k.id == kpiId) : null;
  const unit = kpi
    ? kpi.unit.name + (kpi.unit.symbol ? ` (${kpi.unit.symbol})` : '')
    : null;
  const format = kpi && kpi.manual ? 'Manuel' : 'Automatique';

  return (
    <react.fragment>
      <grid container="" spacing="{1}">
        <grid item="" xs="{12}">
          <defaulttitle isContrast="">
            {intl.formatMessage({ id: 'challenge.form.info_area' })}
          </defaulttitle>
        </grid>
        <grid item="" xs="{12}">
          <card>
            <grid container="" spacing="{1}">
              <grid item="" xs="{12}" sm="{6}">
                <grid container="" direction="column" spacing="{2}">
                  <grid item="">
                    <select name="kpiCategory" emptyText="{intl.formatMessage({" id:="" 'filter.all_category_label',="" })}="" label="{intl.formatMessage({" 'admin.goal.category_label',="" options="{categories}" optionValueName="id" optionTextName="name" fullWidth="" onChange="{setCategory}"></select>
                  </grid>
                  <grid item="">
                    <select name="kpi" label="{intl.formatMessage({" id:="" 'admin.goal.kpi_label'="" })}="" initial="{kpiId}" options="{kpis.filter(" (kpi)="">
                          !category ||
                          _.get(kpi, 'category.id') === parseInt(category)
                      )}
                      optionValueName='id'
                      optionTextName='name'
                      onChange={setKpiId}
                      fullWidth
                      required
                    />
                  </select></grid>
                </grid>
              </grid>
              <grid item="" xs="{12}" sm="{6}">
                <grid container="" direction="column" spacing="{2}">
                  <grid item="">
                    <infotext>
                      {intl.formatMessage({ id: 'admin.goal.unit_label' })}
                    </infotext>
                    <defaulttext 19="" lowercase="" style="{{" minHeight:="" }}="">
                      {unit}
                    </defaulttext>
                  </grid>
                  <grid item="">
                    <infotext>
                      {intl.formatMessage({
                        id: 'admin.goal.periodicity_label',
                      })}
                    </infotext>
                    <defaulttext 19="" lowercase="" style="{{" minHeight:="" }}="">
                      {_.get(kpi, 'periodicity.description')}
                    </defaulttext>
                  </grid>
                  <grid item="">
                    <infotext>
                      {intl.formatMessage({
                        id: 'admin.goal.kpi_format_label',
                      })}
                    </infotext>
                    {kpi && (
                      <defaulttext 19="" lowercase="" style="{{" minHeight:="" }}="">
                        {format}
                      </defaulttext>
                    )}
                  </grid>
                </grid>
              </grid>
            </grid>
          </card>
        </grid>
      </grid>
    </react.fragment>
  );
};

export default KpiStep;
