import React from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import {
  ProgressButton,
  Select,
  TransferList,
  TransferTreeList,
  Card,
  BlueText,
  Switch,
  Tooltip,
  BigText,
  ErrorText,
} from '../../../../../../components';
import { KpiStep, InfoStep, LevelStep } from './components';
import _ from 'lodash';

const BadgeFormStepper = ({
  actionLoading,
  currentStep,
  badge,
  handleNextStep,
  handlePreviousStep,
  isLastStep,
  kpis,
  categories,
  icons,
  levels,
  loading,
  teamGroup,
  setParticipants,
  remainingPoints,
  ...props
}) => {
  const intl = useIntl();
  const isMobile = isWidthDown('xs', props.width);

  let fields;
  let title;
  switch (currentStep.order) {
    case 1:
      title = intl.formatMessage({ id: 'badge.creation.kpi_title' });
      fields = (
        <kpistep categories="{categories}" kpis="{kpis}" initial="{badge.kpi}"></kpistep>
      );
      break;
    case 2:
      title = intl.formatMessage({ id: 'badge.creation.info_title' });
      fields = <infostep categories="{categories}" icons="{icons}" badge="{badge}"></infostep>;
      break;
    case 3:
      title = intl.formatMessage({ id: 'badge.creation.participants_title' });
      fields = (
        <grid item="" xs="{12}">
          <card>
            <transferlist listIn="{teamGroup}" enableCollaboratorSelect="{true}" enableTeamSelect="{true}" onChange="{setParticipants}" selected="{badge.participants" ||="" []}=""></transferlist>
          </card>
        </grid>
      );
      break;
    case 4:
      fields = (
        <levelstep initial="{badge.levels}" remainingPoints="{remainingPoints}" levels="{levels}"></levelstep>
      );
      break;
    case 5:
      title = '';
      fields = (
        <grid item="" xs="{12}">
          <card>
            <div style="{{" textAlign:="" 'center',="" margin:="" 'auto'="" }}="">
              <p style="{{" fontSize:="" 19,="" color:="" '#555555'="" }}="">
                Félicitations 🎉 !
              </p>
              <p style="{{" fontSize:="" 19,="" color:="" '#555555'="" }}="">
                Votre badge est prêt à être créé !
              </p>
            </div>
          </card>
        </grid>
      );
      break;
  }

  return (
    <div>
      <grid 0="" 40="" container="" spacing="{4}" style="{{" paddingBottom:="" isMobile="" ?="" :="" }}="">
        <grid item="" style="{{" textAlign:="" 'center',="" width:="" '100%'="" }}="">
          <bigtext isContrast="">{title}</bigtext>
        </grid>
        {fields}
        {!isMobile && (
          <grid item="" xs="{12}">
            <grid container="" spacing="{4}" direction="row" justify="center">
              {currentStep.order > 1 && (
                <grid item="">
                  <progressbutton onClick="{(e)" ==""> {
                      e.preventDefault();
                      handlePreviousStep();
                    }}
                    color='secondary'
                    text={intl.formatMessage({ id: 'common.previous' })}
                    centered
                  />
                </progressbutton></grid>
              )}
              {!isLastStep && (
                <grid item="">
                  <progressbutton text="{intl.formatMessage({" id:="" 'common.next'="" })}="" centered=""></progressbutton>
                </grid>
              )}
              {isLastStep && (
                <grid item="">
                  <progressbutton text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" loading="{loading}" centered=""></progressbutton>
                </grid>
              )}
            </grid>
          </grid>
        )}
      </grid>
    </div>
  );
};

export default withWidth()(BadgeFormStepper);
