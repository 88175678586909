import React from 'react';
import { withRouter } from 'react-router-dom';
import Formsy, { withFormsy } from 'formsy-react';
import {
  MainLayoutComponent,
  Card,
  DefaultTitle,
  RoleFilter,
  DefaultText,
  TextField,
  ProgressButton,
  Loader,
  IconButton,
  Chip,
} from '../../../../components';
import { injectIntl } from 'react-intl';
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import * as notificationListActions from '../../../../services/Notifications/NotifictionList/actions';
import * as notificationListUpdateActions from '../../../../services/Notifications/NotificationListUpdate/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {
  faBullseye,
  faComment,
  faLandmark,
  faRandom,
  faRocket,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import NECH from '../../../../assets/img/system/email/NECH.png';
import SWE from '../../../../assets/img/system/email/SWE.png';
import NPE from '../../../../assets/img/system/email/NPE.png';
import NROE from '../../../../assets/img/system/email/NROE.png';

const Checkbox = withFormsy(MuiCheckbox);
const styles = (theme) => {
  return {
    dialogCloseIcon: {
      color: 'white',
      width: 25,
      height: 25,
      top: 5,
      right: 5,
      fontSize: 20,
      zIndex: 100,
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.main,
        color: 'white',
      },
    },
    filterChip: {
      fontSize: 10,
      background: 'transparent',
      color: '#333',
      borderColor: '#333',
      padding: '0 4px',
      height: 'auto',
      marginTop: '-3px',
      textTransform: 'none',
    },
  };
};

class AdminNotificatonsList extends MainLayoutComponent {
  constructor(props) {
    super(props);
    this.state = {
      isChevronDown: false,
      chevrons: {
        openNotifsGoals: false,
        openNotifsChallenges: false,
        openNotifsBadges: false,
        openNotifsNewsFeed: false,
        openNotifsRankings: false,
        openNotifsGenerals: false,
      },
      notificationCheckboxes: {},
      inactivityDays: {},
      zoomedImage: null,
    };
    this.imageRefs = {};
  }
  // Toggles the chevron state for the specified section
  toggleChevron = (chevronKey) => {
    this.setState((prevState) => {
      const newChevrons = {
        ...prevState.chevrons,
        [chevronKey]: !prevState.chevrons[chevronKey],
      };
      localStorage.setItem('chevronsState', JSON.stringify(newChevrons));

      return { chevrons: newChevrons };
    });
  };
  // Loads the list of notifications
  loadNotifications = () => {
    const { notificationListActions } = this.props;
    notificationListActions.getNotificationList();
  };

  toggleZoom = (notificationCode) => {
    this.setState((prevState) => ({
      zoomedImage:
        prevState.zoomedImage === notificationCode ? null : notificationCode,
    }));
  };

  handleClickOutside = (event) => {
    if (
      this.state.zoomedImage &&
      this.imageRefs[this.state.zoomedImage] &&
      !this.imageRefs[this.state.zoomedImage].contains(event.target)
    ) {
      this.setState({ zoomedImage: null });
    }
  };

  componentDidMount() {
    const { intl } = this.props;

    this.props.handleTitle(intl.formatMessage({ id: 'admin.title' }));
    this.props.handleSubHeader(<rolefilter></rolefilter>);
    this.props.handleMaxWidth('md');
    this.props.activateReturn();

    document.addEventListener('mousedown', this.handleClickOutside);

    const savedChevrons = localStorage.getItem('chevronsState');
    if (savedChevrons) {
      this.setState({ chevrons: JSON.parse(savedChevrons) });
    }
    this.loadNotifications();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  // Handles the change of the checkbox
  handleCheckboxChange = (notificationId, channel, isChecked) => {
    const { notifications } = this.props.notificationList;

    const EWM_IDS = notifications
      .filter(
        (notificationValue) =>
          notificationValue.notification.code === 'EWM' &&
          notificationValue.channel.code === 'P'
      )
      .map((v) => v.id);

    this.setState((prevState) => {
      let newState = { ...prevState.notificationCheckboxes };

      if (EWM_IDS.includes(notificationId)) {
        EWM_IDS.forEach((id) => {
          newState[id] = {
            ...newState[id],
            [channel]: isChecked,
          };
        });
      } else {
        newState[notificationId] = {
          ...newState[notificationId],
          [channel]: isChecked,
        };
      }

      return { notificationCheckboxes: newState };
    });

    // Ensure inactivityDays is kept in sync
    const notification = notifications.find((n) => n.id === notificationId);
    if (notification) {
      this.setState((prevState) => ({
        inactivityDays: {
          ...prevState.inactivityDays,
          [notificationId]: notification.inactivity_days,
        },
      }));
    }
  };
  // Handles the change of inactivity days
  handleInactivityDaysChange = (notificationId, value) => {
    const { notifications } = this.props.notificationList;
    const notificationToUpdate = notifications.find(
      (n) => n.id === notificationId
    );

    if (
      !notificationToUpdate ||
      notificationToUpdate.notification.code !== 'UINC'
    ) {
      return;
    }

    const relatedNotifications = notifications
      .filter(
        (notification) =>
          notification.notification.code === 'UINC' &&
          notification.notification.id === notificationToUpdate.notification.id
      )
      .map((notification) => notification.id);

    this.setState((prevState) => {
      const updatedInactivityDays = { ...prevState.inactivityDays };
      relatedNotifications.forEach((id) => {
        updatedInactivityDays[id] = value;
      });

      return {
        inactivityDays: updatedInactivityDays,
      };
    });
  };

  // Creates form control labels for the channels
  createFormControlLabels = (channels, notificationCode) => {
    const { intl } = this.props;
    const orderedKeys = ['IA', 'P', 'E'];
    const checkBoxNameMap = {
      IA: intl.formatMessage({
        id: 'admin.notifications_rights.title_in_app',
      }),
      P: intl.formatMessage({ id: 'admin.notifications_rights.title_push' }),
      E: intl.formatMessage({ id: 'admin.notifications_rights.title_email' }),
    };

    return (
      <>
        {orderedKeys
          .map((key) => {
            const channel = channels[key];
            if (!channel) return null;

            const isDisabled = !channel.enabled;
            const currentCheck = this.state.notificationCheckboxes[channel.id];
            const defaultChecked = currentCheck
              ? currentCheck[key]
              : channel.value;
            return (
              <formcontrollabel label="{checkBoxNameMap[key]}" labelPlacement="top" control="{" <Checkbox="" key="{`notification_value_${channel.id}`}" name="{channel.id}" defaultChecked="{defaultChecked}" onChange="{(e)" =="">
                      this.handleCheckboxChange(
                        channel.id,
                        key,
                        e.target.checked
                      )
                    }
                    disabled={isDisabled}
                  />
                }
              />
            );
          })
          .filter(Boolean)}
      </>
    );
  };
  // Renders notifications for a specific chevron section
  renderNotificationsForChevron = (chevronKey) => {
    const { intl } = this.props;
    const { notifications } = this.props.notificationList;
    const currentRole = new URLSearchParams(this.props.location.search).get(
      'current'
    );
    const filteredNotificationsByRole = (notification) => {
      const roleMap = ['C', 'M', 'A'];

      return notification.role.code === roleMap[currentRole];
    };

    const notificationCodeMappings = {
      // openNotifsGoals: [],
      openNotifsChallenges: ['NECH', 'NLP'],
      openNotifsBadges: ['NLB'],
      openNotifsNewsFeed: ['NPIN', 'UMN'],
      openNotifsRankings: ['RFR', 'RFCR', 'RFGR', 'TRFR', 'TRCR', 'TRGR'],
      openNotifsGenerals: ['MNL', 'EWM', 'NPE', 'SWE', 'NROE', 'UINC'],
    };
    const notificationExamples = {
      NLB: {
        text: [
          <>
            🎯 Bravo !! Vous venez de remporter le{' '}
            <chip size="small" label="badge" variant="outlined" className="{this.props.classes.filterChip}"></chip>{' '}
            «{' '}
            <chip size="small" label="nom du badge" variant="outlined" className="{this.props.classes.filterChip}"></chip>{' '}
            » de rang{' '}
            <chip size="small" label="niveau du rang" variant="outlined" className="{this.props.classes.filterChip}"></chip>
            !
          </>,
          <>
            🎯 Bravo !!{' '}
            <chip size="small" label="nom du gagnant" variant="outlined" className="{this.props.classes.filterChip}"></chip>{' '}
            vient de remporter le{' '}
            <chip size="small" label="badge" variant="outlined" className="{this.props.classes.filterChip}"></chip>{' '}
            «{' '}
            <chip size="small" label="nom du badge" variant="outlined" className="{this.props.classes.filterChip}"></chip>{' '}
            » de rang{' '}
            <chip size="small" label="niveau du rang" variant="outlined" className="{this.props.classes.filterChip}"></chip>
            !
          </>,
        ],
      },
      RFR: {
        text: [
          <>
            🏆 Félicitations !! Vous êtes en tête du classement{' '}
            <chip size="small" label="type du classement" variant="outlined" className="{this.props.classes.filterChip}"></chip>
            !
          </>,
          <>
            🏆 Félicitations !!{' '}
            <chip size="small" label="nom du gagnant" variant="outlined" className="{this.props.classes.filterChip}"></chip>{' '}
            est en tête du classement{' '}
            <chip size="small" label="type du classement" variant="outlined" className="{this.props.classes.filterChip}"></chip>
            !
          </>,
        ],
      },
      RFCR: {
        text: [
          '🏆 Félicitations !! Vous êtes en tête du classement « Challenges » !',
          <>
            🏆 Félicitations !!{' '}
            <chip size="small" label="nom du gagnant" variant="outlined" className="{this.props.classes.filterChip}"></chip>
            est en tête du classement « Challenges » !
          </>,
        ],
      },
      RFGR: {
        text: [
          '🏆 🏆 🏆 Incroyable !!! Vous venez de passer 1er du classement général !',
          <>
            🏆 🏆 🏆 Incroyable !!!
            <chip size="small" label="nom du gagnant" variant="outlined" className="{this.props.classes.filterChip}"></chip>
            vient de passer 1er du classement général !
          </>,
        ],
      },
      MNL: {
        text: [
          <>
            🚀 Level UP !! Vous venez de passer au level{' '}
            <chip size="small" label="niveau du level" variant="outlined" className="{this.props.classes.filterChip}"></chip>{' '}
            ! 🔥
          </>,
          <>
            🚀 Level UP !!{' '}
            <chip size="small" label="nom du gagnant" variant="outlined" className="{this.props.classes.filterChip}"></chip>
            vient de passer au level
            <chip size="small" label="niveau du level" variant="outlined" className="{this.props.classes.filterChip}"></chip>
            !! 🔥
          </>,
        ],
      },
      TRFR: {
        text: [
          <>
            🎉 Félicitations !! Votre équipe est en tête du classement «
            <chip size="small" label="type du classement" variant="outlined" className="{this.props.classes.filterChip}"></chip>
            »
          </>,
          <>
            🎉 Félicitation à l’équipe «
            <chip size="small" label="nom de l’équipe" variant="outlined" className="{this.props.classes.filterChip}"></chip>
            » qui prend la tête du classement «
            <chip size="small" label="type du classement" variant="outlined" className="{this.props.classes.filterChip}"></chip>
            »
          </>,
        ],
      },
      TRCR: {
        text: [
          '🎉 Félicitations !! Votre équipe est en tête du classement Challenges',
          <>
            🎉 Félicitation à l’équipe «
            <chip size="small" label="nom de l’équipe" variant="outlined" className="{this.props.classes.filterChip}"></chip>
            » qui prend la tête du classement challenges
          </>,
        ],
      },
      TRGR: {
        text: [
          '🎉 Félicitations !! Votre équipe est en tête du classement général',
          <>
            🎉 Félicitation à l’équipe «
            <chip size="small" label="nom de l’équipe" variant="outlined" className="{this.props.classes.filterChip}"></chip>
            » qui prend la tête du classement général
          </>,
        ],
      },
      NECH: {
        text: [
          "🚀 Vous avez un nouveau challenge qui commence aujourd'hui! Préparez-vous à décoller!",
        ],
        image: NECH,
      },
      NPIN: { text: ["🆕 Découvrez le nouveau post sur le fil d'actualité !"] },
      NLP: {
        text: [
          '👏 Bravo ! Vous avez franchi un palier sur un challenge !',
          '👏 Bravo ! Votre équipe franchi un palier sur un challenge !',
        ],
      },
      UMN: { text: ['📣 Vous avez été mentionné dans une publication!'] },
      EWM: { text: ['🔥 Prêt pour cette nouvelle semaine ?'] },
      NPE: { image: NPE },
      UINC: {
        text: ['⏱️ça fait longtemps ! Venez voir ce qui se passe sur Objow !'],
      },
      SWE: {
        image: SWE,
      },
      NROE: {
        image: NROE,
      },
    };

    const notificationsByChevron = notifications
      ?.filter(filteredNotificationsByRole)
      ?.filter((notification) =>
        notificationCodeMappings[chevronKey]?.includes(
          notification.notification.code
        )
      );

    const notificationCodes = [];
    let notificationValues = notificationsByChevron?.reduce(
      (acc, notification) => {
        if (!(notification.notification.code in acc)) {
          acc[notification.notification.code] = Object.assign(
            {},
            { description: notification.notification.description },
            { channels: {}, inactivity_days: notification.inactivity_days }
          );

          notificationCodes.push(notification.notification.code);
        }

        if (acc[notification.notification.code].inactivity_days === null) {
          acc[notification.notification.code].inactivity_days =
            notification.inactivity_days;
        }
        const new_channels = Object.assign(
          {},
          acc[notification.notification.code].channels,
          {
            [notification.channel.code]: {
              id: notification.id,
              value: notification.value,
              enabled: notification.enabled,
              inactivity_days: notification.inactivity_days,
            },
          }
        );

        const new_notification = Object.assign(
          {},
          acc[notification.notification.code],
          {
            channels: new_channels,
          }
        );
        return Object.assign({}, acc, {
          [notification.notification.code]: new_notification,
        });
      },
      {}
    );
    const { loading } = this.props.notificationList;
    if (!notificationsByChevron) {
      return <loader centered=""></loader>;
    }
    if (notificationsByChevron.length === 0) {
      return (
        <defaulttext lowercase="">
          {intl.formatMessage({
            id: `admin.notifications_rights.no_notifications`,
          })}
        </defaulttext>
      );
    }
    const isMobile = isWidthDown('xs', this.props.width);
    return (
      <>
        {loading && <loader centered=""></loader>}
        <grid container="">
          {notificationCodes.map((notificationCode, index) => {
            const notification = notificationValues[notificationCode];
            const example = notificationExamples[notificationCode];
            return (
              <react.fragment key="{notificationCode}">
                <grid container="" item="" alignItems="center" justifyContent="space-between">
                  <grid item="" lg="{8}" md="{6}" sm="{12}" style="{{" position:="" 'relative',="" width:="" '100%'="" }}="">
                    <defaulttext 16="" lowercase="" style="{{" fontSize:="" }}="">
                      {intl.formatMessage({
                        id: `admin.notifications_rights.notification_${notificationCode}`,
                      })}
                    </defaulttext>
                    {example && (
                      <>
                        {example.text && example.text.length > 0 && (
                          <defaulttext lowercase="" style="{{" fontSize:="" 12,="" color:="" 'gray'="" }}="">
                            {intl.formatMessage({
                              id: `admin.notifications_rights.example_inapp_push_notification`,
                            })}
                            {Array.isArray(example.text) ? (
                              <ul>
                                {example.text.map((item, index) => (
                                  <li key="{index}">{item}</li>
                                ))}
                              </ul>
                            ) : (
                              <p>{example.text}</p>
                            )}
                          </defaulttext>
                        )}
                        {example.image && (
                          <>
                            <defaulttext lowercase="" style="{{" fontSize:="" 12,="" color:="" 'gray'="" }}="">
                              {intl.formatMessage({
                                id: `admin.notifications_rights.example_email`,
                              })}
                            </defaulttext>
                            <grid style="{{" position:="" 'relative',="" display:="" 'inline-block',="" }}="">
                              <img ref="{(el)" ==""> {
                                  this.imageRefs[notificationCode] = el;
                                }}
                                src={example.image}
                                alt={`${notificationCode} example`}
                                style={{
                                  width:
                                    this.state.zoomedImage === notificationCode
                                      ? isMobile
                                        ? '100%'
                                        : '500px'
                                      : '100px',
                                  transform: 'scale(1)',
                                  height:
                                    this.state.zoomedImage === notificationCode
                                      ? 'auto'
                                      : '100px',

                                  objectFit: 'cover',
                                  overflow: 'hidden',
                                  transition: 'height 0.3s ease-in-out',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  this.toggleZoom(notificationCode)
                                }
                              />
                              <div style="{{" position:="" 'absolute',="" top:="" '0px',="" right:="" cursor:="" 'pointer',="" color:="" 'white',="" }}="" onClick="{()" =="">
                                  this.toggleZoom(notificationCode)
                                }
                              >
                                {this.state.zoomedImage === notificationCode ? (
                                  <iconbutton className="{" this.props.classes.dialogCloseIcon="" }="">
                                    <fullscreenexiticon></fullscreenexiticon>
                                  </iconbutton>
                                ) : (
                                  <iconbutton className="{" this.props.classes.dialogCloseIcon="" }="">
                                    <fullscreenicon></fullscreenicon>
                                  </iconbutton>
                                )}
                              </div>
                            </grid>
                          </>
                        )}
                      </>
                    )}
                  </grid>
                  <grid item="">
                    {this.createFormControlLabels(
                      notification.channels,
                      notificationCode
                    )}
                  </grid>
                  {notificationCode === 'UINC' && (
                    <grid item="" lg="{2}" md="{3}" sm="{12}">
                      <textfield name="{'inactivity_days'}" type="number" initial="{notification.inactivity_days}" onChange="{(value)" =="">
                          this.handleInactivityDaysChange(
                            notification.channels.P.id,
                            value
                          )
                        }
                        label='jours'
                        style={{ marginLeft: '10px' }}
                      />
                    </textfield></grid>
                  )}
                </grid>
                {index < notificationCodeMappings[chevronKey].length - 1 && (
                  <hr style="{{" margin:="" '20px="" 0',="" borderColor:="" '#FFFFFF',="" width:="" '100%',="" }}="">
                )}
              </react.fragment>
            );
          })}
        </grid>
      </>
    );
  };
  // Handles the form submit action
  handleSubmit = () => {
    const { intl } = this.props;

    const combinedUpdates = {};

    Object.entries(this.state.notificationCheckboxes).forEach(
      ([id, channels]) => {
        combinedUpdates[id] = combinedUpdates[id] || {};
        combinedUpdates[id].value = Object.values(channels).some(
          (value) => value
        );
      }
    );

    Object.entries(this.state.inactivityDays).forEach(([id, days]) => {
      combinedUpdates[id] = combinedUpdates[id] || {};
      combinedUpdates[id].inactivity_days = days;
    });

    // Ensure value is set for all updates
    Object.keys(combinedUpdates).forEach((id) => {
      if (combinedUpdates[id].value === undefined) {
        const notification = this.props.notificationList.notifications.find(
          (n) => n.id.toString() === id
        );
        if (notification) {
          combinedUpdates[id].value = notification.value;
        }
      }
    });
    const notificationToUpdate = Object.entries(combinedUpdates).map(
      ([id, updates]) => ({
        id,
        ...updates,
      })
    );

    this.props.notificationListUpdateActions.updateNotificationList(
      notificationToUpdate
    );
    this.props.history.replace('/admin');
    toast.success(
      intl.formatMessage({
        id: 'admin.notifications_rights.notification_update_success',
      })
    );
  };

  render() {
    const { loading } = this.props.notificationList;
    const { intl } = this.props;
    const { chevrons } = this.state;
    const notificationTypes = [
      { icon: faBullseye, key: 'openNotifsGoals', titleId: 'admin.goal.title' },
      {
        icon: faRocket,
        key: 'openNotifsChallenges',
        titleId: 'challenge.title_plural',
        explication: 'admin.notifications_rights.explication_challenge',
      },
      {
        icon: faTrophy,
        key: 'openNotifsBadges',
        titleId: 'admin.home.badge_link',
        explication: 'admin.notifications_rights.explication_badge',
      },
      {
        icon: faComment,
        key: 'openNotifsNewsFeed',
        titleId: 'newsfeed.title',
        explication: 'admin.notifications_rights.explication_newsfeed',
      },
      {
        icon: faRandom,
        key: 'openNotifsRankings',
        titleId: 'ranking.title',
        explication: 'admin.notifications_rights.explication_ranking',
      },
      {
        icon: faLandmark,
        key: 'openNotifsGenerals',
        titleId: 'admin.notifications_rights.global',
        explication: 'admin.notifications_rights.explication_global',
      },
    ];

    return (
      <>
        <formsy onValidSubmit="{this.handleSubmit}">
          <div>
            <defaulttitle isContrast="">
              {intl.formatMessage({ id: 'admin.notifications_rights.title' })}
            </defaulttitle>
          </div>
          <grid container="" spacing="{1}">
            <grid item="" xs="{12}">
              <card>
                <defaulttitle lowercase="" bold="">
                  {intl.formatMessage({
                    id: 'admin.notifications_rights.title_explication',
                  })}
                </defaulttitle>
                <defaulttext lowercase="">
                  {intl.formatMessage({
                    id: 'admin.notifications_rights.explication_type',
                  })}
                </defaulttext>
                <defaulttext lowercase="">
                  {intl.formatMessage({
                    id: 'admin.notifications_rights.explication_push',
                  })}
                </defaulttext>
                <defaulttext lowercase="">
                  {intl.formatMessage({
                    id: 'admin.notifications_rights.explication_in_app',
                  })}
                </defaulttext>
                <defaulttext lowercase="">
                  {intl.formatMessage({
                    id: 'admin.notifications_rights.explication_email',
                  })}
                </defaulttext>
                <defaulttitle lowercase="" bold="">
                  {intl.formatMessage({
                    id: 'admin.notifications_rights.remark_explication',
                  })}
                </defaulttitle>
                <defaulttext 10="" lowercase="" style="{{" paddingBottom:="" }}="">
                  {intl.formatMessage({
                    id: 'admin.notifications_rights.remark_explication_checkbox',
                  })}
                </defaulttext>
                {notificationTypes.map(
                  ({ icon, key, titleId, explication }) => (
                    <accordion expanded="{chevrons[key]}" onChange="{()" ==""> this.toggleChevron(key)}
                      key={key}
                    >
                      <accordionsummary expandIcon="{<ExpandMoreIcon"></accordionsummary>}>
                        <grid container="" item="" alignItems="center">
                          <fontawesomeicon 10="" icon="{icon}" style="{{" paddingRight:="" }}=""></fontawesomeicon>
                          <defaulttitle lowercase="" bold="">
                            {intl.formatMessage({ id: titleId })}
                          </defaulttitle>
                        </grid>
                      
                      <accordiondetails>
                        {explication && intl.messages[explication] ? (
                          <defaulttext lowercase="">
                            *{intl.formatMessage({ id: explication })}
                          </defaulttext>
                        ) : null}
                        {this.renderNotificationsForChevron(key)}
                      </accordiondetails>
                    </accordion>
                  )
                )}
                <grid 10="" item="" xs="{12}" style="{{" paddingTop:="" }}="">
                  <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" loading="{loading}" centered=""></progressbutton>
                </grid>
              </card>
            </grid>
          </grid>
        </formsy>
      </>
    );
  }
}
const mapStateToProps = ({ notificationList, notificationListUpdate }) => ({
  notificationList,
  notificationListUpdate,
});

const mapDispatchToProps = (dispatch) => ({
  notificationListActions: bindActionCreators(
    notificationListActions,
    dispatch
  ),
  notificationListUpdateActions: bindActionCreators(
    notificationListUpdateActions,
    dispatch
  ),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withWidth()(injectIntl(AdminNotificatonsList))))
);
</formcontrollabel>