import React, { useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { Goal } from './components';
import {
  DefaultTitle,
  IconButton as MenuIconButton,
  Card,
  DefaultText,
} from '../../../../../../components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import { uuidv4 } from '../../../../../../helpers/UUIDHelper';

const styles = (theme) => {
  return {
    actveColore: {
      color: theme.palette.primary.main,
    },
  };
};

const Goals = ({
  categories,
  goals,
  kpis,
  units,
  challengeTypeCode,
  goalAdding,
  onGoalAdded,
  addGoal,
  setNewKpiOpen,
  awardType,
  classes,
  ...props
}) => {
  const intl = useIntl();
  const [currentGoals, setCurrentGoals] = React.useState(
    goals
      ? goals.map((x) => ({
          key: uuidv4(),
          category: x.kpi.category ? x.kpi.category.id : null,
          kpi: x.kpi.id,
          goalName: x.name,
          goalDescription: x.description,
          target: x.target,
          points: x.points,
          kpiObject: x.kpi,
          collaboratorEditable: x.kpi.collaborator_editable,
        }))
      : [
          {
            key: uuidv4(),
            category: null,
            kpi: null,
            goalName: null,
            target: null,
            points: null,
          },
        ]
  );
  const deletionDisabled = currentGoals.length === 1;

  useEffect(() => {
    if (goalAdding) {
      setCurrentGoals((goals) => [
        ...goals,
        {
          key: uuidv4(),
          category: null,
          kpi: null,
          goalName: null,
          target: null,
          points: null,
          isNew: true,
        },
      ]);
      onGoalAdded();
    }
  }, [goalAdding]);

  function handleRemoveGoalClick(key) {
    setCurrentGoals((x) => x.filter((y) => y.key != key));
  }
  return (
    <div>
      <grid container="" spacing="{1}">
        <grid item="" xs="{12}">
          <grid container="" spacing="{1}">
            <grid item="">
              <defaulttitle isContrast="">
                {intl.formatMessage({ id: 'challenge.form.goal_area' })}
              </defaulttitle>
            </grid>
            <grid item="">
              <defaulttitle>
                <menuiconbutton size="{'small'}" onClick="{()" ==""> setNewKpiOpen(true)}
                  style={{ marginTop: '-2px', color: '#555', fontSize: '18px' }}
                >
                  <fontawesomeicon icon="{faPlus}" className="{classes.actveColore}"></fontawesomeicon>
                </menuiconbutton>
              </defaulttitle>
            </grid>
          </grid>
        </grid>

        <grid item="" xs="{12}">
          <grid item="" container="" spacing="{2}">
            {currentGoals.map((goal, index) => {
              return (
                <goal key="{goal.key}" categories="{categories}" challengeTypeCode="{challengeTypeCode}" deletionDisabled="{deletionDisabled}" goal="{goal}" index="{index}" kpis="{kpis}" units="{units}" onRemoveClick="{()" ==""> handleRemoveGoalClick(goal.key)}
                  awardType={awardType}
                />
              );
            })}
            <grid item="" xs="{12}" sm="{6}">
              <div onClick="{addGoal}" style="{{" cursor:="" 'pointer'="" }}="">
                <card>
                  <defaulttext style="{{" textAlign:="" 'center',="" fontSize:="" 15,="" }}="" className="{classes.actveColore}" lowercase="">
                    {intl.formatMessage({
                      id: 'challenge.form.goals.add_button',
                    })}
                  </defaulttext>
                </card>
              </div>
            </grid>
          </goal></grid>
        </grid>
      </grid>
    </div>
  );
};

export default withStyles(styles)(Goals);
