import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  AccentText,
  Card,
  DefaultText,
  DefaultTitle,
  EmptyState,
  ErrorText,
  InfoText,
  Loader,
  ProgressButton,
  TextField,
} from '../../../../../../../../components';
import * as goalListActions from '../../../../../../../../services/Goals/GoalList/actions';
import * as goalListUpdateActions from '../../../../../../../../services/Goals/GoalListUpdate/actions';
import '../../../../../../../../helpers/FormsyHelper';
import '../../../../../../../../helpers/DateHelper';
import '../../../../../../../../helpers/NumberHelper';
import { injectIntl } from 'react-intl';
import * as Resources from '../../../../../../../../Resources';
import { toast } from 'react-toastify';

const styles = {
  title: {
    marginBottom: 16,
  },
  indicators: {
    marginBottom: 32,
  },
  formFooter: {
    marginTop: 32,
  },
  error: {
    marginBottom: 16,
  },
};

class GoalList extends Component {
  constructor(props) {
    super(props);
    this.date = null;
    this.state = {
      targetSum: null,
    };
  }

  componentDidMount() {
    const { definition } = this.props.goalDefinitionDetail;
    this.props.goalListActions.getGoalList(definition.id);
  }

  renderLoader() {
    return (
      <div>
        <loader centered=""></loader>
      </div>
    );
  }

  renderEmptyState() {
    const { intl } = this.props;
    return (
      <div>
        <emptystate title="{intl.formatMessage({" id:="" 'admin.goal.list.empty_state_title',="" })}="" message="{intl.formatMessage({" 'admin.goal.list.empty_state_message',=""></emptystate>
      </div>
    );
  }

  convertToGoals(model) {
    const goals = [];
    const keys = Object.keys(model);
    keys.map((key) => {
      if (key != 'remainingTarget') {
        const goal = { id: key, target: model[key] };
        goals.push(goal);
      }
    });
    return goals;
  }

  handleChange(model) {
    const goals = this.convertToGoals(model);
    var targetSum = goals
      .map((goal) => Number(goal.target))
      .reduce((a, b) => a + b);
    this.setState({
      ...this.state,
      targetSum: targetSum,
    });
  }

  handleSubmit(model) {
    const goals = this.convertToGoals(model);
    this.props.goalListUpdateActions.updateGoalList(goals);
  }

  renderForm() {
    const { intl } = this.props;
    const { classes } = this.props;
    const { goals } = this.props.goalList;
    const { definition: parentGoal } = this.props.goalDefinitionDetail;
    const { loading } = this.props.goalListUpdate;
    const { account } = this.props.accountDetail;
    const goalCount = goals.length;
    const isRate = parentGoal.kpi.unit.isRate;
    const maxTarget = parentGoal.target;
    var initialAllTarget = goals
      .map((goal) => Number(goal.target))
      .reduce((a, b) => a + b);
    if (isRate)
      initialAllTarget =
        goalCount > 0 ? Math.ceil(initialAllTarget / goalCount) : 0;
    var allTarget = initialAllTarget;
    if (this.state.targetSum != null && !isRate)
      allTarget = this.state.targetSum;
    if (this.state.targetSum != null && isRate)
      allTarget =
        goalCount > 0 ? Math.ceil(this.state.targetSum / goalCount) : 0;
    const remainingTarget = maxTarget - allTarget;
    const canSubmit = remainingTarget >= 0 || parentGoal.allow_over_target;
    const now = new Date();
    const readonly = !parentGoal.isActive;
    let allDisabled = true;

    return (
      <div>
        <defaulttitle className="{classes.title}">Indicateurs</defaulttitle>
        <div className="{classes.indicators}">
          <card>
            <grid container="" justify="space-between">
              <grid item="">
                <defaulttext>Objectif alloué pour l'année</defaulttext>
                <infotext>{maxTarget}</infotext>
              </grid>
              <grid item="">
                <defaulttext>
                  {intl.formatMessage({
                    id: 'admin.goal.edit.all_target_label',
                  })}
                </defaulttext>
                <infotext>{allTarget}</infotext>
              </grid>
              <grid item="">
                <defaulttext>
                  {intl.formatMessage({
                    id: 'admin.goal.edit.remaining_target_label',
                  })}
                </defaulttext>
                {remainingTarget >= 0 && (
                  <accenttext>{remainingTarget}</accenttext>
                )}
                {remainingTarget < 0 && (
                  <errortext>{remainingTarget}</errortext>
                )}
              </grid>
            </grid>
          </card>
        </div>
        <formsy onChange="{this.handleChange.bind(this)}" onValidSubmit="{this.handleSubmit.bind(this)}">
          <grid container="" spacing="{2}">
            {goals.map((goal, index) => {
              const name =
                parentGoal.periodicity.code == 'Y'
                  ? 'Année ' + goal.start.toDate().getFullYear()
                  : parentGoal.periodicity.code == 'S'
                  ? 'Semestre ' + goal.start.toDate().getSemesterNumber()
                  : parentGoal.periodicity.code == 'Q'
                  ? 'Trimestre ' + goal.start.toDate().getQuarterNumber()
                  : parentGoal.periodicity.code == 'M'
                  ? Intl.DateTimeFormat('fr-FR', { month: 'long' }).format(
                      goal.start.toDate()
                    )
                  : parentGoal.periodicity.code == 'W'
                  ? 'Semaine ' + goal.start.toDate().getWeekNumber()
                  : intl
                      .formatMessage({ id: 'admin.goal.period_label_short2' })
                      .format(
                        goal.start.toDate().toLocaleDateString(),
                        goal.end.toDate2().toLocaleDateString()
                      );
              const isPast = new Date(goal.end * 1000) < now;
              const editable =
                !isPast ||
                (parentGoal.past_editable && account.role.code === 'A');
              if (editable) {
                allDisabled = false;
              }
              return (
                <grid key="{goal.id}" item="" xs="{3}">
                  <textfield type="number" name="{goal.id}" label="{name}" initial="{goal.target}" disabled="{!editable" ||="" readonly}="" fullWidth="" required="" validations="{{" isMoreThanOrEquals:="" 0,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" id:="" 'common.form.required_error',="" }),="" "L'objectif="" doit="" être="" supérieur="" ou="" égal="" à="" 0.",=""></textfield>
                </grid>
              );
            })}
          </grid>
          {!readonly && (
            <div className="{classes.formFooter}">
              {!canSubmit && (
                <errortext className="{classes.error}" align="center">
                  Veuillez respecter l'objectif total alloué pour la période
                  sélectionnée
                </errortext>
              )}
              <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" loading="{loading}" disabled="{!canSubmit" ||="" allDisabled="" readonly}="" centered=""></progressbutton>
            </div>
          )}
        </formsy>
      </div>
    );
  }

  render() {
    const { goals, loading: goalListLoading } = this.props.goalList;
    const { loading: goalDetailLoading } = this.props.goalDetail;
    const loading = goalListLoading || goalDetailLoading;
    const hasGoals = goals && goals.length > 0;
    const { success, error } = this.props.goalListUpdate;
    const { intl } = this.props;
    if (success) {
      // this.props.goalDefinitionUpdateActions.clearGoalDefinitionUpdate()
      // this.props.history.goBack()
      this.props.goalListUpdateActions.updateGoalListClear();
      toast.success(intl.formatMessage({ id: 'admin.goal.edit.success' }));
    }

    if (error) {
      this.props.goalListUpdateActions.updateGoalListClear();
      toast.error(intl.formatMessage({ id: 'admin.goal.edit.error' }));
    }

    return (
      <div>
        {loading && this.renderLoader()}
        {!loading && hasGoals && this.renderForm()}
        {!loading && !hasGoals && this.renderEmptyState()}
      </div>
    );
  }
}

const mapStateToProps = ({
  goalDefinitionDetail,
  goalDetail,
  goalList,
  goalListUpdate,
  accountDetail,
}) => ({
  goalDefinitionDetail,
  goalDetail,
  goalList,
  goalListUpdate,
  accountDetail,
});

const mapDispatchToProps = (dispatch) => ({
  goalListActions: bindActionCreators(goalListActions, dispatch),
  goalListUpdateActions: bindActionCreators(goalListUpdateActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(GoalList)));
