import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@mui/material';
import { Character } from '../Character';

import '../../../style.css';
import separation from '../../../../../../../assets/img/jti/separation.svg';
import slot from '../../../../../../../assets/img/jti/stones/slot.svg';
import stone0 from '../../../../../../../assets/img/jti/stones/stone_0.png';
import stone1 from '../../../../../../../assets/img/jti/stones/stone_1.png';
import stone2 from '../../../../../../../assets/img/jti/stones/stone_2.png';
import stone3 from '../../../../../../../assets/img/jti/stones/stone_3.png';
import stone4 from '../../../../../../../assets/img/jti/stones/stone_4.png';
import stone5 from '../../../../../../../assets/img/jti/stones/stone_5.png';
import stone6 from '../../../../../../../assets/img/jti/stones/stone_6.png';
import bgStones from '../../../../../../../assets/img/jti/stones/bgStones.svg';
import { CharacterDetail } from '../CharacterDetail';
import { Typography } from '@material-ui/core';
import _ from 'lodash';

const styles = {
  content: {
    background: '#151924',
    fontWeight: 'bold',
    borderRadius: '0 0 35px 40px',
    boxShadow: '0px 5px 20px #000',
  },
  progressTitle: {
    fontFamily: 'VOXMedium',
    fontWeight: '700',
    fontStyle: 'italic',
    textAlign: 'center',
    color: '#6790A9',
    fontSize: 12,
  },
  progressValueText: {
    fontFamily: 'VOXMedium',
    color: '#DFE9EE',
    fontSize: '24px',
    fontWeight: '700',
    fontStyle: 'italic',
    lineHeight: '24px',
    textAlign: 'center',
    marginTop: '3px',
  },
  progressTotalText: {
    fontFamily: 'VOXMedium',
    color: '#6790A9',
    fontWeight: '700',
    fontStyle: 'italic',
    fontSize: '14px',
    lineHeight: '14px',
    textAlign: 'center',
  },
};

const SubHeader = ({ ...props }) => {
  const { classes } = props;
  const renderData = () => {
    const { account } = props.accountDetail;
    const { challenges } = props.collaboratorChallengeList;
    const { goals } = props.collaboratorGoalSummaryList;
    const { data } = props.collaboratorDataList;
    const stones = [stone0, stone1, stone2, stone3, stone4, stone5, stone6];
    const excluded_goal_kpis = [
      65, 66, 67, 68, 69, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98,
    ];

    const completedChallenges = _.get(account, 'isJtiTradeEnv')
      ? challenges
          .filter((challenge) => parseInt(challenge.sourceId) !== 34)
          .reduce((acc, challenge) => acc + challenge.goal_points, 0)
      : challenges.filter((challenge) => challenge.isCompleted).length;

    const completedGoals = _.get(account, 'isJtiTradeEnv')
      ? goals
          .filter(
            (summary) => excluded_goal_kpis.indexOf(parseInt(summary.kpiId)) < 0
          )
          .reduce((acc, summary) => acc + summary.counter, 0)
      : goals.filter((summary) => summary.isCompleted).length;

    const completedStone = account.identifiers.filter(
      (identifier) => identifier.definition.order === 0
    )[0] || { value: null };

    const rank = data.filter((item) => item.user.id === account.id)[0];

    return (
      <div className="{classes.content}">
        <grid container="" spacing="{0}" alignItems="center" justifyContent="center">
          <character></character>
          <characterdetail></characterdetail>
        </grid>
        <grid container="" justifyContent="space-evenly">
          <grid item="">
            <typography align="center" style="{styles.progressTitle}">
              DÉFIS
            </typography>
            <typography align="center" style="{styles.progressTitle}">
              BUSINESS
            </typography>
            <grid container="" direction="row" justifyContent="center" alignItems="baseline" spacing="{0.5}">
              <grid item="">
                <typography style="{styles.progressValueText}">
                  {completedGoals}
                </typography>
              </grid>
              {!_.get(account, 'isJtiTradeEnv') && (
                <grid item="">
                  <typography style="{styles.progressTotalText}">
                    /{goals.length}
                  </typography>
                </grid>
              )}
            </grid>
          </grid>
          <grid item="">
            <img src="{separation}" alt="separation1">
          </grid>
          <grid item="">
            <typography align="center" style="{styles.progressTitle}">
              DÉFIS
            </typography>
            <typography align="center" style="{styles.progressTitle}">
              PERSONNELS
            </typography>
            <grid container="" direction="row" justifyContent="center" alignItems="baseline" spacing="{0.5}">
              <grid item="">
                <typography style="{styles.progressValueText}">
                  {completedChallenges}
                </typography>
              </grid>
              {!_.get(account, 'isJtiTradeEnv') && (
                <grid item="">
                  <typography style="{styles.progressTotalText}">
                    /{challenges.length}
                  </typography>
                </grid>
              )}
            </grid>
          </grid>
          <grid item="">
            <img src="{separation}" alt="separation2">
          </grid>
          <grid item="">
            <typography align="center" style="{styles.progressTitle}">
              CLASSEMENT
            </typography>
            <typography align="center" style="{styles.progressTitle}">
              GÉNÉRAL
            </typography>
            <grid container="" direction="row" justifyContent="center" alignItems="baseline" spacing="{0.5}">
              <grid item="">
                <typography style="{styles.progressValueText}">
                  {rank ? rank.dataValue : ''}
                </typography>
              </grid>
              <grid item="">
                <typography style="{styles.progressTotalText}">
                  /{_.get(account, 'isJtiTradeEnv') ? '151' : '2000'}
                </typography>
              </grid>
            </grid>
          </grid>
        </grid>
        <grid container="" style="{{" marginTop:="" '10px',="" marginBottom:="" '-56px'="" }}="" justifyContent="center" spacing="{1}">
          {[...Array(7).keys()].map((i) => (
            <grid item="" style="{{" overflow:="" 'hidden'="" }}="">
              {_.isFinite(parseInt(completedStone.value)) &&
              parseInt(completedStone.value) >= parseInt(i) ? (
                <img 36="" style="{{" height:="" }}="" src="{stones[i]}" alt="slot1">
              ) : (
                <img 36="" style="{{" height:="" }}="" src="{slot}" alt="slot1">
              )}
            </grid>
          ))}
        </grid>
        <img src="{bgStones}" alt="bgStones" style="{{" boxShadow:="" '0px="" 5px="" 20px="" #000',="" opacity:="" 0.12,="" width:="" '98%',="" position:="" 'relative',="" top:="" '0px',="" left:="" '3px',="" }}="">
      </div>
    );
  };

  return <div>{renderData()}</div>;
};

const mapStateToProps = ({
  accountDetail,
  collaboratorChallengeList,
  collaboratorGoalSummaryList,
  collaboratorDataList,
}) => ({
  accountDetail,
  collaboratorChallengeList,
  collaboratorGoalSummaryList,
  collaboratorDataList,
});

export default connect(mapStateToProps)(withStyles(styles)(SubHeader));
