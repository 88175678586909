import React, { useState, useRef, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { DefaultText, Linkify } from '../../../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { useIntl, injectIntl } from 'react-intl';
import _ from 'lodash';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const styles = {
  seeMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    paddingLeft: 5,
    textAlign: 'right',
    paddingBottom: 5,
    cursor: 'pointer',
    '&:hover p': {
      color: 'rgb(15,111,222)',
      textDecoration: 'underline',
    },
  },
};

const Content = ({ classes, content, seeMoreClass, ...props }) => {
  const intl = useIntl();
  const [seeMore, setSeeMore] = React.useState(false);
  const descriptionWrapper = useRef();

  const descriptionHeightLimit = 68;
  const [initialized, setInitialized] = useState(false);
  const [descriptionIsLong, setDescriptionIsLong] = useState(
    descriptionWrapper.current &&
      descriptionWrapper.current.clientHeight >= descriptionHeightLimit
  );

  const formatMentions = (text) => {
    const mentionRegex = /@\[(.+?)\]\((\d+)\)/g;
    const parts = [];
    let lastIndex = 0;

    let match;
    while ((match = mentionRegex.exec(text)) !== null) {
      const [fullMatch, name, id] = match;
      const start = match.index;
      const end = mentionRegex.lastIndex;

      if (start > lastIndex) {
        parts.push(text.substring(lastIndex, start));
      }
      parts.push(
        <span key="{id}" style="{{" fontWeight:="" 700,="" textDecoration:="" 'none',="" color:="" '#555'="" }}="">
          {name}
        </span>
      );

      lastIndex = end;
    }

    if (lastIndex < text.length) {
      parts.push(text.substring(lastIndex));
    }

    return parts;
  };

  useEffect(() => {
    if (initialized) return;
    if (!descriptionWrapper.current) return;

    setInitialized(true);
    setDescriptionIsLong(
      descriptionWrapper.current &&
        descriptionWrapper.current.clientHeight >= descriptionHeightLimit
    );
  }, []);
  return (
    <div style="{{" paddingTop:="" 0,="" paddingBottom:="" 25,="" maxHeight:="" seeMore="" &&="" descriptionIsLong="" ?="" 'none'="" :="" descriptionHeightLimit,="" position:="" 'relative',="" overflow:="" 'hidden',="" }}="" ref="{descriptionWrapper}">
      <div>
        {formatMentions(content).map((part, index) => (
          <span key="{index}">
            <linkify>
              <defaulttext style="{{" display:="" 'inline'="" }}="" lowercase="">
                {part}
              </defaulttext>
            </linkify>
          </span>
        ))}
      </div>
      {descriptionIsLong && !seeMore && (
        <div className="{`${classes.seeMore}" ${seeMoreClass}`}="">
          <defaulttext lowercase="" onClick="{()" ==""> setSeeMore(!seeMore)}>
            {intl.formatMessage({
              id: seeMore ? 'newsfeed.see_less' : 'newsfeed.see_more',
            })}
          </defaulttext>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(Content);
