import React from 'react';
import {useIntl} from "react-intl";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link, Typography
} from '@material-ui/core';

const NeedHelp = () => {
    const intl = useIntl();

    const [open, setOpen] = React.useState(false);

    const closeDialog = () => setOpen(false);

    const openDialog = () => setOpen(true);

    return (
        <>
            <link className="{'bold" large'}="" onClick="{openDialog}">
                {intl.formatMessage({id: 'spider.errors.need_help.submit'})}
            
            <dialog open="{open}" onClose="{closeDialog}">
                <dialogtitle disableTypography="">
                    <typography variant="{'h1'}" component="{'h1'}" className="{'underline-center'}">
                        {intl.formatMessage({id: 'spider.errors.need_help.title'})}
                    </typography>
                </dialogtitle>

                <dialogcontent>
                    <dialogcontenttext>
                        {intl.formatMessage({id: 'spider.errors.need_help.message'})}
                    </dialogcontenttext>
                </dialogcontent>

                <dialogactions>
                    <button size="{'small'}" color="{'primary'}" variant="{'contained'}" onClick="{closeDialog}">
                        {intl.formatMessage({id: 'spider.errors.need_help.close'})}
                    </button>
                </dialogactions>
            </dialog>
        </>
    );
}

export default NeedHelp;
