import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { ProgressButton } from '../../../../components/Common/components/Buttons/components/ProgressButton';
import { Card, DefaultTitle, ErrorText, Switch } from '../../../../components';
import { Link } from 'react-router-dom';
import '../../../../helpers/FormsyHelper';
import * as termsAcceptanceActions from '../../../../services/Account/TermsAcceptance/actions';
import local from '../../../../data/local/local';

const AcceptTerms = ({ ...props }) => {
  const { intl } = props;
  const { loading } = props.termsAcceptance;
  const [isInvalid, setIsInvalid] = React.useState(false);

  const onCancelClick = () => {
    // localStorage.clear();
    local.removeAccessToken();
    local.removeRefreshToken();
    local.removeStore();
    window.location = '/';
  };

  const onInvalidSubmit = () => {
    setIsInvalid(true);
  };

  const onValidSubmit = (model) => {
    setIsInvalid(false);
    props.termsAcceptanceActions.acceptTerms(
      model.useTerms,
      model.privacyPolicy
    );
  };

  return (
    <div>
      <formsy onValidSubmit="{onValidSubmit}" onInvalidSubmit="{onInvalidSubmit}">
        <grid container="" spacing="{4}">
          <grid item="" xs="{12}">
            <card>
              <grid container="" spacing="{4}">
                <grid item="" xs="{12}">
                  <grid container="" spacing="{2}">
                    <grid item="" xs="{12}">
                      <defaulttitle>
                        Conditions générales d'utilisation
                      </defaulttitle>
                      <link to="/use-terms">Voir document
                    </grid>
                    <grid item="" xs="{12}">
                      <switch name="useTerms" label="J'accepte les conditions générales d'utilisation" validations="isTrue"></switch>
                    </grid>
                  </grid>
                </grid>
                <grid item="" xs="{12}">
                  <grid container="" spacing="{2}">
                    <grid item="" xs="{12}">
                      <defaulttitle>Politique de confidentialité</defaulttitle>
                      <link to="/privacy-policy">Voir document
                    </grid>
                    <grid item="" xs="{12}">
                      <switch name="privacyPolicy" label="J'accepte la politique de confidentialité" validations="isTrue"></switch>
                    </grid>
                  </grid>
                </grid>
              </grid>
            </card>
          </grid>
          {isInvalid && (
            <grid item="" xs="{12}">
              <errortext align="center">
                Pour utiliser la solution vous devez tout accepter
              </errortext>
            </grid>
          )}
          <grid item="" xs="{12}">
            <div>
              <grid container="" justify="space-between">
                <grid item="">
                  <progressbutton type="button" text="Annuler" color="secondary" centered="" onClick="{onCancelClick}"></progressbutton>
                </grid>
                <grid item="">
                  <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" centered="" loading="{loading}"></progressbutton>
                </grid>
              </grid>
            </div>
          </grid>
        </grid>
      </formsy>
    </div>
  );
};

const mapStateToProps = ({ termsAcceptance }) => ({
  termsAcceptance,
});

const mapDispatchToProps = (dispatch) => ({
  termsAcceptanceActions: bindActionCreators(termsAcceptanceActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AcceptTerms));
