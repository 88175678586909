import React, {useMemo, useState} from 'react';
import {Grid, Typography} from "@material-ui/core";
import {Tab} from '@material-ui/core';
import {TabContext, TabList, TabPanel} from '@material-ui/lab';
import {withStyles} from "@mui/styles";
import {PersonalizationTab} from "./components/PersonalizationTab";
import {RegulationsTab} from "./components/RegulationsTab";
import {useIntl} from "react-intl";
import {useHistory, useParams} from "react-router-dom";

const styles = {
    adminBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    },
    adminHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem'
    }
}

const Admin = ({ ...props }) => {
    const intl = useIntl();
    const history = useHistory();
    const {contract, tab} = useParams();

    const REGULATIONS_TAB_INDEX = '1'
    const PERSONALIZATION_TAB_INDEX = '2'
    const PARAMETERS_TAB_INDEX = '3'

    const tabs = [
        { label: intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.adminTabLabel.regulations'}), index: REGULATIONS_TAB_INDEX, url: 'regulations' },
        { label: intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.adminTabLabel.personalization'}), index: PERSONALIZATION_TAB_INDEX, url: 'personalization' },
        { label: intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.adminTabLabel.parameters'}), index: PARAMETERS_TAB_INDEX, url: 'parameters' }
    ]

    const currentTab = useMemo(() => {
        return tabs.find((tabItem) => tabItem.url === tab)
    }, [tab])
    const [tabValue, setTabValue] = useState(currentTab ? currentTab.index : REGULATIONS_TAB_INDEX);

    const handleChange = (event, tabValue) => {
        setTabValue(tabValue);
        const selectedTab = tabs.find((tabItem) => tabItem.index === tabValue)
        if (selectedTab) {
            history.push(`/nodes/${contract}/administration/${selectedTab.url}`, { shallow: true });
        }
    };

    return (
        <div className="{props.classes.adminBody}">

            <div className="{props.classes.adminHeader}">
                <typography variant="{'h1'}" component="{'h1'}" className="{'underline-left'}">
                    {intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.title'})}
                </typography>
            </div>

            <tabcontext value="{tabValue}">
                <div className="{'tabs-container'}">
                    <tablist onChange="{handleChange}">
                        { tabs.map((tab, index) => {
                            return (
                                <tab key="{index}" label="{tab.label}" value="{tab.index}" disableFocusRipple=""></tab>
                            )
                        }) }
                    </tablist>
                    <tabpanel value="{REGULATIONS_TAB_INDEX}">
                        <regulationstab></regulationstab>
                    </tabpanel>
                    <tabpanel value="{PERSONALIZATION_TAB_INDEX}">
                        <personalizationtab></personalizationtab>
                    </tabpanel>
                    <tabpanel value="{PARAMETERS_TAB_INDEX}">
                        <grid container="" spacing="{4}">
                        </grid>
                    </tabpanel>
                </div>
            </tabcontext>
        </div>
    );
};

export default withStyles(styles)(Admin);
