import React from 'react';
import { connect } from 'react-redux';
import { Grid, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { DefaultTitle } from '../../../../components/Common/components/Texts/components/DefaultTitle';
import { Card } from '../../../../components/Common/components/Card';
import { DefaultText } from '../../../../components/Common/components/Texts/components/DefaultText';
import { HiddenInput } from '../../../../components/Common/components/Inputs/components/HiddenInput';
import { Select } from '../../../../components/Common/components/Inputs/components/Select';
import { TextField } from '../../../../components/Common/components/Inputs/components/TextField';
import { uuidv4 } from '../../../../helpers/UUIDHelper';
import * as Resources from '../../../../Resources';
import '../../../../helpers/StringHelper';
import { useIntl } from 'react-intl';

const ChallengeAwardList = ({
  initialAwards = [],
  awardTypes,
  initialAwardTypeId = null,
  challengeTypeCode,
  readonly,
  ...props
}) => {
  const intl = useIntl();
  const getInitialAwards = () => {
    if (initialAwards && initialAwards.length > 0) {
      return initialAwards.map((x) => ({ key: uuidv4(), points: x.points }));
    } else {
      return [{ key: uuidv4(), points: null }];
    }
  };

  const maxAwardTypeId = awardTypes[0].id;
  const finalInitialAwardTypeId = initialAwardTypeId
    ? initialAwardTypeId
    : maxAwardTypeId;
  const [awardType, setAwardType] = React.useState(finalInitialAwardTypeId);
  const [awards, setAwards] = React.useState(getInitialAwards);
  const isMaxAward = awardType == maxAwardTypeId;
  const { points, loading } = props.challengeTypeUsablePoints;
  const usablePoints = points
    ? !isMaxAward
      ? points.all
      : points.participant
    : 0;

  const onAddClick = () => {
    setAwards((x) => x.concat([{ key: uuidv4(), points: null }]));
  };

  const onAwardTypeChange = (type) => {
    setAwardType(type);
    setAwards((x) => x.filter((y) => x.indexOf(y) == 0));
  };

  const onRemoveClick = (key) => {
    setAwards((x) => x.filter((y) => y.key != key));
  };

  return (
    <div>
      <grid container="" spacing="{1}">
        <grid item="" xs="{12}">
          <grid container="" spacing="{2}">
            <grid item="">
              <defaulttitle>
                {intl.formatMessage({ id: 'challenge.award_list.title' })}
              </defaulttitle>
            </grid>
            {!isMaxAward && (
              <grid item="">
                <iconbutton size="small" onClick="{onAddClick}">
                  <fontawesomeicon size="xs" icon="{faPlus}"></fontawesomeicon>
                </iconbutton>
              </grid>
            )}
          </grid>
        </grid>
        <grid item="" xs="{12}">
          <card>
            <grid container="" spacing="{2}">
              <grid item="" xs="{12}">
                {loading && (
                  <defaulttext>
                    {intl.formatMessage({
                      id: 'challenge.award_list.points_calculation_message',
                    })}
                  </defaulttext>
                )}
                {!loading && (
                  <defaulttext>
                    {intl
                      .formatMessage({
                        id: 'challenge.award_list.usable_points',
                      })
                      .format(usablePoints)}
                  </defaulttext>
                )}
                <hiddeninput name="usablePoints" value="{usablePoints}"></hiddeninput>
              </grid>
              <grid item="" xs="{3}">
                <select name="awardType" label="{intl.formatMessage({" id:="" 'challenge.award_list.type_label',="" })}="" options="{awardTypes}" initial="{finalInitialAwardTypeId}" emptyDisabled="" onChange="{onAwardTypeChange}" optionValueName="id" optionTextName="name" fullWidth="" required="" disabled="{readonly}" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}=""></select>
              </grid>
              {awards.map((award, index) => {
                const number = index + 1;
                const label = isMaxAward
                  ? challengeTypeCode === 'CT' || challengeTypeCode === 'TP'
                    ? intl.formatMessage({
                        id: 'challenge.award_list.team_max_point_label',
                      })
                    : intl.formatMessage({
                        id: 'challenge.award_list.collaborator_max_point_label',
                      })
                  : challengeTypeCode === 'CT' || challengeTypeCode === 'TP'
                  ? intl
                      .formatMessage({
                        id: 'challenge.award_list.team_point_label',
                      })
                      .format(number)
                  : intl
                      .formatMessage({
                        id: 'challenge.award_list.collaborator_point_label',
                      })
                      .format(number);
                const validations = isMaxAward
                  ? 'isLessThanOrEquals:usablePoints'
                  : 'isRankingValid';
                const validationErrors = isMaxAward
                  ? {
                      isDefaultRequiredValue: intl.formatMessage({
                        id: 'common.form.required_error',
                      }),
                      isLessThanOrEquals: 'La récompense est trop élevée',
                    }
                  : {
                      isDefaultRequiredValue: intl.formatMessage({
                        id: 'common.form.required_error',
                      }),
                      isRankingValid: 'La récompense est trop élevée',
                    };
                return (
                  <grid key="{award.key}" item="" xs="{3}">
                    <grid container="" spacing="{1}" alignItems="flex-end">
                      <grid item="" xs="">
                        <textfield name="{`award[${index}]`}" label="{label}" fullWidth="" required="" initial="{award.points}" validations="{validations}" validationErrors="{validationErrors}"></textfield>
                      </grid>
                      {!isMaxAward && awards.length > 1 && (
                        <grid item="">
                          <iconbutton size="small" onClick="{()" ==""> onRemoveClick(award.key)}
                          >
                            <fontawesomeicon icon="{faTrashAlt}"></fontawesomeicon>
                          </iconbutton>
                        </grid>
                      )}
                    </grid>
                  </grid>
                );
              })}
            </grid>
          </card>
        </grid>
      </grid>
    </div>
  );
};

const mapStateToProps = ({ challengeTypeUsablePoints }) => ({
  challengeTypeUsablePoints,
});

export default connect(mapStateToProps)(ChallengeAwardList);
