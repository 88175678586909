import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  DefaultText,
  RoundedTabs,
  RoundedTab,
} from '../../../../../../components';

const styles = {
  title: {
    paddingTop: 16,
    paddingBottom: 16,
  },
};

const ParticipantTypeFilter = (props) => {
  const { classes, handleTypeChange } = props;
  const [value, setValue] = React.useState(0);

  function handleChange(e, value) {
    let type = value == 0 ? 'P' : 'T';
    setValue(value);
    handleTypeChange(type);
  }

  return (
    <div>
      <defaulttext align="center" className="{classes.title}">
        Règles de gestion des points
      </defaulttext>
      <roundedtabs value="{value}" onChange="{handleChange}" variant="fullWidth">
        <roundedtab label="Joueurs"></roundedtab>
        <roundedtab label="Équipes"></roundedtab>
      </roundedtabs>
    </div>
  );
};

export default withStyles(styles)(ParticipantTypeFilter);
