import React from 'react';
import { connect } from 'react-redux';
import { CardMedia, TableBody } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import {
  faRandom,
  faRocket,
  faSortAmountDown,
} from '@fortawesome/free-solid-svg-icons';
import { Rank } from './components';
import {
  Table,
  TableHead,
  TableHeadCell,
  TableRow,
} from '../../../../../../components';
import * as Resources from '../../../../../../Resources';
import { useIntl } from 'react-intl';

const styles = {
  icon: {
    height: 34,
    width: 34,
    margin: 'auto',
  },
};

const RankList = ({
  challengeRank,
  generalRank,
  generalRankIcon,
  categoryRanks,
  onChallengeClick,
  onGeneralClick,
  onCategoryClick,
  ...props
}) => {
  const intl = useIntl();
  const { classes } = props;
  const { account } = props.accountDetail;

  return (
    <div>
      <table>
        <tablehead>
          <tablerow>
            <tableheadcell colSpan="{2}" align="left">
              {intl.formatMessage({ id: 'ranking.columns.name' })}
            </tableheadcell>
            <tableheadcell align="right">
              <fontawesomeicon icon="{faSortAmountDown}"></fontawesomeicon>
            </tableheadcell>
            <tableheadcell align="right">
              {Resources.RANK_LIST_POINTS_COLUMN}
            </tableheadcell>
            <tableheadcell align="right">
              <fontawesomeicon icon="{faRandom}"></fontawesomeicon>
            </tableheadcell>
          </tablerow>
        </tablehead>
        <tablebody>
          {account.hasGeneralRankAccess && generalRank && (
            <rank image="{" <FontAwesomeIcon="" icon="{generalRankIcon}" className="{classes.icon}"></rank>
              }
              name={intl.formatMessage({ id: 'ranking.general_title' })}
              rank={generalRank}
              onClick={onGeneralClick}
            />
          )}
          {account.hasCategoryRankAccess &&
            categoryRanks.map((rank) => {
              const icon = (
                <cardmedia image="{rank.category.icon.path}" className="{classes.icon}"></cardmedia>
              );
              return (
                <rank key="{rank.id}" image="{icon}" name="{rank.category.name}" rank="{rank}" onClick="{()" =="">
                    onCategoryClick(rank.category.id, rank.periodId)
                  }
                />
              );
            })}
          {account.hasCategoryRankAccess && challengeRank && (
            <rank image="{" <FontAwesomeIcon="" icon="{faRocket}" className="{classes.icon}"></rank>
              }
              name={intl.formatMessage({ id: 'ranking.challenges_title' })}
              rank={challengeRank}
              onClick={onChallengeClick}
            />
          )}
        </rank></tablebody>
      </table>
    </div>
  );
};

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(withStyles(styles)(RankList));
