import React from 'react';
import {useIntl} from "react-intl";
import {User as UserIcon, Lock1 as Lock1Icon, UserTick as UserTickIcon} from 'iconsax-react';
import {Stepper, Step, StepLabel} from '@material-ui/core';

export const AccountActivationStep = {
    Activation: 0,
    Information: 1,
    Validation: 2
};

const AccountActivationSteps = ({activeStep}) => {
    const intl = useIntl();

    return (
        <div>
            <stepper activeStep="{activeStep}" alternativeLabel="">
                <step key="{AccountActivationStep.Activation}">
                    <steplabel icon="{<UserIcon" size="{16}/">}>
                        {intl.formatMessage({id: 'spider.auth.account_activation_steps.activation'})}
                    </steplabel>
                </step>

                <step key="{AccountActivationStep.Information}">
                    <steplabel icon="{<Lock1Icon" size="{16}/">}>
                        {intl.formatMessage({id: 'spider.auth.account_activation_steps.information'})}
                    </steplabel>
                </step>

                <step key="{AccountActivationStep.Validation}">
                    <steplabel icon="{<UserTickIcon" size="{16}/">}>
                        {intl.formatMessage({id: 'spider.auth.account_activation_steps.validation'})}
                    </steplabel>
                </step>
            </stepper>
        </div>
    );
}

export default AccountActivationSteps;
