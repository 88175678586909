import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, CardMedia } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { faSortAmountDown, faGift } from '@fortawesome/free-solid-svg-icons';
import {
  FullTableCell,
  RankEvolution,
  Table,
  TableBody,
  TableChip,
  TableHead,
  TableHeadCell,
  TableRow,
  TableRowHighlight,
  FixedTableCell,
  Tooltip,
  Card,
  FlexibleTableCell,
  ThemeWrapper,
} from '../../../../components';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { ChallengeReward } from '../';
import { useIntl } from 'react-intl';
import _ from 'lodash';

const styles = {
  photo: {
    height: 28,
    width: 28,
  },
  levelIcon: {
    height: 28,
    width: 28,
  },
  tooltip: {
    minWidth: 320,
  },
  transparentTooltip: {
    background: 'transparent',
  },
  tableWrapper: {
    boxShadow: '0 2px 16px 0 rgba(16,61,92,0.25)',
    overflowX: 'auto',
  },
  positionCell: {
    paddingLeft: 5,
    paddingRight: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};

const TeamGroupChallengeRankList = ({
  ranks,
  teamId,
  classes,
  displayCollaboratorDepartment,
  ...props
}) => {
  const intl = useIntl();
  const hasRanking = ranks.reduce((acc, rank) => rank.rank || acc, false);
  const hasRankAward = (rank) =>
    rank.awards.length > 0 &&
    ((rank.award_type_code === 'C' && rank.race_position) ||
      rank.award_type_code === 'R');
  const coinImage = require(`../../../../assets/img/system/challenge/icons/coin.png`);
  const giftImage = require(`../../../../assets/img/system/challenge/icons/gift.png`);
  const hasAwards = ranks.reduce(
    (acc, rank) => hasRankAward(rank) || acc,
    false
  );
  let borderTop = false;
  const isMobile = isWidthDown('xs', props.width);
  const cellWidth = isMobile ? 100 : 'auto';

  const { successColor, errorColor } = useContext(ThemeWrapper.Context);
  return (
    <div className="{classes.tableWrapper}">
      <table>
        <tablehead>
          <tablerow>
            <tableheadcell colSpan="{1}">
              <fontawesomeicon icon="{faSortAmountDown}"></fontawesomeicon>
            </tableheadcell>

            <tableheadcell colSpan="{1}">
              {intl.formatMessage({
                id: 'challenge.ranking.collaborator_team_group_column',
              })}
            </tableheadcell>

            <tableheadcell>
              <grid container="" justify="center">
                <grid 5="" item="" style="{{" marginRight:="" }}="">
                  <fontawesomeicon icon="{faGift}"></fontawesomeicon>
                </grid>
              </grid>
            </tableheadcell>
            <tableheadcell style="{{" textAlign:="" 'center'="" }}="">
              {intl.formatMessage({ id: 'challenge.ranking.points_column' })}
            </tableheadcell>
          </tablerow>
        </tablehead>
        <tablebody>
          {ranks.map((rank, index) => {
            const selected = rank.team ? rank.team.id == teamId : false;
            const color = !selected ? 'default' : 'primary';
            const hasAward = hasRankAward(rank);
            const isRaceMode = rank.award_type_code === 'C';
            if (
              hasAwards &&
              !hasAward &&
              hasRanking &&
              index > 0 &&
              borderTop === false
            ) {
              borderTop = index;
            }
            const TableRowComponent = hasAward ? TableRowHighlight : TableRow;
            const pointEvolution = rank.points - rank.previous_points;
            return (
              <tablerowcomponent key="{rank.id}" style="{{" borderTop:="" borderTop="==" index="" ?="" '2px="" solid="" #333'="" :="" '',="" background:="" selected="" '#E4F6E0'="" 'auto',="" }}="">
                <fulltablecell>
                  <div 76="" 90="" className="{classes.positionCell}" style="{{" width:="" isMobile="" ?="" :="" }}="">
                    <span>
                      <tablechip color="default" label="{rank.rank" ?="" rank.rank="" :="" '-'}=""></tablechip>
                    </span>
                    {rank.evolution !== 0 && (
                      <react.fragment>
                        <span 8="" style="{{" marginLeft:="" }}="">
                          <rankevolution evolution="{rank.evolution}"></rankevolution>
                        </span>
                        <span style="{{" marginLeft:="" 3,="" fontWeight:="" hasAward="" ?="" 'bold'="" :="" '',="" color:="" rank.evolution=""> 0
                                ? successColor
                                : rank.evolution < 0
                                ? errorColor
                                : 'auto',
                          }}
                        >
                          {Math.abs(rank.evolution)}
                        </span>
                      </react.fragment>
                    )}
                  </div>
                </fulltablecell>
                <flexibletablecell style="{{" fontWeight:="" hasAward="" ?="" 'bold'="" :="" '',="" minWidth:="" cellWidth,="" maxWidth:="" }}="" color="{color}">
                  {rank.team_group.name}
                </flexibletablecell>
                <fixedtablecell style="{{" verticalAlign:="" 'middle'="" }}="" color="{color}">
                  {rank.awards && rank.awards.length > 0 && (
                    <grid container="" justify="center">
                      {rank.awards[0].reward ? (
                        <grid item="">
                          <tooltip 320="" className="{`${classes.tooltip}" ${classes.transparentTooltip}`}="" title="{" <div="" style="{{" minWidth:="" }}="">
                                <card>
                                  <challengereward reward="{rank.awards[0].reward}"></challengereward>
                                </card>
                              </tooltip></grid></grid></fixedtablecell></tablerowcomponent></tablebody></table></div>
                            }
                          >
                            <cardmedia image="{giftImage}" style="{{" height:="" 20,="" width:="" marginRight:="" 5,="" marginTop:="" -2,="" }}=""></cardmedia>
                          
                        
                      ) : (
                        <grid item="">
                          <tooltip title="{" <Grid="" container="" spacing="{1}">
                                <grid item="">{rank.awards[0].points}</grid>
                                <grid item="">
                                  <cardmedia 20="" image="{coinImage}" style="{{" height:="" 20,="" width:="" }}=""></cardmedia>
                                </grid>
                              </tooltip></grid>
                            }
                          >
                            <cardmedia image="{coinImage}" style="{{" height:="" 20,="" width:="" marginRight:="" 5,="" marginTop:="" -2,="" }}=""></cardmedia>
                          
                        
                      )}
                    
                  )}
                
                <flexibletablecell style="{{" fontWeight:="" hasAward="" ?="" 'bold'="" :="" '',="" textAlign:="" 'center',="" verticalAlign:="" 'middle',="" }}="" color="{color}">
                  <div>
                    {rank.points.toLocaleString()}
                    {isRaceMode ? `/${rank.goals_count}` : ''}
                  </div>
                  {pointEvolution !== 0 && (
                    <div style="{{" fontWeight:="" hasAward="" ?="" 'bold'="" :="" '',="" color:="" pointEvolution=""> 0
                            ? successColor
                            : pointEvolution < 0
                            ? errorColor
                            : 'auto',
                      }}
                    >
                      {pointEvolution > 0 ? '+' : pointEvolution < 0 ? '-' : ''}
                      <span 3="" style="{{" marginLeft:="" }}="">
                        {Math.abs(pointEvolution).toLocaleString()}
                      </span>
                    </div>
                  )}
                </flexibletablecell>
              
            );
          })}
        
      
    