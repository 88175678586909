import React from 'react';
import {
  BallAnimation,
  BicycleAnimation,
  CheckAnimation,
  ConfettiAnimation,
  FireworksAnimation,
  Like2Animation,
  LikeAnimation,
  Loading2Animation,
  LoadingAnimation,
  NinjaAnimation,
  PlanetAnimation,
  Rocket2Animation,
  RocketAnimation,
  Search2Animation,
  ShareAnimation,
  SparklesAnimation,
  SpeedAnimation,
  Stars2Animation,
  StarsAnimation,
  SuccessAnimation,
  WaterfallAnimation,
} from './components';

const animations = [
  <ballanimation></ballanimation>,
  <bicycleanimation></bicycleanimation>,
  <checkanimation></checkanimation>,
  <confettianimation></confettianimation>,
  <fireworksanimation></fireworksanimation>,
  <likeanimation></likeanimation>,
  <like2animation></like2animation>,
  <loadinganimation></loadinganimation>,
  <loading2animation></loading2animation>,
  <ninjaanimation></ninjaanimation>,
  <planetanimation></planetanimation>,
  <rocketanimation></rocketanimation>,
  <rocket2animation></rocket2animation>,
  <search2animation></search2animation>,
  <sparklesanimation></sparklesanimation>,
  <speedanimation></speedanimation>,
  <shareanimation></shareanimation>,
  <starsanimation></starsanimation>,
  <stars2animation></stars2animation>,
  <successanimation></successanimation>,
  <waterfallanimation></waterfallanimation>,
];

const AnimationController = ({ ...props }) => {
  const animation = animations[Math.floor(Math.random() * animations.length)];

  return <div>{animation}</div>;
};

export default AnimationController;
