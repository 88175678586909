import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Tag } from '../../../Teams/components/Team/components';
import { DefaultTitle, ErrorText, InfoText, Avatar } from '../../..';
import { useIntl } from 'react-intl';
import '../../../../helpers/StringHelper';
import _ from 'lodash';

const styles = {
  managerAvatar: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  avatarGroup: {},
  avatar: {
    width: 30,
    height: 30,
  },
  tag: {
    backgroundColor: '#F2F5FC',
    color: '#43586C',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
};

const TeamGroup = ({
  team,
  classes,
  hideTeamGroupUsers,
  teamNumber,
  teamNumberWording,
  hideManager,
  image,
  ...props
}) => {
  const intl = useIntl();
  const players = _.get(team, 'collaborators.length');
  const managerPhoto = _.get(
    team,
    'manager.photo',
    '/assets/img/user/avatar.svg'
  );
  const collaboratorList = _.compact([
    _.get(team, 'manager', null),
    ..._.get(team, 'collaborators', []),
  ]);
  const avatarLimit = 6;

  const teamNumberWordingId =
    teamNumberWording && teamNumberWording === 'team_groups'
      ? 'team.group_team_groups_text'
      : 'team.group_teams_text';
  return (
    <div>
      <grid container="" style="{{}}">
        <grid 8="" item="" xs="{2}" style="{{" minWidth:="" 40,="" marginRight:="" }}="">
          <avatar src="{image" ||="" managerPhoto}="" entityId="{_.get(team," 'manager.id')}="" fallbackName="{_.get(team," 'manager.fullname')}=""></avatar>
        </grid>
        <grid item="" xs="{9}" container="" alignItems="center">
          <grid item="" xs="{12}">
            <defaulttitle 18="" style="{{" textAlign:="" 'left',="" fontWeight:="" 'bold',="" textTransform:="" 'none',="" fontSize:="" hideManager="" ?="" :="" 16,="" maxWidth:="" 320,="" }}="" noWrap="">
              {team.name}
            </defaulttitle>
          </grid>

          {teamNumber !== undefined ? (
            <grid 18="" item="" zeroMinWidth="" style="{{" borderRadius:="" 5,="" overflow:="" 'hidden',="" height:="" }}="">
              <tag className="{classes.tag}">
                {intl
                  .formatMessage({ id: teamNumberWordingId })
                  .format(_.get(team, 'teams.length', teamNumber))}
              </tag>
            </grid>
          ) : (
            <react.fragment>
              {!hideManager && (
                <grid item="" xs="{12}" zeroMinWidth="" style="{{" textAlign:="" 'left'="" }}="">
                  {team.manager && (
                    <infotext 14="" style="{{" textTransform:="" 'none',="" fontSize:="" }}="" noWrap="">
                      {intl
                        .formatMessage({ id: 'team.manager_text' })
                        .format(team.manager.firstname, team.manager.lastname)}
                    </infotext>
                  )}
                  {!team.manager && (
                    <errortext 14="" style="{{" textTransform:="" 'none',="" fontSize:="" }}="" noWrap="">
                      {intl.formatMessage({ id: 'team.no_manager_text' })}
                    </errortext>
                  )}
                </grid>
              )}
            </react.fragment>
          )}
          {!hideTeamGroupUsers && (
            <grid item="" xs="{12}" zeroMinWidth="" style="{{" textAlign:="" 'left'="" }}="">
              <infotext style="{{" textTransform:="" 'none',="" color:="" 'rgb(15,111,222)'="" }}="">
                {intl
                  .formatMessage({ id: 'team.group_collaborators_text' })
                  .format(_.get(collaboratorList, 'length', 0))}
              </infotext>
            </grid>
          )}
        </grid>
      </grid>
    </div>
  );
};

export default withStyles(styles)(TeamGroup);
