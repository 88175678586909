import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { IconButton } from '../../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { ClassNames } from '@emotion/react';

const styles = {};

// entries is an array of objects with the following structure:
// {
//  title: 'string',
//  icon: '<fontawesomeicon></fontawesomeicon>',
//  onClick: () => {}
const PostMenu = ({ entries, classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // useEffect(() => {
  //     setOpen(Boolean(anchorEl))
  // }, [anchorEl])

  return (
    <react.fragment>
      {entries.length > 0 && (
        <react.fragment>
          <iconbutton aria-controls="basic-menu" aria-haspopup="true" size="{'small'}" onClick="{(event)" ==""> setAnchorEl(open ? null : event.currentTarget)}
          >
            <fontawesomeicon icon="{faEllipsisH}" style="{{" color:="" '#333'="" }}=""></fontawesomeicon>
          </iconbutton>
          <menu id="basic-menu" anchorEl="{anchorEl}" open="{open}" onClose="{()" ==""> setAnchorEl()}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {entries.map((entry, index) => (
              <menuitem onClick="{()" ==""> {
                  setAnchorEl();
                  entry.onClick();
                }}
              >
                <listitemicon 10="" style="{{" color:="" '#333',="" minWidth:="" 0,="" marginRight:="" }}="">
                  {entry.icon}
                </listitemicon>
                <listitemtext>{entry.title}</listitemtext>
              </menuitem>
            ))}
          </menu>
        </react.fragment>
      )}
    </react.fragment>
  );
};

export default withStyles(styles)(PostMenu);
