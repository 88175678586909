import React, { useState, useRef, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import {
  DefaultText,
  PostCommentForm,
  Loader,
  TimeAgo,
  Tooltip,
} from '../../..';
import { I18nWrapper } from '../../../../../';
import { PostCommentContent, PostCommentReply } from './components';
import { LikeList } from '../LikeList';
import { LikesAvatars } from '../../..';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faThumbsUp,
  faClock,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { useIntl } from 'react-intl';
import _ from 'lodash';

const styles = {
  dialog: {
    width: 500,
  },
  iconButton: {
    width: 40,
    height: 40,
  },
  textField: {
    width: '100%',
  },
  commentButton: {
    cursor: 'pointer',
  },

  subCommentWrapper: {
    marginLeft: 50,
    marginBottom: 20,
  },
  commentReactionSeparator: {
    userSelect: 'none',
    color: '#ccc',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover p': {
      color: 'rgb(15,111,222)',
      textDecoration: 'underline',
    },
  },
};

const PostComment = ({
  classes,
  comment: commentInput,
  index,
  onSubmit,
  onLike,
  onDislike,
  onReport,
  onDelete,
  onUpdate,
  ...props
}) => {
  const { account } = props.accountDetail;
  const context = useContext(I18nWrapper.Context);
  const timeAgo = new TimeAgo(context.locale);
  const [displaySubCommentForm, setDisplaySubCommentForm] = useState(false);
  const [comment, setComment] = useState(commentInput);
  const [replies, setReplies] = useState(comment.replies);
  const [likes, setLikes] = useState(comment.likes);
  const [likesCount, setLikesCount] = useState(comment.likes_count);
  const [likeState, setLikeState] = useState(comment.current_user_like);
  const [likeListOpen, setLikeListOpen] = useState(false);
  const isAuthor =
    account.id === comment.author.id || _.get(account, 'role.code') === 'A';
  const date = comment.date.toDate();

  const intl = useIntl();

  useEffect(() => {
    setComment(commentInput);
    setReplies(commentInput.replies);
    setLikes(commentInput.likes);
    setLikesCount(commentInput.likes_count);
    setLikeState(commentInput.current_user_like);
  }, [commentInput]);

  // useEffect(() => {
  //     if (!initialized) {
  //         setInitialized(true)
  //     }
  //     if (initialized) {
  //         if (likeState) {
  //             onLike(comment.id)
  //             setLikesCount(likesCount + 1)
  //         } else {
  //             onDislike(comment.id)
  //             setLikesCount(likesCount - 1)
  //         }
  //     }
  // }, [likeState])
  const commentLike = (state) => {
    setLikeState(state);
    setLikesCount(state ? likesCount + 1 : likesCount - 1);
    setLikes(
      state
        ? [...likes, { user: account }]
        : likes.filter((like) => like.user.id !== account.id)
    );
    if (state) {
      onLike(comment.id);
    } else {
      onDislike(comment.id);
    }
  };
  const commentCreate = (comment) => {
    onSubmit(comment);
    setReplies(
      replies
        ? [...replies, Object.assign({}, comment, { date: Date.now() })]
        : [comment]
    );
  };

  const deleteReply = (replyId) => {
    onDelete(replyId);
    setReplies(replies.filter((reply) => reply.id !== replyId));
  };

  const updateComment = (comment) => {
    onUpdate(comment);
    setComment(comment);
  };

  const reportWarning =
    _.get(account, 'role.code') === 'A' && comment.report_count > 0;
  return (
    <div>
      <grid container="" spacing="{1}" justifyContent="flex-end">
        <postcommentcontent comment="{comment}" onDelete="{onDelete}" onReport="{onReport}" isAuthor="{isAuthor}" isAdmin="{_.get(account," 'role.code')="==" 'A'}="" onUpdate="{updateComment}"></postcommentcontent>
        <grid item="" xs="{12}">
          <grid container="" spacing="{1}" justifyContent="flex-end">
            <grid item="" xs="" container="">
              <grid 50="" item="" style="{{" marginLeft:="" }}="">
                {reportWarning ? (
                  <tooltip title="{" reportWarning="" ?="" intl.formatMessage({="" id:="" 'newsfeed.reported_comment',="" })="" :="" null="" }="">
                    <div>
                      <defaulttext lowercase="" style="{{" fontSize:="" 12,="" color:="" '#f44336'="" }}="">
                        <fontawesomeicon 5="" icon="{faExclamationTriangle}" style="{{" marginRight:="" }}=""></fontawesomeicon>
                        {timeAgo.format(date, 'twitter')}
                      </defaulttext>
                    </div>
                  </tooltip>
                ) : (
                  <defaulttext 12="" lowercase="" style="{{" fontSize:="" }}="">
                    <fontawesomeicon 5="" icon="{faClock}" style="{{" marginRight:="" }}=""></fontawesomeicon>
                    {timeAgo.format(date, 'twitter')}
                  </defaulttext>
                )}
              </grid>
            </grid>
            {!comment.id && (
              <grid item="">
                <loader></loader>
              </grid>
            )}
            {comment.id && (
              <react.fragment>
                <grid item="">
                  <grid container="" spacing="{1}">
                    <grid item="">
                      <defaulttext lowercase="" onClick="{()" ==""> commentLike(!likeState)}
                        className={classes.commentButton}
                        style={{ color: likeState ? '#00E58D' : '' }}
                      >
                        {intl.formatMessage({ id: 'newsfeed.like' })}
                      </defaulttext>
                    </grid>
                    {likesCount > 0 && (
                      <react.fragment>
                        <grid item="" className="{classes.commentReactionSeparator}">
                          ·
                        </grid>
                        <grid item="" onClick="{()" ==""> setLikeListOpen(true)}
                          className={classes.link}
                        >
                          <grid container="" spacing="{1}">
                            <grid item="">
                              <defaulttext lowercase="">
                                <fontawesomeicon icon="{faThumbsUp}"></fontawesomeicon>
                              </defaulttext>
                            </grid>
                            <grid item="">
                              <likesavatars likes="{likes}" limit="{3}"></likesavatars>
                            </grid>
                          </grid>
                        </grid>
                      </react.fragment>
                    )}
                  </grid>
                </grid>

                <grid item="" className="{classes.commentReactionSeparator}">
                  |
                </grid>
                <grid item="">
                  <grid container="" spacing="{1}">
                    <grid item="">
                      <defaulttext lowercase="" onClick="{()" ==""> setDisplaySubCommentForm(true)}
                        className={classes.commentButton}
                      >
                        {intl.formatMessage({ id: 'newsfeed.reply' })}
                      </defaulttext>
                    </grid>
                    {replies && replies.length > 0 && (
                      <react.fragment>
                        <grid item="" className="{classes.commentReactionSeparator}">
                          ·
                        </grid>
                        <grid item="">
                          <grid container="" spacing="{1}">
                            <grid item="">
                              <defaulttext lowercase="">
                                <fontawesomeicon icon="{faComment}"></fontawesomeicon>
                              </defaulttext>
                            </grid>
                            <grid item="">
                              <defaulttext lowercase="">
                                {replies.length}
                              </defaulttext>
                            </grid>
                          </grid>
                        </grid>
                      </react.fragment>
                    )}
                  </grid>
                </grid>
              </react.fragment>
            )}
          </grid>
        </grid>
        {displaySubCommentForm && (
          <grid item="" xs="{12}">
            <div className="{classes.subCommentWrapper}">
              <grid container="" spacing="{2}">
                <grid item="" xs="{12}">
                  <postcommentform onSubmit="{(model)" =="">
                      commentCreate(
                        Object.assign({}, model, { parent: comment.id })
                      )
                    }
                  />
                </postcommentform></grid>
                {replies &&
                  replies.map((reply, index) => (
                    <react.fragment>
                      <grid item="" xs="{12}">
                        <postcommentreply reply="{reply}" onLike="{onLike}" onDislike="{onDislike}" onDelete="{deleteReply}" onUpdate="{onUpdate}" onReport="{onReport}"></postcommentreply>
                      </grid>
                    </react.fragment>
                  ))}
              </grid>
            </div>
          </grid>
        )}
      </grid>
      <likelist open="{likeListOpen}" likes="{likes}" setOpen="{setLikeListOpen}"></likelist>
    </div>
  );
};

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(withStyles(styles)(PostComment));
