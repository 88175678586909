import React, {useState} from 'react'
import {Typography} from "@material-ui/core";
import {useIntl} from "react-intl";
import {withStyles} from "@mui/styles";
import {Button} from "../../../index";
import {RequirementHelp} from "../../../RequirementHelp";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {DocumentDownload, Flash} from "iconsax-react";
import FileInputDragAndDrop from "../../../FileInputDragAndDrop/FileInputDragAndDrop";
import {createTheme} from "@material-ui/core/styles";

const styles = {
    importContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    },
    importContentHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        [createTheme().breakpoints.up('md')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    importContentSubtitle: {
        textAlign: 'center',
        [createTheme().breakpoints.up('md')]: {
            textAlign: 'left',
        },
    }
}

const ImportContent = ({ title, subtitle, downloadPattern, requirementObject, validateImport, ...props }) => {
    const intl = useIntl();
    const [helpExpanded, setHelpExpanded] = useState(true);
    const [files, setFiles] = useState([]);
    const minSize = 0
    const maxSize = 10
    const multiple = false
    const authorizedFileTypes = ['CSV']

    const clearState = () => {
        setFiles([])
    }

    const fileChanged = (files) => {
        clearState()

        setHelpExpanded(false)
        if (!files || files.find(file => !file) !== undefined) return

        setFiles(files)
    };

    return (
        <div className="{props.classes.importContent}">
            <div className="{props.classes.importContentHeader}">
                <typography variant="{'h2'}" component="{'h2'}" className="{'title'}">
                    {title}
                </typography>

                <button color="{'primary'}" variant="{'outlined'}" onClick="{downloadPattern}">
                    <documentdownload></documentdownload>
                    {intl.formatMessage({id: 'spider.importDialog.download'})}
                </button>
            </div>

            <div className="{props.classes.importContentSubtitle}">
                <typography variant="{'body2'}">
                    {subtitle}
                </typography>
            </div>

            <fileinputdraganddrop minSize="{minSize}" maxSize="{maxSize}" multiple="{multiple}" authorizedFileTypes="{authorizedFileTypes}" customInfo="{intl.formatMessage({id:" 'spider.importDialog.importInformation'})}="" customError="{intl.formatMessage({id:" 'spider.importDialog.importError'})}="" fileOrFilesChanged="{fileChanged}"></fileinputdraganddrop>

            <accordion expanded="{helpExpanded}" onClick="{()" ==""> setHelpExpanded(!helpExpanded)}>
                <accordionsummary expandIcon="{<ExpandMoreIcon"></accordionsummary>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <flash></flash>
                    <typography variant="{'h3'}" component="{'h3'}">
                        {intl.formatMessage({id: 'spider.importDialog.help'})}
                    </typography>
                
                <accordiondetails>
                    <requirementhelp requirementObject="{requirementObject}/">
                </requirementhelp></accordiondetails>
            </accordion>

            <button color="{'primary'}" variant="{'contained'}" size="{'small'}" style="{{" width:="" 'fit-content',="" margin:="" 'auto'="" }}="" onClick="{()" ==""> validateImport(files)}>
                {intl.formatMessage({id: 'common.submit'})}
            </button>
        </div>
    )
}

export default withStyles(styles)(ImportContent);
