import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { Period, SubHeader } from './components';
import {
  MainLayoutComponent,
  ProgressButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '../../../../components';
import * as collaboratorDataListActions from '../../../../services/CollaboratorData/CollaboratorDataList/actions';
import * as collaboratorDataUpdateActions from '../../../../services/CollaboratorData/CollaboratorDataUpdate/actions';
import * as kpiDetailActions from '../../../../services/Kpis/KpiDetail/actions';
import { toast } from 'react-toastify';

class AdminReportDetail extends MainLayoutComponent {
  constructor(props) {
    super(props);
    this.props.collaboratorDataUpdateActions.clearCollaboratorDataUpdate();
  }

  componentDidMount() {
    const { intl } = this.props;
    const id = this.props.match.params.id;
    this.props.handleTitle(intl.formatMessage({ id: 'admin.title' }));
    this.props.handleSubHeader(<subheader></subheader>);
    this.props.activateReturn();
    this.props.collaboratorDataListActions.getCollaboratorDataList(id);
    this.props.kpiDetailActions.getKpiDetail(id);
  }

  handleSubmit(model) {
    const data = [];
    const keys = Object.keys(model);
    keys.map((key) => {
      const item = { id: key, value: model[key] };
      data.push(item);
    });
    this.props.collaboratorDataUpdateActions.updateCollaboratorData(data);
  }

  renderData() {
    const { intl } = this.props;
    const { data } = this.props.collaboratorDataList;
    const { kpi } = this.props.kpiDetail;
    const { loading } = this.props.collaboratorDataUpdate;
    const firstData = data && data.length > 0 ? data[0] : null;

    return (
      <formsy onSubmit="{this.handleSubmit.bind(this)}">
        <grid container="" spacing="{4}">
          <grid item="" xs="{12}">
            <table>
              <tablehead>
                <tablerow>
                  <tableheadcell>ID</tableheadcell>
                  <tableheadcell>
                    {intl.formatMessage({ id: 'common.collaborator' })}
                  </tableheadcell>
                  <tableheadcell>
                    {intl.formatMessage({ id: 'common.teams' })}
                  </tableheadcell>
                  {firstData && firstData.dataId && (
                    <tableheadcell>
                      <period periodicity="{firstData.periodicity}" start="{firstData.dataStart}"></period>
                    </tableheadcell>
                  )}
                  {firstData && firstData.previousDataId && (
                    <tableheadcell>
                      <period periodicity="{firstData.periodicity}" start="{firstData.previousDataStart}"></period>
                    </tableheadcell>
                  )}
                </tablerow>
              </tablehead>
              <tablebody>
                {data.map((item) => {
                  return (
                    <tablerow key="{item.id}">
                      <tablecell>{item.id}</tablecell>
                      <tablecell>
                        {item.firstname} {item.lastname}
                      </tablecell>
                      <tablecell>{item.team}</tablecell>
                      {item.dataId && (
                        <tablecell>
                          {kpi.manual && (
                            <textfield disableInitialUpdate="" type="number" name="{item.dataId}" initial="{item.dataValue}"></textfield>
                          )}
                          {!kpi.manual && (
                            <span>{item.dataValue.toLocaleString()}</span>
                          )}
                        </tablecell>
                      )}
                      {item.previousDataId && (
                        <tablecell>
                          {kpi.manual && item.previousDataId && (
                            <textfield disableInitialUpdate="" type="number" name="{item.previousDataId}" initial="{parseFloat(item.previousDataValue)}"></textfield>
                          )}
                          {!kpi.manual && (
                            <span>
                              {item.previousDataValue.toLocaleString()}
                            </span>
                          )}
                        </tablecell>
                      )}
                    </tablerow>
                  );
                })}
              </tablebody>
            </table>
          </grid>
          {kpi.manual && (
            <grid item="" xs="{12}">
              <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" loading="{loading}" centered=""></progressbutton>
            </grid>
          )}
        </grid>
      </formsy>
    );
  }

  render() {
    const { intl } = this.props;
    const { data, loading: collaboratorDataListLoading } =
      this.props.collaboratorDataList;
    const { kpi, loading: kpiDetailLoading } = this.props.kpiDetail;
    const loading = collaboratorDataListLoading || kpiDetailLoading;
    const { success, hasError: error } = this.props.collaboratorDataUpdate;

    if (success) {
      this.props.collaboratorDataUpdateActions.clearCollaboratorDataUpdate();
      this.props.history.goBack();
      toast.success(
        intl.formatMessage({ id: 'common.update_success_message' }),
      );
    }
    if (error) {
      toast.error(intl.formatMessage({ id: 'common.update_error_message' }));
    }

    return <div>{!loading && data && kpi && this.renderData()}</div>;
  }
}

const mapStateToProps = ({
  collaboratorDataList,
  collaboratorDataUpdate,
  kpiDetail,
}) => ({
  collaboratorDataList,
  collaboratorDataUpdate,
  kpiDetail,
});

const mapDispatchToProps = (dispatch) => ({
  collaboratorDataListActions: bindActionCreators(
    collaboratorDataListActions,
    dispatch,
  ),
  collaboratorDataUpdateActions: bindActionCreators(
    collaboratorDataUpdateActions,
    dispatch,
  ),
  kpiDetailActions: bindActionCreators(kpiDetailActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AdminReportDetail));
