import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, CardMedia, IconButton, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faEquals,
  faInfoCircle,
  faUser,
  faUsers,
  faTimes,
  faClock,
  faPencilAlt as faPencil,
} from '@fortawesome/free-solid-svg-icons';
import {
  AccentTag,
  AccentText,
  AnimationController,
  BlueTag,
  BlueText,
  Card,
  DefaultText,
  DefaultTitle,
  InfoText,
  Table,
  TableBody,
  TableCell,
  TableChip,
  TableRow,
  Tooltip,
  RichText,
  Linkify,
  Dialog,
  BigText,
  ProgressBar,
} from '../../../../../components';
import * as Resources from '../../../../../Resources';
import {
  ChallengeReward,
  ChallengeRewardDetail,
  ChallengeRewardCard,
  ChallengeKpiResultUpdate,
} from '../..';
import * as collaboratorDataListActions from '../../../../../services/CollaboratorData/CollaboratorDataList/actions';
import { useIntl } from 'react-intl';
import '../../../../../helpers/StringHelper';
import _ from 'lodash';

const styles = (theme) => {
  return {
    rewardDialog: {
      width: 900,
      maxWidth: 900,
    },
    kpiResultDialog: {
      overflow: 'visible',
    },
    icon: {
      height: 100,
      width: 100,
    },
    dialogCloseIcon: {
      position: 'absolute',
      color: 'white',
      top: 10,
      right: 10,
      width: 25,
      height: 25,
      fontSize: 20,
      zIndex: 100,
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.main,
        color: 'white',
      },
    },
    link: {
      fontSize: 14,
      cursor: 'pointer',
      color: 'rgb(15,111,222)',
      opacity: 1,
      fontWeight: 'normal',
    },
  };
};

const animationController = <animationcontroller></animationcontroller>;

const ChallengeConditionJti = ({
  challenge,
  goals,
  participants,
  ...props
}) => {
  const intl = useIntl();
  const { account } = props.accountDetail;

  const [rewardDetail, setRewardDetail] = useState();
  const [currentGoal, setCurrentGoal] = useState(null);

  const start = challenge.start.toDate2().toLocaleDateString();
  const end = challenge.end.toDate2().toLocaleDateString();
  const lastUpdate = challenge.last_update
    ? challenge.last_update.toDate()
    : null;
  const lastUpdateTime = lastUpdate
    ? `${lastUpdate.getHours() < 10 ? '0' : ''}${lastUpdate.getHours()}:${
        lastUpdate.getMinutes() < 10 ? '0' : ''
      }${lastUpdate.getMinutes()}`
    : null;

  const coinImage = require(`../../../../../assets/img/system/challenge/icons/coin.png`);

  const teamIds = _.get(account, 'team.id')
    ? [_.get(account, 'team.id')]
    : _.get(account, 'team_group.allTeamIds');

  const canEdit =
    _.get(account, 'hasManagerChallengeEditAccess') ||
    _.get(account, 'role.code') === 'A';

  const pointRewardImage = require(`../../../../../assets/img/system/challenge/icons/points.png`);

  const renderMaximumAward = () => {
    const award = challenge.awards[0];

    return (
      <grid container="" spacing="{1}">
        <grid 15="" key="{award.key}" item="" xs="{12}" sm="{6}" md="{4}" style="{{" borderRadius:="" }}="">
          <grid container="" spacing="{1}" direction="column">
            <grid item="" xs="{12}">
              <challengerewardcard>
                <grid container="" spacing="{1}" alignItems="flex-end">
                  <grid item="" xs="{12}">
                    <grid container="" direction="column" spacing="{2}">
                      <grid item="">
                        <grid container="" justify="space-between">
                          <grid item="">
                            <defaulttitle>
                              {challenge.typeCode === 'TG'
                                ? intl.formatMessage({
                                    id: 'challenge.condition.team_group_max_points_label',
                                  })
                                : challenge.typeCode === 'CT'
                                ? intl.formatMessage({
                                    id: 'challenge.condition.team_max_points_label',
                                  })
                                : intl.formatMessage({
                                    id: 'challenge.condition.collaborator_max_points_label',
                                  })}
                            </defaulttitle>
                          </grid>
                        </grid>
                      </grid>

                      <grid item="" xs="{12}">
                        <cardmedia image="{pointRewardImage}" style="{{" height:="" 100,="" width:="" margin:="" 'auto'="" }}=""></cardmedia>
                      </grid>
                      <grid item="">
                        <grid container="">
                          <grid item="">
                            <cardmedia image="{coinImage}" style="{{" height:="" 20,="" width:="" marginRight:="" 5,="" marginTop:="" -2,="" }}=""></cardmedia>
                          </grid>
                          <grid item="">
                            <defaulttext>
                              {intl
                                .formatMessage({
                                  id: 'challenge.condition.award_points',
                                })
                                .format(award.points.toLocaleString())}
                            </defaulttext>
                          </grid>
                        </grid>
                      </grid>
                    </grid>
                  </grid>
                </grid>
              </challengerewardcard>
            </grid>
          </grid>
        </grid>
      </grid>
    );
  };

  const renderRankingAwards = () => {
    return (
      <table backgroundDisabled="">
        <tablebody>
          <grid container="" spacing="{1}">
            {challenge.awards.map((award, awardIndex) => {
              const highlightAward =
                // filtered on participant
                (challenge.collaboratorId || challenge.teamId) &&
                // step is reached
                challenge.rank == award.rank;
              return (
                <react.fragment>
                  {challenge.rewardTypeCode === 'P' && (
                    <grid key="{award.key}" item="" xs="{12}" sm="{6}" md="{4}" style="{{" background:="" highlightAward="" ?="" '#00E58D'="" :="" '',="" borderRadius:="" 15,="" }}="">
                      <grid container="" spacing="{1}" direction="column">
                        <grid item="" xs="{12}">
                          <challengerewardcard>
                            <grid container="" spacing="{1}" alignItems="flex-end">
                              <grid item="" xs="{12}">
                                <grid container="" direction="column" spacing="{2}">
                                  <grid item="">
                                    <grid container="" justify="space-between">
                                      <grid item="">
                                        <defaulttitle>
                                          {challenge.typeCode === 'TG'
                                            ? intl
                                                .formatMessage({
                                                  id: 'challenge.condition.team_group_rank',
                                                })
                                                .format(award.rank)
                                            : challenge.typeCode === 'CT'
                                            ? intl
                                                .formatMessage({
                                                  id: 'challenge.condition.team_rank',
                                                })
                                                .format(award.rank)
                                            : intl
                                                .formatMessage({
                                                  id: 'challenge.condition.collaborator_rank',
                                                })
                                                .format(award.rank)}
                                        </defaulttitle>
                                      </grid>
                                    </grid>
                                  </grid>

                                  <grid item="" xs="{12}">
                                    <cardmedia image="{pointRewardImage}" style="{{" height:="" 100,="" width:="" margin:="" 'auto',="" }}=""></cardmedia>
                                  </grid>
                                  <grid item="">
                                    <grid container="">
                                      <grid item="">
                                        <cardmedia image="{coinImage}" style="{{" height:="" 20,="" width:="" marginRight:="" 5,="" marginTop:="" -2,="" }}=""></cardmedia>
                                      </grid>
                                      <grid item="">
                                        <defaulttext>
                                          {intl
                                            .formatMessage({
                                              id: 'challenge.condition.award_points',
                                            })
                                            .format(
                                              award.points.toLocaleString()
                                            )}
                                        </defaulttext>
                                      </grid>
                                    </grid>
                                  </grid>
                                </grid>
                              </grid>
                            </grid>
                          </challengerewardcard>
                        </grid>
                      </grid>
                    </grid>
                  )}
                  {challenge.rewardTypeCode === 'G' && (
                    <grid item="" xs="{12}" style="{{" cursor:="" 'pointer'="" }}="" onClick="{()" =="">
                        award.reward &&
                        setRewardDetail(Object.assign({}, award.reward))
                      }
                    >
                      <div>
                        <tablerow>
                          <tablecell 270="" style="{{" width:="" }}="">
                            <grid container="" direction="column" spacing="{1}">
                              <grid item="">
                                <grid container="" spacing="{1}">
                                  <grid item="">
                                    <tablechip label="{'">'} />
                                  </tablechip></grid>
                                  <grid item="">
                                    <defaulttitle>
                                      {challenge.typeCode === 'TG'
                                        ? intl
                                            .formatMessage({
                                              id: 'challenge.condition.team_group_rank',
                                            })
                                            .format(award.rank)
                                        : challenge.typeCode === 'CT'
                                        ? intl
                                            .formatMessage({
                                              id: 'challenge.condition.team_rank',
                                            })
                                            .format(award.rank)
                                        : intl
                                            .formatMessage({
                                              id: 'challenge.condition.collaborator_rank',
                                            })
                                            .format(award.rank)}
                                    </defaulttitle>
                                  </grid>
                                </grid>
                              </grid>
                              <grid 250="" item="" style="{{" maxWidth:="" }}="">
                                <challengereward reward="{award.reward}"></challengereward>
                              </grid>
                            </grid>
                          </tablecell>
                        </tablerow>
                      </div>
                    </grid>
                  )}
                </react.fragment>
              );
            })}
          </grid>
        </tablebody>
      </table>
    );
  };

  const renderStepAwards = () => {
    const awards = _.sortBy(challenge.awards, ['target']);
    return (
      <grid container="" spacing="{1}">
        {awards.map((award, awardIndex) => {
          const highlightAward =
            // filtered on participant
            (challenge.collaboratorId || challenge.teamId) &&
            // step is reached
            challenge.goalPoints >= award.target &&
            // next step is not reached
            (awardIndex >= awards.length - 1 ||
              challenge.goalPoints < awards[awardIndex + 1].target);
          return (
            <react.fragment>
              {challenge.rewardTypeCode === 'P' && (
                <grid key="{award.key}" item="" xs="{12}" sm="{6}" md="{4}" style="{{" background:="" highlightAward="" ?="" '#00E58D'="" :="" '',="" borderRadius:="" 15,="" }}="">
                  <grid container="" spacing="{1}" direction="column">
                    <grid item="" xs="{12}">
                      <challengerewardcard>
                        <grid container="" spacing="{1}" alignItems="flex-end">
                          <grid item="" xs="{12}">
                            <grid container="" direction="column" spacing="{2}">
                              <grid item="">
                                <grid container="" justify="space-between">
                                  <grid item="">
                                    <defaulttitle>
                                      {intl
                                        .formatMessage({
                                          id: 'challenge.award_list.step_point_label',
                                        })
                                        .format(award.rank)}
                                    </defaulttitle>
                                  </grid>
                                </grid>
                              </grid>

                              <grid item="">
                                <defaulttext>
                                  {intl.formatMessage({
                                    id: 'challenge.award_list.target_label',
                                  })}{' '}
                                  : {award.target.toLocaleString()}
                                </defaulttext>
                              </grid>
                              <grid item="" xs="{12}">
                                <cardmedia image="{pointRewardImage}" style="{{" height:="" 100,="" width:="" margin:="" 'auto',="" }}=""></cardmedia>
                              </grid>
                              <grid item="">
                                <grid container="">
                                  <grid item="">
                                    <cardmedia image="{coinImage}" style="{{" height:="" 20,="" width:="" marginRight:="" 5,="" marginTop:="" -2,="" }}=""></cardmedia>
                                  </grid>
                                  <grid item="">
                                    <defaulttext>
                                      {intl
                                        .formatMessage({
                                          id: 'challenge.condition.award_points',
                                        })
                                        .format(award.points.toLocaleString())}
                                    </defaulttext>
                                  </grid>
                                </grid>
                              </grid>
                            </grid>
                          </grid>
                        </grid>
                      </challengerewardcard>
                    </grid>
                  </grid>
                </grid>
              )}
              {challenge.rewardTypeCode === 'G' && (
                <grid item="" xs="{12}" style="{{" cursor:="" 'pointer',="" background:="" highlightAward="" ?="" '#00E58D'="" :="" '',="" borderRadius:="" 15,="" }}="" onClick="{()" =="">
                    award.reward &&
                    setRewardDetail(Object.assign({}, award.reward))
                  }
                >
                  <div>
                    <tablerow>
                      <tablecell 270="" style="{{" width:="" }}="">
                        <grid container="" direction="column" spacing="{1}">
                          <grid item="">
                            <grid container="" spacing="{1}">
                              <grid item="">
                                <tablechip label="{'">'} />
                              </tablechip></grid>
                              <grid item="">
                                <defaulttext>
                                  {intl
                                    .formatMessage({
                                      id: 'challenge.award_list.step_point_label',
                                    })
                                    .format(award.rank)}
                                </defaulttext>
                              </grid>
                            </grid>
                          </grid>
                          <grid item="">
                            <defaulttext>
                              {intl.formatMessage({
                                id: 'challenge.award_list.target_label',
                              })}{' '}
                              : {award.target.toLocaleString()}
                            </defaulttext>
                          </grid>
                          <grid 250="" item="" style="{{" maxWidth:="" }}="">
                            <challengereward reward="{award.reward}"></challengereward>
                          </grid>
                        </grid>
                      </tablecell>
                    </tablerow>
                  </div>
                </grid>
              )}
            </react.fragment>
          );
        })}
      </grid>
    );
  };

  const renderAwards = () => {
    return (
      <grid container="" spacing="{2}">
        {challenge.awards.map((award) => {
          return (
            <grid key="{award.key}" item="" xs="{12}" sm="{6}" md="{4}" style="{{" cursor:="" 'pointer'="" }}="" onClick="{()" =="">
                award.reward && setRewardDetail(Object.assign({}, award.reward))
              }
            >
              <grid container="" spacing="{1}" direction="column">
                <grid item="" xs="{12}">
                  <challengerewardcard>
                    <grid container="" spacing="{1}" alignItems="flex-end">
                      {challenge.rewardTypeCode === 'G' && (
                        <grid item="" xs="{12}">
                          <grid container="" direction="column" spacing="{2}">
                            <grid item="">
                              <grid container="" justify="space-between">
                                <grid item="">
                                  {challenge.awardCode === 'P' ? (
                                    <defaulttext>
                                      {intl
                                        .formatMessage({
                                          id: 'challenge.award_list.step_point_label',
                                        })
                                        .format(award.rank)}
                                    </defaulttext>
                                  ) : (
                                    <defaulttitle>
                                      {challenge.typeCode === 'TG'
                                        ? intl
                                            .formatMessage({
                                              id: 'challenge.condition.team_group_rank',
                                            })
                                            .format(award.rank)
                                        : challenge.typeCode === 'CT'
                                        ? intl
                                            .formatMessage({
                                              id: 'challenge.condition.team_rank',
                                            })
                                            .format(award.rank)
                                        : intl
                                            .formatMessage({
                                              id: 'challenge.condition.collaborator_rank',
                                            })
                                            .format(award.rank)}
                                    </defaulttitle>
                                  )}
                                </grid>
                              </grid>
                            </grid>

                            {challenge.awardCode === 'P' && (
                              <grid item="">
                                <defaulttext>
                                  {intl.formatMessage({
                                    id: 'challenge.award_list.target_label',
                                  })}{' '}
                                  : {award.target.toLocaleString()}
                                </defaulttext>
                              </grid>
                            )}
                            <grid item="" xs="{12}">
                              {award.reward && (
                                <challengereward reward="{award.reward}"></challengereward>
                              )}
                            </grid>
                          </grid>
                        </grid>
                      )}
                      {challenge.rewardTypeCode === 'P' && (
                        <react.fragment>
                          <grid item="" xs="">
                            <defaulttitle>
                              {challenge.typeCode === 'TG'
                                ? intl
                                    .formatMessage({
                                      id: 'challenge.condition.team_group_rank',
                                    })
                                    .format(award.rank)
                                : challenge.typeCode === 'CT'
                                ? intl
                                    .formatMessage({
                                      id: 'challenge.condition.team_rank',
                                    })
                                    .format(award.rank)
                                : intl
                                    .formatMessage({
                                      id: 'challenge.condition.collaborator_rank',
                                    })
                                    .format(award.rank)}
                            </defaulttitle>
                          </grid>
                        </react.fragment>
                      )}
                    </grid>
                  </challengerewardcard>
                </grid>
              </grid>
            </grid>
          );
        })}
      </grid>
    );
  };

  const closeKpiModal = () => {
    props.collaboratorDataListActions.getCollaboratorDataListClear();
    setCurrentGoal(null);
  };

  const openKpiModal = (goal) => {
    props.collaboratorDataListActions.getCollaboratorDataListClear();
    setCurrentGoal(goal);
  };

  const closeCollaboratorKpiModal = () => {
    props.collaboratorDataListActions.getCollaboratorDataListClear();
    setCurrentGoal(null);
  };

  const openCollaboratorKpiModal = (goal) => {
    props.collaboratorDataListActions.getCollaboratorDataListClear();
    setCurrentGoal(goal);
  };

  const goalTooltip =
    challenge.awardCode === 'C'
      ? intl.formatMessage({
          id: 'challenge.condition.race_condition_goal_info',
        })
      : intl.formatMessage({ id: 'challenge.condition.goal_info' });
  // const AwardWrapperComponent = challenge.awardCode === 'P' || challenge.awardCode === 'R' ? React.Fragment : Card
  const AwardWrapperComponent = React.Fragment;

  const participants_text = intl
    .formatMessage({ id: 'challenge.form.steps.participants' })
    .toLowerCase();
  const participant_text = intl
    .formatMessage({ id: 'challenge.form.steps.participant' })
    .toLowerCase();
  const first_text = intl.formatMessage({ id: 'challenge.form.first' });
  const first_teams = intl.formatMessage({ id: 'challenge.form.first_teams' });
  const first_departments = intl.formatMessage({
    id: 'challenge.form.first_departments',
  });
  const department_text = intl
    .formatMessage({ id: 'common.team_group' })
    .toLowerCase();
  const team_text = intl.formatMessage({ id: 'common.team' }).toLowerCase();
  const departments_text = intl
    .formatMessage({ id: 'common.team_groups' })
    .toLowerCase();
  const teams_text = intl.formatMessage({ id: 'common.teams' }).toLowerCase();
  const the_first_department = intl.formatMessage({
    id: 'challenge.form.the_first_department',
  });
  const the_first_team = intl.formatMessage({
    id: 'challenge.form.the_first_team',
  });
  const the_first = intl.formatMessage({ id: 'challenge.form.the_first' });

  const beginningOfLastMonth = new Date();
  beginningOfLastMonth.setMonth(beginningOfLastMonth.getMonth() - 1);
  beginningOfLastMonth.setDate(1);
  return (
    <div>
      <grid container="" spacing="{4}" style="{{" position:="" 'relative'="" }}="">
        {/*<grid item="" xs="{12}" container="" spacing="{1}">*/}
        {/*  <grid item="" xs="{12}">*/}
        {/*    <defaulttitle>*/}
        {/*      {intl.formatMessage({ id: "challenge.condition.condition_area" })}*/}
        {/*    </defaulttitle>*/}
        {/*  </grid>*/}
        {/*  {lastUpdate && (*/}
        {/*    <grid 0="" item="" xs="{12}" style="{{" paddingTop:="" }}="">*/}
        {/*      <defaulttext lowercase="" style="{{" fontSize:="" 12,="" opacity:="" 0.8="" }}="">*/}
        {/*        {intl*/}
        {/*          .formatMessage({ id: "challenge.condition.last_update" })*/}
        {/*          .format(lastUpdate.toLocaleDateString(), lastUpdateTime)}*/}
        {/*        <span style="{{" fontWeight:="" "bold"="" }}="">*/}
        {/*          {intl*/}
        {/*            .formatMessage({*/}
        {/*              id: "challenge.condition.last_update_time",*/}
        {/*            })*/}
        {/*            .format(lastUpdate.toLocaleDateString(), lastUpdateTime)}*/}
        {/*        </span>*/}
        {/*      </defaulttext>*/}
        {/*    </grid>*/}
        {/*  )}*/}
        {/*  <grid item="" xs="{12}">*/}
        {/*    <card marginDisabled="">*/}
        {/*      <grid container="" spacing="{2}" alignItems="{&quot;flex-end&quot;}">*/}
        {/*        <grid item="" xs="{12}" sm="">*/}
        {/*          <table backgroundDisabled="">*/}
        {/*            <tablebody>*/}
        {/*              {goals.map((goal) => {*/}
        {/*                const progression = Math.round(*/}
        {/*                  (goal.counter / goal.target) * 100*/}
        {/*                );*/}
        {/*                return (*/}
        {/*                  <tablerow>*/}
        {/*                    <tablecell>*/}
        {/*                      <grid container="" spacing="{2}">*/}
        {/*                        <grid item="">*/}
        {/*                          <tablechip label="{goal.number}"></tablechip>*/}
        {/*                        </grid>*/}
        {/*                        <grid item="" xs="">*/}
        {/*                          <div>*/}
        {/*                            <grid container="" spacing="{1}">*/}
        {/*                              <grid item="" xs="{12}">*/}
        {/*                                <div>*/}
        {/*                                  <grid container="" spacing="{1}">*/}
        {/*                                    <grid item="" xs="" zeroMinWidth="">*/}
        {/*                                      <defaulttext* }="" {="" *="" lowercase*="" style="{{*/}" fontSize:="" 16,*="" fontWeight:="" "bold",*="" }}*="">*/}
        {/*                                        <grid* }="" {="" *="" container*="" spacing="{1}*/}" alignItems="center">*/}
        {/*                                          <grid item="">*/}
        {/*                                            {goal.name}&nbsp;*/}
        {/*                                            <tooltip* }="" {="" *="" title="{goalTooltip}*/}" placement="{&quot;right&quot;}*/}">*/}
        {/*                                              <bluetext* }="" {="" *="" style="{{*/}" width:="" "fit-content",*="" }}*="" component="{&quot;span&quot;}*/}">*/}
        {/*                                                <fontawesomeicon* }="" {="" *="" icon="{faInfoCircle}*/}"></fontawesomeicon*>*/}
        {/*                                              */}
        {/*                                            */}
        {/*                                          </bluetext*></tooltip*></grid>*/}

        {/*                                          {((goal.kpiCustom.toBoolean() &&*/}
        {/*                                            canEdit) ||*/}
        {/*                                            (goal.kpiCustom.toBoolean() &&*/}
        {/*                                              !canEdit &&*/}
        {/*                                              goal.kpiCollaboratorEditable.toBoolean() &&*/}
        {/*                                              challenge.end*/}
        {/*                                                .toDate2()*/}
        {/*                                                .getTime() >*/}
        {/*                                                beginningOfLastMonth.getTime())) && (*/}
        {/*                                            <grid* }="" {="" *="" item*="" onClick="{()" =="">*/}
        {/*                                                openKpiModal(goal)*/}
        {/*                                              }*/}
        {/*                                              className={*/}
        {/*                                                props.classes.link*/}
        {/*                                              }*/}
        {/*                                            >*/}
        {/*                                              <fontawesomeicon* }="" {="" *="" icon="{faPencil}*/}" style="{{*/}" marginRight:="" 5,*="" }}*=""></fontawesomeicon*>*/}
        {/*                                              {intl.formatMessage({*/}
        {/*                                                id: "challenge.kpi_results.edit_results",*/}
        {/*                                              })}*/}
        {/*                                            </grid*></grid*></defaulttext*></grid>*/}
        {/*                                          )}*/}
        {/*                                        </grid>*/}
        {/*                                      */}
        {/*                                    </div></grid>*/}
        {/*                                  </grid>*/}
        {/*                                </div>*/}
        {/*                              </grid>*/}
        {/*                              <grid>*/}
        {/*                                <infotext* }="" {="" *="" lowercase*="" style="{{*/}" marginTop:="" -5,*="" marginLeft:="" 4,*="" }}*="">*/}
        {/*                                  {goal.description}*/}
        {/*                                */}
        {/*                              </infotext*></grid>*/}
        {/*                              {challenge.awardCode === "C" && (*/}
        {/*                                <grid* }="" {="" *="" item*="" xs="{12}*/}" style="{{*/}" marginTop:="" 10,*="" marginBottom:="" 15,*="" maxWidth:="" 400,*="" }}*="">*/}
        {/*                                  <grid container="">*/}
        {/*                                    <grid item="">*/}
        {/*                                      <defaulttext* 13="" }="" {="" *="" lowercase*="" style="{{" fontSize:="" }}*="">*/}
        {/*                                        {intl*/}
        {/*                                          .formatMessage({*/}
        {/*                                            id: "admin.goal.thumbnail.counter_text",*/}
        {/*                                          })*/}
        {/*                                          .format(*/}
        {/*                                            goal.counter.toLocaleString()*/}
        {/*                                          )}{" "}*/}
        {/*                                        <infotext* 13="" }="" {="" *="" lowercase*="" style="{{" fontSize:="" }}*="" component="span">*/}
        {/*                                          {intl*/}
        {/*                                            .formatMessage({*/}
        {/*                                              id: "admin.goal.thumbnail.target_text",*/}
        {/*                                            })*/}
        {/*                                            .format(*/}
        {/*                                              goal.target.toLocaleString()*/}
        {/*                                            )}*/}
        {/*                                        */}
        {/*                                      */}
        {/*                                    </infotext*></defaulttext*></grid>*/}
        {/*                                    <grid item="" xs="">*/}
        {/*                                      <accenttext align="right">*/}
        {/*                                        {"{0}%".format(progression)}*/}
        {/*                                      </accenttext>*/}
        {/*                                    </grid>*/}
        {/*                                  </grid>*/}
        {/*                                  <grid container="">*/}
        {/*                                    <grid item="" xs="">*/}
        {/*                                      <progressbar* }="" {="" *="" value="{progression}*/}"></progressbar*>*/}
        {/*                                    </grid>*/}
        {/*                                  </grid>*/}
        {/*                                </grid*></grid>*/}
        {/*                              )}*/}
        {/*                              {challenge.awardCode !== "C" && (*/}
        {/*                                <grid item="" xs="{12}">*/}
        {/*                                  <div>*/}
        {/*                                    <table>*/}
        {/*                                      <tbody>*/}
        {/*                                        <tr>*/}
        {/*                                          <td 0="" style="{{" padding:="" }}="">*/}
        {/*                                            <defaulttext* }="" {="" *="" lowercase*="" style="{{*/}" textAlign:="" "right",*="" paddingRight:="" 5,*="" fontSize:="" 13,*="" }}*="">*/}
        {/*                                              {intl.formatMessage({*/}
        {/*                                                id: "challenge.condition.label",*/}
        {/*                                              })}{" "}*/}
        {/*                                              :*/}
        {/*                                            */}
        {/*                                          </defaulttext*></td>*/}
        {/*                                          <td>*/}
        {/*                                            <grid* }="" {="" *="" container*="" spacing="{1}*/}" style="{{" marginTop:="" -2="" }}*="">*/}
        {/*                                              <grid item="">*/}
        {/*                                                <defaulttext lowercase="">*/}
        {/*                                                  {"individualTarget" in*/}
        {/*                                                  goal*/}
        {/*                                                    ? goal.individualTarget.toLocaleString()*/}
        {/*                                                    : goal.target.toLocaleString()}*/}
        {/*                                                </defaulttext>*/}
        {/*                                              </grid>*/}

        {/*                                              <react.fragment>*/}
        {/*                                                <grid item="">*/}
        {/*                                                  <defaulttext* }="" {="" *="" lowercase*="">*/}
        {/*                                                    <fontawesomeicon* }="" {="" *="" icon="{faEquals}*/}"></fontawesomeicon*>*/}
        {/*                                                  */}
        {/*                                                </defaulttext*></grid>*/}
        {/*                                                <grid item="">*/}
        {/*                                                  <bluetag>*/}
        {/*                                                    {intl*/}
        {/*                                                      .formatMessage({*/}
        {/*                                                        id: "challenge.condition.point_target",*/}
        {/*                                                      })*/}
        {/*                                                      .format(*/}
        {/*                                                        goal.targetPoints.toLocaleString()*/}
        {/*                                                      )}*/}
        {/*                                                  </bluetag>*/}
        {/*                                                </grid>*/}
        {/*                                              </react.fragment>*/}
        {/*                                            </grid*></td></tr></tbody></table></div></grid>*/}
        {/*                                          */}
        {/*                                        */}
        {/*                                        <tr>*/}
        {/*                                          <td 0="" style="{{" padding:="" }}="">*/}
        {/*                                            <defaulttext* }="" {="" *="" lowercase*="" style="{{*/}" textAlign:="" "right",*="" paddingRight:="" 5,*="" fontSize:="" 13,*="" }}*="">*/}
        {/*                                              👉{" "}*/}
        {/*                                              {intl.formatMessage({*/}
        {/*                                                id: "challenge.condition.label_completed",*/}
        {/*                                              })}{" "}*/}
        {/*                                              :*/}
        {/*                                            */}
        {/*                                          </defaulttext*></td>*/}
        {/*                                          <td>*/}
        {/*                                            <grid* }="" {="" *="" container*="" spacing="{1}*/}" style="{{" marginTop:="" "0"="" }}*="">*/}
        {/*                                              <grid* }="" {="" *="" item*="" style="{{*/}" marginTop:="" -1,*="" }}*="">*/}
        {/*                                                <accenttext>*/}
        {/*                                                  {goal.counter.toLocaleString()}*/}
        {/*                                                </accenttext>*/}
        {/*                                              </grid*></grid*></td></tr></tablecell></tablerow></tablebody></table></grid>*/}

        {/*                                              <react.fragment>*/}
        {/*                                                <grid* }="" {="" *="" item*="" style="{{*/}" marginTop:="" 0,*="" }}*="">*/}
        {/*                                                  <accenttext>*/}
        {/*                                                    <fontawesomeicon* }="" {="" *="" icon="{*/}" faAngleRight*="" }*=""></fontawesomeicon*>*/}
        {/*                                                  </accenttext>*/}
        {/*                                                </grid*></react.fragment></grid>*/}
        {/*                                                <grid* }="" {="" *="" item*="" style="{{*/}" marginTop:="" -2,*="" }}*="">*/}
        {/*                                                  <accenttag>*/}
        {/*                                                    {intl*/}
        {/*                                                      .formatMessage({*/}
        {/*                                                        id: "challenge.condition.point_counter",*/}
        {/*                                                      })*/}
        {/*                                                      .format(*/}
        {/*                                                        goal.points.toLocaleString()*/}
        {/*                                                      )}*/}
        {/*                                                  </accenttag>*/}
        {/*                                                </grid*></card></grid>*/}
        {/*                                              */}
        {/*                                            </grid>*/}
        {/*                                          */}
        {/*                                        */}
        {/*                                      */}
        {/*                                    */}
        {/*                                  </grid></div>*/}
        {/*                                */}
        {/*                              )}*/}
        {/*                            */}
        {/*                          