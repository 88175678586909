import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Formsy from 'formsy-react';
import {
  Card,
  DefaultTitle,
  Loader,
  ProgressButton,
  Select,
  TextField,
} from '../../../../../../components';
import * as challengeTypeListActions from '../../../../../../services/ChallengeTypes/ChallengeTypeList/actions';
import * as challengeTypeListUpdateActions from '../../../../../../services/ChallengeTypes/ChallengeTypeListUpdate/actions';
import * as configListActions from '../../../../../../services/Configs/ConfigList/actions';
import * as configListUpdateActions from '../../../../../../services/Configs/ConfigListUpdate/actions';
import './helpers/FormsyHelper';
import * as Resources from '../../../../../../Resources';
import { injectIntl } from 'react-intl';
import { toast } from 'react-toastify';

const styles = {
  title: {
    marginBottom: 16,
  },
  form: {
    marginBottom: 32,
  },
  arrow: {
    marginTop: 24,
  },
};

const rules = [
  { id: 'P', name: '1€ = 10 PTS' },
  { id: 'L', name: 'Libre' },
];

class PlayerPointConfig extends Component {
  constructor(props) {
    super(props);
    this.initialized = false;
    this.state = {
      ruleId: null,
      rule: '',
      global: {
        id: null,
        points: { value: 0, display: true },
        budget: { value: 0, display: true },
      },
      goals: {
        id: null,
        points: { value: 0, display: true },
        percentage: { value: 0, display: true },
      },
      badges: {
        id: null,
        points: { value: 0, display: true },
        percentage: { value: 0, display: true },
      },
    };

    this.props.configListUpdateActions.clearConfigListUpdate();
  }

  componentDidMount(props) {
    const periodId = this.props.period;

    this.props.configListActions.getConfigList(periodId);
  }

  componentWillReceiveProps(props) {
    const { configs } = props.configList;
    if (!this.initialized && configs) {
      const rule = configs.find((x) => x.code == 'CCRP');
      const global = configs.find((x) => x.code == 'CPA');
      const goals = configs.find((x) => x.code == 'CPG');
      const badges = configs.find((x) => x.code == 'CPB');

      this.initialized = true;
      this.setState({
        ...this.state,
        ruleId: rule.id,
        rule: rule.value,
        global: {
          id: global.id,
          points: { value: global.value, display: true },
          budget: { value: global.value / 10, display: true },
        },
        goals: {
          id: goals.id,
          points: { value: goals.value, display: true },
          percentage: {
            value:
              global.value > 0
                ? Number(((goals.value / global.value) * 100).toFixed(2))
                : 0,
            display: true,
          },
        },
        badges: {
          id: badges.id,
          points: { value: badges.value, display: true },
          percentage: {
            value:
              global.value > 0
                ? Number(((badges.value / global.value) * 100).toFixed(2))
                : 0,
            display: true,
          },
        },
      });
    }
  }

  handleRuleChange(value) {
    this.setState({
      ...this.state,
      rule: value,
    });
  }

  handleBudgetChange(value) {
    const points = value * 10;
    var global = this.state.global;
    var goals = this.state.goals;
    var badges = this.state.badges;

    global.budget.value = value;
    global.points.value = points;
    goals.points.value = Math.round(points * (goals.percentage.value / 100));
    badges.points.value = Math.round(points * (badges.percentage.value / 100));

    global.points.display = false;
    goals.points.display = false;
    badges.points.display = false;

    this.setState(
      {
        ...this.state,
        global: global,
        goals: goals,
        badges: badges,
      },
      () => {
        global.points.display = true;
        goals.points.display = true;
        badges.points.display = true;

        this.setState({
          ...this.state,
          global: global,
          goals: goals,
          badges: badges,
        });
      }
    );
  }

  handleGlobalPointsChange(value) {
    const budget = (value / 10).toFixed(2);
    var global = this.state.global;
    var goals = this.state.goals;
    var badges = this.state.badges;

    global.points.value = value;
    global.budget.value = budget;
    goals.points.value = Math.round(value * (goals.percentage.value / 100));
    badges.points.value = Math.round(value * (badges.percentage.value / 100));

    global.budget.display = false;
    goals.points.display = false;
    badges.points.display = false;

    this.setState(
      {
        ...this.state,
        global: global,
        goals: goals,
        badges: badges,
      },
      () => {
        global.budget.display = true;
        goals.points.display = true;
        badges.points.display = true;

        this.setState({
          ...this.state,
          global: global,
          goals: goals,
          badges: badges,
        });
      }
    );
  }

  handlePercentageChange = (name) => (value) => {
    const points = Math.round(this.state.global.points.value * (value / 100));
    var config = this.state[name];
    config.percentage.value = value;
    config.points.value = points;
    config.points.display = false;
    this.setState(
      {
        ...this.state,
        [name]: config,
      },
      () => {
        config.points.display = true;
        this.setState({
          ...this.state,
          [name]: config,
        });
      }
    );
  };

  handlePointsChange = (name) => (value) => {
    const percentage = ((value / this.state.global.points.value) * 100).toFixed(
      2
    );
    var config = this.state[name];
    config.points.value = value;
    config.percentage.value = percentage;
    config.percentage.display = false;
    this.setState(
      {
        ...this.state,
        [name]: config,
      },
      () => {
        config.percentage.display = true;
        this.setState({
          ...this.state,
          [name]: config,
        });
      }
    );
  };

  handleSubmit(model) {
    const configs = [
      { id: this.state.ruleId, value: model.rule },
      { id: this.state.global.id, value: model.global },
      { id: this.state.goals.id, value: model.goals },
      { id: this.state.badges.id, value: model.badges },
    ];

    this.props.configListUpdateActions.updateConfigList(configs);
  }

  renderLoader() {
    return <loader centered=""></loader>;
  }

  renderData() {
    const { intl } = this.props;
    const { classes } = this.props;

    const { loading: configListUpdateLoading } = this.props.configListUpdate;
    const loading = configListUpdateLoading;

    return (
      <div>
        <formsy onValidSubmit="{this.handleSubmit.bind(this)}">
          <grid container="" spacing="{4}">
            <grid item="" xs="{12}" container="" spacing="{1}">
              <grid item="" xs="{12}">
                <defaulttitle isContrast="">
                  {intl.formatMessage({ id: 'admin.point_config.total_title' })}
                </defaulttitle>
              </grid>
              <grid item="" xs="{12}">
                <card>
                  <grid container="" spacing="{2}">
                    <grid item="" xs="{4}">
                      <select name="rule" label="{intl.formatMessage({" id:="" 'admin.point_config.point_rule',="" })}="" fullWidth="" options="{rules}" optionValueName="id" optionTextName="name" initial="{this.state.rule}" onChange="{this.handleRuleChange.bind(this)}"></select>
                    </grid>
                    {this.state.rule == 'P' && (
                      <grid item="">
                        {this.state.global.budget.display && (
                          <textfield name="globalPercentage" type="number" label="{intl.formatMessage({" id:="" 'admin.point_config.budget',="" })}="" initial="{this.state.global.budget.value}" onChange="{this.handleBudgetChange.bind(this)}"></textfield>
                        )}
                      </grid>
                    )}
                    {this.state.rule == 'P' && (
                      <grid item="">
                        <fontawesomeicon icon="{faAngleRight}" className="{classes.arrow}"></fontawesomeicon>
                      </grid>
                    )}
                    <grid item="">
                      {this.state.global.points.display && (
                        <textfield name="global" type="number" label="PTS" initial="{this.state.global.points.value}" onChange="{this.handleGlobalPointsChange.bind(this)}" required="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" id:="" 'common.form.required_error',="" }),="" }}=""></textfield>
                      )}
                    </grid>
                  </grid>
                </card>
              </grid>
            </grid>
            <grid item="" xs="{6}" container="" spacing="{1}">
              <grid item="" xs="{12}">
                <defaulttitle isContrast="">
                  {intl.formatMessage({ id: 'admin.goal.title' })}
                </defaulttitle>
              </grid>
              <grid item="" xs="{12}">
                <card>
                  <grid container="" spacing="{2}">
                    <grid item="">
                      {this.state.goals.percentage.display && (
                        <textfield name="hello" type="number" label="%" initial="{this.state.goals.percentage.value}" onChange="{this.handlePercentageChange('goals').bind(" this="" )}=""></textfield>
                      )}
                    </grid>
                    <grid item="">
                      <fontawesomeicon icon="{faAngleRight}" className="{classes.arrow}"></fontawesomeicon>
                    </grid>
                    <grid item="">
                      {this.state.goals.points.display && (
                        <textfield name="goals" type="number" label="Pts" initial="{this.state.goals.points.value}" onChange="{this.handlePointsChange('goals').bind(this)}" required="" validations="isCollaboratorPointsValid" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" id:="" 'common.form.required_error',="" }),="" isCollaboratorPointsValid:="" 'common.form.collaborator_points_valid',="" }}=""></textfield>
                      )}
                    </grid>
                  </grid>
                </card>
              </grid>
            </grid>
            <grid item="" xs="{6}" container="" spacing="{1}">
              <grid item="" xs="{12}">
                <defaulttitle isContrast="">
                  {intl.formatMessage({ id: 'badge.title' })}
                </defaulttitle>
              </grid>
              <grid item="" xs="{12}">
                <card>
                  <grid container="" spacing="{2}">
                    <grid item="">
                      {this.state.badges.percentage.display && (
                        <textfield name="hello" type="number" label="%" initial="{this.state.badges.percentage.value}" onChange="{this.handlePercentageChange('badges').bind(" this="" )}=""></textfield>
                      )}
                    </grid>
                    <grid item="">
                      <fontawesomeicon icon="{faAngleRight}" className="{classes.arrow}"></fontawesomeicon>
                    </grid>
                    <grid item="">
                      {this.state.badges.points.display && (
                        <textfield name="badges" type="number" label="Pts" initial="{this.state.badges.points.value}" onChange="{this.handlePointsChange('badges').bind(" this="" )}="" required="" validations="isCollaboratorPointsValid" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" id:="" 'common.form.required_error',="" }),="" isCollaboratorPointsValid:="" 'common.form.collaborator_points_valid',="" }}=""></textfield>
                      )}
                    </grid>
                  </grid>
                </card>
              </grid>
            </grid>

            <grid item="" xs="{12}">
              <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" centered="" loading="{loading}"></progressbutton>
            </grid>
          </grid>
        </formsy>
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    const { configs, loading: configListLoading } = this.props.configList;
    const { success: configListUpdateSuccess } = this.props.configListUpdate;
    const loading = configListLoading;
    const success = configListUpdateSuccess;

    if (success) {
      this.props.challengeTypeListUpdateActions.clearChallengeTypeListUpdate();
      this.props.configListUpdateActions.clearConfigListUpdate();
      toast.success(
        intl.formatMessage({ id: 'common.update_success_message' })
      );
    }

    return (
      <div>
        {loading && this.renderLoader()}
        {!loading && configs && this.renderData()}
      </div>
    );
  }
}

const mapStateToProps = ({
  challengeTypeList,
  challengeTypeListUpdate,
  configList,
  configListUpdate,
}) => ({
  challengeTypeList,
  challengeTypeListUpdate,
  configList,
  configListUpdate,
});

const mapDispatchToProps = (dispatch) => ({
  challengeTypeListActions: bindActionCreators(
    challengeTypeListActions,
    dispatch
  ),
  challengeTypeListUpdateActions: bindActionCreators(
    challengeTypeListUpdateActions,
    dispatch
  ),
  configListActions: bindActionCreators(configListActions, dispatch),
  configListUpdateActions: bindActionCreators(
    configListUpdateActions,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(PlayerPointConfig)));
