import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import {
  AdminRoutes,
  AnonymousRoutes,
  FileRoutes,
  PartnerRoutes,
  MaintenanceRoutes,
  MainRoutes,
  ManagerRoutes,
  SuperManagerRoutes,
} from './components';
import * as scenes from './scenes';
import IntermediateRoutes from './components/Common/components/IntermadiateRoutes/IntermadiateRoutes';

export default () => {
  return (
    <browserrouter>
      <switch>
        {/* Main routes */}
        <mainroutes exact="" path="/" component="{scenes.Main}"></mainroutes>
        <mainroutes exact="" path="/home" component="{scenes.Home}"></mainroutes>
        {/*<maintenanceroutes component="{scenes.Maintenance}"></maintenanceroutes>*/}
        {/* Account */}
        <mainroutes exact="" path="/account" component="{scenes.AccountDetail}"></mainroutes>
        {/* Collaboratos */}
        <mainroutes exact="" path="/collaborators/:id/detail" component="{scenes.CollaboratorDetail}"></mainroutes>
        <mainroutes exact="" path="/collaborators/jti/:id/detail" component="{scenes.CollaboratorDetailJti}"></mainroutes>
        <managerroutes exact="" path="/collaborators/:id/edit" component="{scenes.CollaboratorPassword}"></managerroutes>

        {/* Stats */}
        <mainroutes exact="" path="/stats" component="{scenes.HomeStats}"></mainroutes>
        <mainroutes exact="" path="/stats/collaborators/:collaboratorId/categories" component="{scenes.CollaboratorGoalCategoryStats}"></mainroutes>
        <mainroutes exact="" path="/stats/goals" component="{scenes.GoalStats}"></mainroutes>
        <mainroutes exact="" path="/stats/teams/:teamId/categories" component="{scenes.TeamCollaboratorGoalCategoryStats}"></mainroutes>
        {/* Goals */}
        <mainroutes exact="" path="/goals" component="{scenes.GoalListHome}"></mainroutes>
        <mainroutes exact="" path="/goals/collaborators/:id/list" component="{scenes.CollaboratorGoalList}"></mainroutes>
        <mainroutes exact="" path="/goals/collaborators/:id/categories" component="{scenes.CollaboratorGoalCategoryList}"></mainroutes>
        <mainroutes exact="" path="/goals/teams/:id/list" component="{scenes.TeamGoalList}"></mainroutes>
        <mainroutes exact="" path="/goals/teams/:id/categories" component="{scenes.TeamGoalCategoryList}"></mainroutes>
        <mainroutes exact="" path="/goals/detail/collaborator/:id" component="{scenes.CollaboratorGoalDetail}"></mainroutes>
        <mainroutes exact="" path="/goals/detail/team/:id" component="{scenes.TeamGoalDetail}"></mainroutes>
        <mainroutes exact="" path="/goals/detail/team-collaborator/:id" component="{scenes.TeamCollaboratorGoalDetail}"></mainroutes>
        {/* Challenges */}
        <mainroutes exact="" path="/challenges" component="{scenes.ChallengeHome}"></mainroutes>
        <mainroutes exact="" path="/challenges/creation" component="{scenes.ChallengeCreation}"></mainroutes>
        <mainroutes exact="" path="/challenges/team/:id/creation" component="{scenes.ChallengeCreation}"></mainroutes>
        <mainroutes exact="" path="/challenges/modification/:id" component="{scenes.ChallengeUpdate}"></mainroutes>
        <mainroutes exact="" path="/challenges/duplication/:id" component="{scenes.ChallengeDuplication}"></mainroutes>
        <mainroutes exact="" path="/challenges/collaborator/:id" component="{scenes.CollaboratorChallengeList}"></mainroutes>
        <mainroutes exact="" path="/challenges/team/:id" component="{scenes.TeamChallengeList}"></mainroutes>
        <mainroutes exact="" path="/challenges/department/:id" component="{scenes.TeamGroupChallengeList}"></mainroutes>
        <mainroutes exact="" path="/challenges/detail/collaborator/:id" component="{scenes.CollaboratorChallengeDetail}"></mainroutes>
        <mainroutes exact="" path="/challenges/detail/team/:id" component="{scenes.TeamChallengeDetail}"></mainroutes>
        <mainroutes exact="" path="/challenges/detail/team-collaborator/:id" component="{scenes.TeamCollaboratorChallengeDetail}"></mainroutes>

        <mainroutes exact="" path="/challenges/detail/team-group-collaborator/:id" component="{scenes.TeamGroupCollaboratorChallengeDetail}"></mainroutes>
        <mainroutes exact="" path="/challenges/detail/team-group/:id" component="{scenes.TeamGroupChallengeDetail}"></mainroutes>
        <mainroutes exact="" path="/challenges/detail/team-group-based/:id" component="{scenes.TeamGroupBasedChallengeDetail}"></mainroutes>
        {/* Badges */}
        <mainroutes exact="" path="/badges" component="{scenes.BadgeHome}"></mainroutes>
        <mainroutes exact="" path="/badges/:id" component="{scenes.BadgeTeamDetail}"></mainroutes>
        <mainroutes exact="" path="/badges/collaborator/:id" component="{scenes.BadgeList}"></mainroutes>
        <mainroutes exact="" path="/badges/detail/:id" component="{scenes.BadgeDetail}"></mainroutes>
        {/* Coaching lists */}
        <mainroutes exact="" path="/coaching" component="{scenes.CoachingHome}"></mainroutes>
        <mainroutes exact="" path="/coaching/team/:id" component="{scenes.CoachingTeamDetail}"></mainroutes>
        <mainroutes exact="" path="/coaching/:id" component="{scenes.CoachingList}"></mainroutes>
        {/* Game rules */}
        <mainroutes exact="" path="/rules" component="{scenes.GameRules}"></mainroutes>
        {/* Partners */}
        <partnerroutes exact="" path="/aircall/oauth/install" component="{scenes.AircallInstall}"></partnerroutes>
        {/* NewsFeed */}
        <mainroutes exact="" path="/newsfeed" component="{scenes.NewsFeed}"></mainroutes>
        {/* Rankings */}
        <mainroutes exact="" path="/rankings" component="{scenes.RankingList}"></mainroutes>
        <mainroutes exact="" path="/rankings/team/:id" component="{scenes.RankingsTeamDetail}"></mainroutes>
        <mainroutes exact="" path="/rankings/collaborators/:collaborator/list" component="{scenes.CollaboratorRankingList}"></mainroutes>
        <mainroutes exact="" path="/rankings/collaborators/:collaborator/general/:period" component="{scenes.CollaboratorGeneralRanking}"></mainroutes>
        <mainroutes exact="" path="/rankings/collaborators/:collaborator/categories/:category/years/:period" component="{scenes.CollaboratorCategoryRanking}"></mainroutes>
        <mainroutes exact="" path="/rankings/collaborators/:collaborator/challenges/:period" component="{scenes.CollaboratorChallengeRanking}"></mainroutes>
        <mainroutes exact="" path="/rankings/teams/:team/general/:period" component="{scenes.TeamGeneralRanking}"></mainroutes>
        <mainroutes exact="" path="/rankings/teams/:team/categories/:category/years/:period" component="{scenes.TeamCategoryRanking}"></mainroutes>
        <mainroutes exact="" path="/rankings/teams/:team/challenges/:period" component="{scenes.TeamChallengeRanking}"></mainroutes>
        {/* App tour */}
        <mainroutes exact="" path="/apptour" component="{scenes.AppTour}"></mainroutes>
        {/* JTI Game */}
        <mainroutes exact="" path="/game" component="{scenes.Game}"></mainroutes>
        {/* Rewards */}
        <mainroutes exact="" path="/rewards" component="{scenes.RewardHome}"></mainroutes>
        <adminroutes exact="" path="/rewards/creation" component="{scenes.RewardCreation}"></adminroutes>
        <mainroutes exact="" path="/rewards/collaborators/:id" component="{scenes.CollaboratorRewardStore}"></mainroutes>
        <adminroutes exact="" path="/rewards/collaborator-orders/:id/summary" component="{scenes.CollaboratorRewardOrderSummary}"></adminroutes>
        <adminroutes exact="" path="/rewards/collaborator-orders/:id/validation" component="{scenes.CollaboratorRewardOrderValidation}"></adminroutes>
        <mainroutes exact="" path="/rewards/detail/:id" component="{scenes.RewardDetail}"></mainroutes>
        <adminroutes exact="" path="/rewards/duplication/:id" component="{scenes.RewardDuplication}"></adminroutes>
        <supermanagerroutes exact="" path="/rewards/management" component="{scenes.RewardManagement}"></supermanagerroutes>
        <adminroutes exact="" path="/rewards/modification/:id" component="{scenes.RewardUpdate}"></adminroutes>
        <mainroutes exact="" path="/rewards/shopping-cart" component="{scenes.ShoppingCart}"></mainroutes>
        <managerroutes exact="" path="/rewards/teams/:id" component="{scenes.TeamRewardStore}"></managerroutes>
        <adminroutes exact="" path="/rewards/team-orders/:id/summary" component="{scenes.TeamRewardOrderSummary}"></adminroutes>
        <adminroutes exact="" path="/rewards/team-orders/:id/validation" component="{scenes.TeamRewardOrderValidation}"></adminroutes>
        <adminroutes exact="" path="/rewards/tracking/collaborators" component="{scenes.CollaboratorRewardOrderTracking}"></adminroutes>
        <adminroutes exact="" path="/rewards/tracking/teams" component="{scenes.TeamRewardOrderTracking}"></adminroutes>
        {/* Teams */}
        <mainroutes exact="" path="/teams" component="{scenes.TeamList}"></mainroutes>
        <mainroutes exact="" path="/teams/:id" component="{scenes.TeamDetail}"></mainroutes>
        <mainroutes exact="" path="/teams/:teamId/collaborators/:id/detail" component="{scenes.CollaboratorDetail}"></mainroutes>
        <managerroutes exact="" path="/teams/:teamId/collaborators/creation" component="{scenes.CollaboratorCreation}"></managerroutes>
        {/* Admin routes */}
        <adminroutes exact="" path="/admin" component="{scenes.AdminHome}"></adminroutes>
        <adminroutes exact="" path="/admin/periods/:periodId/badges" component="{scenes.AdminBadgeList}"></adminroutes>
        <adminroutes exact="" path="/admin/periods/:periodId/badges/creation" component="{scenes.AdminBadgeCreation}"></adminroutes>
        <adminroutes exact="" path="/admin/periods/:periodId/badges/:id" component="{scenes.AdminBadgeDetail}"></adminroutes>
        <adminroutes exact="" path="/admin/categories" component="{scenes.AdminCategoryList}"></adminroutes>
        <adminroutes exact="" path="/admin/categories/creation" component="{scenes.AdminCategoryCreation}"></adminroutes>
        <adminroutes exact="" path="/admin/categories/modification/:id" component="{scenes.AdminCategoryUpdate}"></adminroutes>
        <adminroutes exact="" path="/admin/partners" component="{scenes.AdminPartnerList}"></adminroutes>
        <adminroutes exact="" path="/admin/toucan" component="{scenes.AdminToucan}"></adminroutes>
        <adminroutes exact="" path="/admin/partners/:id" component="{scenes.AdminPartnerDetail}"></adminroutes>
        <adminroutes exact="" path="/admin/periods/:periodId/challenges" component="{scenes.AdminChallenge}"></adminroutes>
        <adminroutes exact="" path="/admin/periods/:periodId/goals" component="{scenes.AdminGoalList}"></adminroutes>
        <adminroutes exact="" path="/admin/periods/:periodId/goals/creation" component="{scenes.AdminGoalCreation}"></adminroutes>
        <adminroutes exact="" path="/admin/periods/:periodId/goals/modification/:id" component="{scenes.AdminGoalUpdate}"></adminroutes>
        <adminroutes exact="" path="/admin/periods/:periodId/levels" component="{scenes.AdminLevelList}"></adminroutes>
        <adminroutes exact="" path="/admin/periods/:periodId/levels/creation" component="{scenes.AdminLevelCreation}"></adminroutes>
        <adminroutes exact="" path="/admin/periods/:periodId/levels/modification/:id" component="{scenes.AdminLevelUpdate}"></adminroutes>
        <adminroutes exact="" path="/admin/logo" component="{scenes.AdminLogo}"></adminroutes>
        <adminroutes exact="" path="/admin/periods/:periodId/points" component="{scenes.AdminPointConfig}"></adminroutes>
        <adminroutes exact="" path="/admin/periods/:periodId/goal-levels" component="{scenes.AdminGoalPointList}"></adminroutes>
        <adminroutes exact="" path="/admin/periods/:periodId/goal-levels/mode-select" component="{scenes.AdminGoalPointModeSelect}"></adminroutes>
        <adminroutes exact="" path="/admin/periods/:periodId/goal-levels/:id" component="{scenes.AdminGoalPointConfig}"></adminroutes>
        <adminroutes exact="" path="/admin/access/" component="{scenes.AdminAccessRightList}"></adminroutes>
        <adminroutes exact="" path="/admin/notifications/" component="{scenes.AdminNotificatonsList}"></adminroutes>
        <adminroutes exact="" path="/admin/organization/" component="{scenes.AdminOrganization}"></adminroutes>
        <adminroutes exact="" path="/admin/users" component="{scenes.AdminUserList}"></adminroutes>
        <adminroutes exact="" path="/admin/users/modification/:id" component="{scenes.AdminUserUpdate}"></adminroutes>
        <adminroutes exact="" path="/admin/users/creation" component="{scenes.AdminUserCreation}"></adminroutes>
        <adminroutes exact="" path="/admin/reports" component="{scenes.AdminReportList}"></adminroutes>
        <adminroutes exact="" path="/admin/reward-categories" component="{scenes.AdminRewardCategoryList}"></adminroutes>
        <adminroutes exact="" path="/admin/reward-categories/creation" component="{scenes.AdminRewardCategoryCreation}"></adminroutes>
        <adminroutes exact="" path="/admin/reward-categories/modification/:id" component="{scenes.AdminRewardCategoryUpdate}"></adminroutes>
        <adminroutes exact="" path="/admin/reports/:id" component="{scenes.AdminReportDetail}"></adminroutes>
        <adminroutes exact="" path="/admin/imports/logs" component="{scenes.AdminImportLogList}"></adminroutes>
        {/* Other routes */}
        <mainroutes exact="" path="{'/help'}" component="{scenes.Contact}"></mainroutes>
        {/* Anonymous routes */}
        <anonymousroutes exact="" path="/login" component="{scenes.Login}"></anonymousroutes>
        <anonymousroutes exact="" path="/reset-password" component="{scenes.ResetPassword}"></anonymousroutes>
        <anonymousroutes exact="" path="/create-password/:reset_token" component="{scenes.CreatePassword}"></anonymousroutes>
        <anonymousroutes exact="" path="/reset-password-confirm/:reset_token" component="{scenes.ResetPasswordConfirm}"></anonymousroutes>
        <anonymousroutes exact="" path="/oauth/callback" component="{scenes.OauthCallback}"></anonymousroutes>
        <anonymousroutes exact="" path="/workos/callback" component="{scenes.WorkosCallback}"></anonymousroutes>
        {/* Intermediate routes */}
        <intermediateroutes exact="" path="/accept-terms" component="{scenes.AcceptTerms}"></intermediateroutes>
        <intermediateroutes exact="" path="/force-reset-password" component="{scenes.ForceResetPassword}"></intermediateroutes>
        {/* File routes */}
        <fileroutes exact="" path="{'/privacy-policy'}" component="{scenes.PrivacyPolicy}"></fileroutes>
        <fileroutes exact="" path="{'/use-terms'}" component="{scenes.UseTerms}"></fileroutes>
      </switch>
    </browserrouter>
  );
};
