import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from '@material-ui/core';
import { RewardOrderItemList, RewardOrderSummary } from '../../components';
import {
  AppBarSubTitle,
  DefaultTitle,
  InfoText,
  Loader,
  MainLayoutComponent,
} from '../../../../components';
import { injectIntl } from 'react-intl';
import * as Resources from '../../../../Resources';
import * as teamRewardOrderDetailActions from '../../../../services/TeamRewardOrders/TeamRewardOrderDetail/actions';

class TeamRewardOrderSummary extends MainLayoutComponent {
  componentDidMount() {
    const { intl } = this.props;
    this.props.handleTitle(intl.formatMessage({ id: 'reward.title' }));
    this.props.handleSubHeader(
      <appbarsubtitle title="{intl.formatMessage({" id:="" 'collaborator.reward_order.summary_title',="" })}=""></appbarsubtitle>,
    );
    this.props.teamRewardOrderDetailActions.getTeamRewardOrder(
      this.props.match.params.id,
    );
    this.props.activateReturn();
  }

  renderLoader() {
    return <loader centered=""></loader>;
  }

  renderData() {
    const { intl } = this.props;
    const { order } = this.props.teamRewardOrderDetail;
    const name = order.counter.team.name;
    const orderPoints = order.items
      .map((x) => x.quantity * x.reward.points)
      .reduce((a, b) => a + b);
    const orderValue = order.items
      .map((x) => x.quantity * x.reward.value)
      .reduce((a, b) => a + b);

    return (
      <div>
        <grid container="" spacing="{4}">
          <grid item="" xs="{12}" md="{7}">
            <grid container="" spacing="{1}">
              <grid item="" xs="{12}">
                <infotext style="{{" visibility:="" 'hidden'="" }}="">Fake</infotext>
                <defaulttitle>
                  {intl
                    .formatMessage({
                      id: 'collaborator.reward_order.summary_rewards_area',
                    })
                    .format(order.id, name)}
                </defaulttitle>
              </grid>
              <grid item="" xs="{12}">
                <rewardorderitemlist items="{order.items}"></rewardorderitemlist>
              </grid>
            </grid>
          </grid>
          <grid item="" xs="{12}" md="{5}">
            <grid container="" spacing="{1}">
              <grid item="" xs="{12}">
                <defaulttitle>
                  {intl.formatMessage({
                    id: 'collaborator.reward_order.summary_points_area',
                  })}
                </defaulttitle>
                <infotext>
                  {intl
                    .formatMessage({
                      id: 'collaborator.reward_order.summary_points_area_year',
                    })
                    .format(order.counter.period.name)}
                </infotext>
              </grid>
              <grid item="" xs="{12}">
                <rewardordersummary recipientPoints="{order.oldPointBalance}" orderPoints="{orderPoints}" orderValue="{orderValue}"></rewardordersummary>
              </grid>
            </grid>
          </grid>
        </grid>
      </div>
    );
  }

  render() {
    const { order, loading } = this.props.teamRewardOrderDetail;

    if (order && (order.isValid == null || order.isValid === false)) {
      return <redirect to="/"></redirect>;
    }

    return (
      <div>
        {loading && this.renderLoader()}
        {!loading && order && this.renderData()}
      </div>
    );
  }
}

const mapStateToProps = ({ teamRewardOrderDetail }) => ({
  teamRewardOrderDetail,
});

const mapDispatchToProps = (dispatch) => ({
  teamRewardOrderDetailActions: bindActionCreators(
    teamRewardOrderDetailActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(TeamRewardOrderSummary));
