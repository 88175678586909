import React, {Component} from "react";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import {
  HiddenInput,
  TextField,
  Card,
  DefaultTitle,
  Loader,
  Collaborator,
  ProgressButton, Button, DialogActions
} from "../../../../../../components";
import {Grid, IconButton} from "@material-ui/core";
import _ from "lodash";
import {ChallengeSearchBar} from "../../../ChallengeSearchBar";
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Formsy from "formsy-react";

const styles = (theme) => {
  return {
    itemIcon: {
      position: 'relative',
      left: '50%',
      top: '-25px',
      marginTop: '-10px',
      zIndex: 40,
    },
    addIcon: {
      color: theme.palette.primary.main,
    },
    deleteIcon: {
      color: '#E50000',
    },
  }
};

class TeamPersonalizedCreationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: {
        name: (props.teamSelected !== -1) ? props.teams[props.teamSelected].name : '',
        lookup_id: (props.teamSelected !== -1) ? props.teams[props.teamSelected].lookup_id : '',
        collaborators: (props.teamSelected !== -1) ? props.teams[props.teamSelected].collaborators : []
      },
      collaborators: props.collaborators,
      resultSearch: [],
      search: ''
    };
  }

  updateTeam = (collab) => {
    let team = this.state.team;
    const index = team.collaborators.findIndex(c => c.id === collab.id);
    if (index !== -1) {
      team.collaborators.splice(index, 1);
    } else {
      team.collaborators.push(collab);
    }
    this.setState({ team: team, resultSearch: [] })
    this.handleSearch(this.state.search)
  }

  onSubmit = (model) => {
    const team = this.state.team
    if (!team.collaborators || team.collaborators.length === 0) {
      return
    }
    team.name = model.name
    team.lookup_id = model.lookup_id
    if (this.props.teamSelected !== -1) {
      this.props.onTeamUpdated(team)
    } else {
      this.props.onTeamAdded(team)
    }
  }

  handleSearch = (newValue) => {
    if (!newValue) { this.setState({ search: '', resultSearch: [] }) }
    else {
      const result = []
      this.state.collaborators.forEach((c) => {
        if (c && c.collaborators) {
          c.collaborators.forEach((c1) => {
            if (c1.firstname.toLowerCase().includes(newValue.toLowerCase())
              || c1.lastname.toLowerCase().includes(newValue.toLowerCase())) {
              // Check collaborator is already in team
              const index = this.state.team.collaborators.findIndex(c2 => c2.id === c1.id);
              let isAlreadyInTeam = false;
              this.props.teams.forEach((t) => {
                const i = t.collaborators.findIndex(tc => tc.id === c1.id);
                if (i !== -1) isAlreadyInTeam = true;
              })
              if (index === -1 && !isAlreadyInTeam) {
                result.push(c1);
              }
            }
          })
        }
      })
      this.setState({ search: newValue, resultSearch: result })
    }
  };

  renderData () {
    const { intl } = this.props;

    return (
      <formsy onValidSubmit="{this.onSubmit}">
        <grid container="" spacing="{4}">
          <grid item="" xs="{12}" container="" spacing="{2}">
            <grid item="" xs="{12}">
              <card>
                <grid container="" spacing="{2}">
                  <grid item="" xs="{6}">
                    <textfield name="name" initial="{_.get(this.state.team," 'name')}="" label="{intl.formatMessage({" id:="" 'team.form.name'="" })}="" fullWidth="" required="" lowercase="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}=""></textfield>
                  </grid>
                  <grid item="" xs="{6}">
                    <textfield name="lookup_id" initial="{_.get(this.state.team," 'lookup_id')}="" label="{intl.formatMessage({" id:="" 'team.form.id'="" })}="" fullWidth="" lowercase=""></textfield>
                  </grid>
                </grid>
              </card>
            </grid>
          </grid>
          <grid item="" xs="{12}" container="" spacing="{2}">
            <grid item="" xs="{12}">
              <challengesearchbar search="{this.state.search}" onChange="{this.handleSearch}" fullSize=""></challengesearchbar>
              <grid container="" spacing="{2}" style="{{" marginTop:="" '10px'="" }}="">
                <grid item="" xs="{6}" direction="column">
                  <defaulttitle>
                    Joueurs disponible
                  </defaulttitle>
                  {this.state.search && this.state.resultSearch.length === 0 ? (
                    <loader centered=""></loader>
                  ) : (
                    <grid container="" spacing="{2}" direction="column">
                      {this.state.search &&
                        this.state.resultSearch?.map((collaborator) => {
                          return (
                            <grid key="{collaborator.id}" item="" xs="{12}" onClick="{()" ==""> this.updateTeam(collaborator)}
                            >
                              <collaborator key="{collaborator.id}" collaborator="{collaborator}"></collaborator>
                              { this.state.team.collaborators.find((c) => {
                                return c.id === collaborator.id
                              }) !== undefined ? (
                                <iconbutton size="small" className="{this.props.classes.itemIcon}">
                                  <fontawesomeicon icon="{faMinus}" className="{this.props.classes.deleteIcon}"></fontawesomeicon>
                                </iconbutton>
                              ) : (
                                <iconbutton size="small" className="{this.props.classes.itemIcon}">
                                  <fontawesomeicon icon="{faPlus}" className="{this.props.classes.addIcon}"></fontawesomeicon>
                                </iconbutton>
                              )}
                            </grid>
                          );
                        })}
                    </grid>
                  )}
                </grid>
                <grid item="" xs="{6}">
                  <defaulttitle>
                    Joueurs de l'équipe
                  </defaulttitle>
                  {this.state.team.collaborators.length === 0 ? (
                    <defaulttitle>
                      Aucun joueurs
                    </defaulttitle>
                  ) : (
                    <grid container="" spacing="{2}" direction="column">
                      {this.state.team.collaborators?.map((collaborator) => {
                          return (
                            <grid key="{collaborator.id}" item="" xs="{12}" onClick="{()" ==""> this.updateTeam(collaborator)}
                            >
                              <collaborator key="{collaborator.id}" collaborator="{collaborator}"></collaborator>
                              <iconbutton size="small" className="{this.props.classes.itemIcon}">
                                <fontawesomeicon icon="{faMinus}" className="{this.props.classes.deleteIcon}"></fontawesomeicon>
                              </iconbutton>
                            </grid>
                          );
                        })}
                    </grid>
                  )}
                </grid>
              </grid>
            </grid>
          </grid>
        </grid>
        <dialogactions>
          <progressbutton type="submit" text="{intl.formatMessage({" id:="" "common.submit"="" })}="" centered=""></progressbutton>
          <button onClick="{this.props.onNewTeamClose}" color="secondary">
            {intl.formatMessage({ id: "common.cancel" })}
          </button>
        </dialogactions>
      </formsy>
    );
  }

  render () {
    return this.renderData();
  }
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(TeamPersonalizedCreationForm)));


