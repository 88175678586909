import React, { useEffect, useContext, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import {
  Card,
  DefaultText,
  Avatar,
  PostCommentForm,
  PostComment,
  Loader,
  TimeAgo,
  Chip,
  Tooltip,
  BlueText,
} from '../../../../components';
import { I18nWrapper } from '../../../../../';
import {
  LikesAvatars,
  PostMenu,
  PostFormDialog,
  LinkPreview,
  Content,
  LikeList,
} from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp,
  faTrash,
  faEdit,
  faFlag,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  faThumbsUp as faThumbsUpEmpty,
  faComment as faCommentEmpty,
  faClock,
} from '@fortawesome/free-regular-svg-icons';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import api from '../../../../../../data/api/api';
import _ from 'lodash';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const styles = {
  card: {
    borderRadius: 10,
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover p': {
      color: 'rgb(15,111,222)',
      textDecoration: 'underline',
    },
  },
  noLink: {
    textDecoration: 'none',
    cursor: 'unset',
  },
  image: {
    width: '100%',
  },
  gifImage: {
    // maxWidth: '500px',
    // maxHeight: '500px',
    width: '100%',
    // height: 'auto',
  },
  postText: {
    padding: 10,
  },
  filterChip: {
    fontSize: 10,
    background: 'transparent',
    color: '#333',
    padding: '0 4px',
    height: 'auto',
    marginTop: '-3px',
    textTransform: 'none',
  },
  content: {
    background: 'white',
  },
  buttonWrapper: {
    padding: 5,
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    borderRadius: 10,
  },
};

const useStyles = makeStyles((theme) => {
  return {
    buttonText: {
      fontSize: 18,
      color: '#757575',
      '&.active': {
        color: theme.palette.primary.main,
      },
    },
    buttonIcon: {
      fontSize: 18,
      color: '#757575',
      marginRight: 10,
      '&.active': {
        color: theme.palette.primary.main,
      },
    },
  };
});

const Post = ({
  post: postInput,
  classes,
  onLike,
  onDislike,
  onReport,
  onDelete,
  onUpdate,
  onCommentCreate,
  onCommentDelete,
  onCommentLike,
  onCommentDislike,
  onCommentReport,
  onCommentUpdate,
  commentCreateSuccess,
  commentLikeSuccess,
  commentDeleteSuccess,
  ...props
}) => {
  const intl = useIntl();
  const context = useContext(I18nWrapper.Context);
  const themeClasses = useStyles();
  const timeAgo = new TimeAgo(context.locale);
  const { account } = props.accountDetail;
  const [edit, setEdit] = React.useState(false);
  const [post, setPost] = React.useState(postInput);
  const [imagePath, setImagePath] = React.useState();
  const [videoPath, setVideoPath] = React.useState();
  const [filePath, setFilePath] = React.useState();
  const [gifPath, setGifPath] = React.useState();
  const [likeState, setLikeState] = React.useState(post.current_user_like);
  const [likesCount, setLikesCount] = React.useState(post.likes_count);
  const [likes, setLikes] = React.useState(post.likes);
  const [initialized, setInitialized] = React.useState(false);
  const [commentFormDisplay, setCommentFormDisplay] = React.useState(false);
  const [comments, setComments] = React.useState();
  const [initialComments, setInitialComments] = React.useState([]);
  const [commentsCount, setCommentsCount] = React.useState(post.comments_count);
  const [likeListOpen, setLikeListOpen] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);

  const date = post.date.toDate();
  const isAuthor =
    account.id === post.author.id || _.get(account, 'role.code') === 'A';
  const menuEntries = _.compact([
    isAuthor
      ? {
          title: intl.formatMessage({ id: 'common.delete' }),
          onClick: () => onDelete(post.id),
          icon: <fontawesomeicon icon="{faTrash}"></fontawesomeicon>,
        }
      : null,
    isAuthor
      ? {
          title: intl.formatMessage({ id: 'common.edit' }),
          onClick: () => setEdit(true),
          icon: <fontawesomeicon icon="{faEdit}"></fontawesomeicon>,
        }
      : null,
    !isAuthor
      ? {
          title: intl.formatMessage({ id: 'common.report' }),
          onClick: () => onReport(post.id),
          icon: <fontawesomeicon icon="{faFlag}"></fontawesomeicon>,
        }
      : null,
  ]);

  const getComments = () => {
    api.posts.comments(post.id).then((response) => {
      if (initialComments.length === 0) {
        setInitialComments(response.data);
        setComments(response.data);
      } else {
        setComments([
          ..._.orderBy(
            response.data.filter(
              (comment) =>
                initialComments.map((c) => c.id).indexOf(comment.id) < 0
            ),
            ['id'],
            ['desc']
          ),
          ...response.data.filter(
            (comment) =>
              initialComments.map((c) => c.id).indexOf(comment.id) >= 0
          ),
        ]);
      }
    });
  };

  useEffect(() => {
    if (commentFormDisplay && !comments) {
      getComments();
    }
  }, [commentFormDisplay]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
    if (initialized) {
      if (likeState) {
        onLike(post.id);
        setLikesCount(likesCount + 1);
        setLikes([{ user: account }, ...likes]);
      } else {
        onDislike(post.id);
        setLikesCount(likesCount - 1);
        setLikes(_.filter(likes, (like) => like.user.id !== account.id));
      }
    }
  }, [likeState]);

  const commentCreate = (postId, comment) => {
    onCommentCreate(postId, comment);
    if (!comment.parent) {
      setComments(
        comments
          ? [Object.assign({}, comment, { date: Date.now() }), ...comments]
          : [comment]
      );
    }
  };

  const commentDelete = (commentId) => {
    onCommentDelete(commentId);
    setComments(
      comments.filter((comment) => {
        if (comment.id !== commentId) {
          return Object.assign({}, comment, {
            comments: comment.replies.filter((reply) => reply.id !== commentId),
          });
        }
      })
    );
  };

  const handleUpdate = (model) => {
    setUpdating(true); // Установите загрузку перед обновлением
    const newPost = Object.assign({}, post, model);
    // setPost(newPost);
    onUpdate(newPost);
    setEdit(false);
  };
  useEffect(() => {
    if (post.image) {
      if (typeof post.image === 'string') {
        setImagePath(post.image);
      } else {
        const reader = new FileReader();
        reader.onloadend = function (e) {
          setImagePath(reader.result);
        }.bind(this);
        reader.readAsDataURL(post.image);
      }
    }
    if (post.video) {
      if (typeof post.video === 'string') {
        setVideoPath(post.video);
      } else {
        const reader = new FileReader();
        reader.onloadend = function (e) {
          setVideoPath(reader.result);
        }.bind(this);
        reader.readAsDataURL(post.video);
      }
    }
    if (post.file) {
      if (typeof post.file === 'string' || post.file.name) {
        setFilePath(post.file);
      } else {
        const reader = new FileReader();
        reader.onloadend = function (e) {
          setFilePath(reader.result);
        }.bind(this);
        reader.readAsDataURL(post.file);
      }
    }
    if (post.gif) {
      if (typeof post.gif === 'string') {
        setGifPath(post.gif);
      } else {
        setGifPath(_.get(post.gif, 'images.fixed_height.url'));
      }
    }
  }, [post]);

  useEffect(() => {
    if (commentCreateSuccess) {
      getComments();
    }
  }, [commentCreateSuccess]);

  useEffect(() => {
    if (commentLikeSuccess) {
      getComments();
    }
  }, [commentLikeSuccess]);

  useEffect(() => {
    if (commentDeleteSuccess) {
      getComments();
    }
  }, [commentDeleteSuccess]);

  useEffect(() => {
    const newCommentsCount = comments
      ? comments.length +
        _.sum(comments.map((c) => (c.replies ? c.replies.length : 0)))
      : post.comments_count;
    if (newCommentsCount !== commentsCount) {
      setCommentsCount(newCommentsCount);
    }
  }, [comments]);

  const hasProfile = (user) =>
    _.get(user, 'role_code') === 'C' && _.get(user, 'team');

  const goToUserProfile = (user) => {
    if (hasProfile(user)) {
      props.history.push(
        `/teams/${user.team.id}/collaborators/${user.id}/detail`
      );
    }
  };
  const reportWarning =
    _.get(account, 'role.code') === 'A' && post.report_count > 0;

  useEffect(() => {
    setUpdating(false);
  }, [postInput]);
  return (
    <div>
      <card marginDisabled="" className="{classes.card}">
        <grid container="" spacing="{1}">
          <grid item="" xs="{12}">
            <grid container="" spacing="{2}" className="{classes.postText}">
              <grid item="">
                <span onClick="{()" ==""> goToUserProfile(post.author)}
                  className={
                    hasProfile(post.author) ? classes.link : classes.noLink
                  }
                >
                  <avatar src="{post.author.photo}" fallbackName="{post.author.fullname}"></avatar>
                </span>
              </grid>

              <grid item="" xs="" container="" direction="column">
                <grid item="">
                  <grid container="">
                    <grid item="">
                      <span onClick="{()" ==""> goToUserProfile(post.author)}
                        className={
                          hasProfile(post.author)
                            ? classes.link
                            : classes.noLink
                        }
                      >
                        <defaulttext 16="" lowercase="" style="{{" fontSize:="" }}="">
                          {post.author.fullname}
                        </defaulttext>
                      </span>
                    </grid>
                    {post.visibility?.role !== null && (
                      <grid 5="" item="" style="{{" marginLeft:="" }}="">
                        <tooltip title="{intl" .formatMessage({="" id:="" 'newsfeed.post_visibility_role_tooltip',="" })="" .format(="" intl.formatMessage({="" `roles.${post.visibility?.role_code}`,="" )}="">
                          <bluetext>
                            <fontawesomeicon icon="{faInfoCircle}"></fontawesomeicon>
                          </bluetext>
                        </tooltip>
                      </grid>
                    )}
                  </grid>
                </grid>
                {post.author.title && (
                  <grid item="">
                    <defaulttext 5="" lowercase="" style="{{" fontSize:="" 12,="" marginBottom:="" }}="">
                      {post.author.title}
                    </defaulttext>
                  </grid>
                )}
                <grid item="">
                  <grid container="" style="{{" alignItems:="" 'center'="" }}="" spacing="{1}">
                    <grid item="">
                      {reportWarning ? (
                        <tooltip title="{" reportWarning="" ?="" intl.formatMessage({="" id:="" 'newsfeed.reported_post',="" })="" :="" null="" }="">
                          <div>
                            <defaulttext lowercase="" style="{{" fontSize:="" 11,="" color:="" '#f44336'="" }}="">
                              <fontawesomeicon 5="" icon="{faExclamationTriangle}" style="{{" marginRight:="" }}=""></fontawesomeicon>
                              {timeAgo.format(date)}
                            </defaulttext>
                          </div>
                        </tooltip>
                      ) : (
                        <defaulttext 11="" lowercase="" style="{{" fontSize:="" }}="">
                          <fontawesomeicon 5="" icon="{faClock}" style="{{" marginRight:="" }}=""></fontawesomeicon>
                          {timeAgo.format(date)}
                        </defaulttext>
                      )}
                    </grid>

                    <grid 2="" item="" style="{{" marginTop:="" }}="">
                      <chip size="small" label="{" _.get(post,="" 'visibility.all')="" ?="" intl.formatMessage({="" id:="" 'newsfeed.for_everybody',="" })="" :="" 'visibility.team.name')="" ||="" 'visibility.team_group.name')="" }="" style="{{" borderColor:="" 'visibility.team.color')="" '#333',="" }}="" variant="outlined" className="{classes.filterChip}"></chip>
                    </grid>
                  </grid>
                </grid>
              </grid>
              <grid item="">
                <grid item="">
                  <postmenu entries="{menuEntries}"></postmenu>
                </grid>
              </grid>
            </grid>
          </grid>

          <grid item="" xs="{12}">
            <div 0="" className="{classes.postText}" style="{{" paddingBottom:="" }}="">
              {updating && <loader centered=""></loader>}
              <content content="{post.description}" seeMoreClass="{classes.content}"></content>
            </div>
          </grid>
          {post.image && (
            <grid item="" xs="{12}">
              <img src="{imagePath}" className="{classes.image}">
            </grid>
          )}
          {post.video && (
            <grid item="" xs="{12}">
              <video width="100%" controls="">
                <source src="{videoPath}" type="video/mp4">
              </video>
            </grid>
          )}
          {post.gif && (
            <grid item="" xs="{12}" style="{{" display:="" 'flex',="" justifyContent:="" 'center'="" }}="">
              <img src="{gifPath}" className="{classes.gifImage}">
            </grid>
          )}

          {filePath && (
            <grid item="" xs="{12}">
              <linkpreview openGraph="{{" url:="" typeof="" filePath="==" 'string'="" ?="" :="" null,="" title:="" _.get(filePath,="" 'name')="" ||="" _.last(filePath.split('="" ')),="" }}=""></linkpreview>
            </grid>
          )}
          {post.link_preview && (
            <grid item="" xs="{12}">
              <linkpreview openGraph="{post.link_preview}"></linkpreview>
            </grid>
          )}
          {post.embed && (
            <grid item="" xs="{12}">
              <iframe width="100%" height="{500}" src="{post.embed}" frameBorder="0" allowFullScreen=""></iframe>
            </grid>
          )}
          {(commentsCount > 0 || likes.length > 0) && (
            <grid item="" xs="{12}">
              <div 10="" style="{{" paddingLeft:="" }}="">
                <grid container="" spacing="{2}" style="{{" justifyContent:="" 'space-between',="" paddingRight:="" 25,="" paddingLeft:="" 15,="" }}="">
                  <grid item="">
                    {likes.length > 0 && (
                      <grid container="" spacing="{1}" onClick="{()" ==""> setLikeListOpen(true)}
                        className={classes.link}
                      >
                        <grid item="">
                          <defaulttext lowercase="">
                            <fontawesomeicon icon="{faThumbsUp}"></fontawesomeicon>
                          </defaulttext>
                        </grid>

                        <grid item="" style="{{" alignSelf:="" 'center'="" }}="">
                          <likesavatars likes="{likes}" limit="{3}"></likesavatars>
                        </grid>
                      </grid>
                    )}
                  </grid>

                  <grid item="" onClick="{()" ==""> setCommentFormDisplay(true)}
                    className={classes.link}
                  >
                    {commentsCount > 0 && account.newsFeedCommentsCreation && (
                      <grid container="" spacing="{1}">
                        <grid item="">
                          <defaulttext 12="" lowercase="" style="{{" fontSize:="" }}="">
                            {commentsCount}{' '}
                            {intl.formatMessage({ id: 'newsfeed.comments' })}
                          </defaulttext>
                        </grid>
                      </grid>
                    )}
                  </grid>
                </grid>
              </div>
            </grid>
          )}
          <div style="{{" marginTop:="" 10,="" borderTop:="" '1px="" solid="" #EBEBEB',="" width:="" '100%',="" marginLeft:="" 25,="" marginRight:="" }}=""></div>

          <grid item="" xs="{12}">
            <grid container="" spacing="{1}" alignItems="flex-start" style="{{" padding:="" '5px="" 10px'="" }}="">
              <grid item="" style="{{" width:="" 'auto'="" }}="" onClick="{()" ==""> setLikeState(!likeState)}
              >
                <grid container="" className="{`${classes.buttonWrapper}`}">
                  <grid item="">
                    <fontawesomeicon icon="{likeState" ?="" faThumbsUp="" :="" faThumbsUpEmpty}="" className="{`${themeClasses.buttonIcon}" ${="" likeState="" 'active'="" ''="" }`}=""></fontawesomeicon>
                  </grid>
                  <grid item="">
                    <defaulttext lowercase="">
                      <span className="{`${themeClasses.buttonText}" ${="" likeState="" ?="" 'active'="" :="" ''="" }`}="">
                        Like
                      </span>
                    </defaulttext>
                  </grid>
                </grid>
              </grid>
              {account.newsFeedCommentsCreation && (
                <grid item="" style="{{" width:="" 'auto'="" }}="" onClick="{()" ==""> setCommentFormDisplay(true)}
                >
                  <grid container="" className="{classes.buttonWrapper}">
                    <grid item="">
                      <fontawesomeicon icon="{faCommentEmpty}" className="{`${themeClasses.buttonIcon}`}"></fontawesomeicon>
                    </grid>
                    <grid item="">
                      <defaulttext lowercase="">
                        <span className="{`${themeClasses.buttonText}`}">
                          {intl.formatMessage({
                            id: 'newsfeed.comment_action',
                          })}
                        </span>
                      </defaulttext>
                    </grid>
                  </grid>
                </grid>
              )}
            </grid>
          </grid>
          {commentFormDisplay && (
            <react.fragment>
              <grid item="" xs="{12}">
                <div className="{classes.postText}">
                  <postcommentform onSubmit="{(model)" ==""> commentCreate(post.id, model)}
                  />
                </postcommentform></div>
              </grid>
              <grid item="" xs="{12}">
                <div className="{classes.postText}">
                  {!comments && <loader centered=""></loader>}
                  {comments && (
                    <grid container="" spacing="{2}">
                      {comments.map((comment, index) => (
                        <grid item="" xs="{12}" key="{`post-${index}`}">
                          <postcomment comment="{comment}" onSubmit="{(model)" ==""> commentCreate(post.id, model)}
                            onLike={onCommentLike}
                            onDislike={onCommentDislike}
                            onDelete={commentDelete}
                            onUpdate={onCommentUpdate}
                            onReport={onCommentReport}
                          />
                        </postcomment></grid>
                      ))}
                    </grid>
                  )}
                </div>
              </grid>
            </react.fragment>
          )}
        </grid>
      </card>
      <postformdialog dialogOpen="{edit}" setDialogOpen="{setEdit}" onSubmit="{handleUpdate}" post="{post}" title="{intl.formatMessage({" id:="" 'newsfeed.edit_post_title'="" })}="" {...props}=""></postformdialog>
      <likelist open="{likeListOpen}" likes="{likes}" setOpen="{setLikeListOpen}"></likelist>
    </div>
  );
};

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(withStyles(styles)(withRouter(Post)));
