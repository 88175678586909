import React from 'react';
import {useIntl} from "react-intl";
import {Chip} from "@material-ui/core";

import {HierarchyNodeUserStatuses} from "../enums";

const HierarchyNodeUserStatus = ({status}) => {
    const intl = useIntl();

    switch (status) {
        case HierarchyNodeUserStatuses.DELETED:
        case HierarchyNodeUserStatuses.INACTIVE:
            return <chip label="{intl.formatMessage({id:" 'spider.hierarchy_node_user_status.unsubscribed'})}="" size="{'small'}" className="{'error'}/">
        case HierarchyNodeUserStatuses.ACTIVE:
        case HierarchyNodeUserStatuses.PENDING_ACTIVATION:
        case HierarchyNodeUserStatuses.PENDING_ACTIVATION_EMAIL:
        default:
            return <chip label="{intl.formatMessage({id:" 'spider.hierarchy_node_user_status.registered'})}="" size="{'small'}" className="{'success'}/">
    }
}

export default HierarchyNodeUserStatus;
</chip></chip>