import React, { useState } from 'react';
import { Grid, CardMedia, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import { faFireAlt, faLock } from '@fortawesome/free-solid-svg-icons';
import { ChallengeImage, ChallengeType, ChallengeReward } from '..';
import {
  DefaultText,
  InfoText,
  TimerTag,
  AccentText,
  Tooltip,
  Card,
  BoldTitle,
} from '../../../../components';
import { useIntl } from 'react-intl';
import _ from 'lodash';

const styles = {
  imageContainer: {
    position: 'relative',
  },
  timerContainer: {
    position: 'absolute',
    right: 0,
    top: 16,
  },
  tooltip: {
    minWidth: 320,
  },
  transparentTooltip: {
    background: 'transparent',
  },
};

const ChallengeSimple = ({
  challenge,
  fetchWonAwards,
  fetchCurrentRank,
  fetchGoalPoints,
  fetchTopParticipants,
  ...props
}) => {
  const intl = useIntl();
  const { classes } = props;
  const { account } = props.accountDetail;
  const [wonAwards, setWonAwards] = useState(
    fetchWonAwards ? null : challenge.wonAwards
  );
  const [rank, setRank] = useState(fetchCurrentRank ? null : challenge.rank);
  const [wonAwardsLoading, setWonAwardsLoading] = useState(false);
  const [rankLoading, setRankLoading] = useState(false);
  const [initialized, setInitialized] = useState();
  const [goalPoints, setGoalPoints] = useState();
  const [topParticipants, setTopParticipants] = useState();

  const allowRank = account.hasChallengeRankAccess;

  if (!initialized) {
    setInitialized(true);
    if (fetchWonAwards) {
      setWonAwardsLoading(true);
      fetchWonAwards()
        .then((results) => {
          setWonAwardsLoading(false);
          setWonAwards(results.data);
        })
        .catch(() => {
          setWonAwardsLoading(false);
        });
    }
    if (fetchCurrentRank) {
      setRankLoading(true);
      fetchCurrentRank()
        .then((results) => {
          setRankLoading(false);
          setRank(results.data);
        })
        .catch(() => {
          setRankLoading(false);
        });
    }
    if (fetchGoalPoints) {
      fetchGoalPoints().then((results) => {
        setGoalPoints(results.data);
      });
    }
    if (fetchTopParticipants) {
      fetchTopParticipants().then((results) => {
        setTopParticipants(results.data);
      });
    }
  }

  const coinImage = require(`../../../../assets/img/system/challenge/icons/coin.png`);
  const giftImage = require(`../../../../assets/img/system/challenge/icons/gift.png`);

  const displayRank = rank && allowRank;

  const challengeTypeInfos = {
    CT: {
      rank: (
        <span>
          <span style="{{" fontWeight:="" 'bold'="" }}="">{challenge.participants}</span>{' '}
          {intl.formatMessage({ id: 'challenge.teams' }).format('')}
        </span>
      ),
    },
    TP: {
      rank: (
        <span>
          <span style="{{" fontWeight:="" 'bold'="" }}="">{challenge.participants}</span>{' '}
          {intl.formatMessage({ id: 'challenge.teams' }).format('')}
        </span>
      ),
    },
    CC: {
      rank: (
        <span>
          <span>
            {challenge.totalParticipants &&
            challenge.participants !== challenge.totalParticipants ? (
              <span>
                <span style="{{" fontWeight:="" 'bold'="" }}="">
                  {challenge.participants}
                </span>{' '}
                / {challenge.totalParticipants}
              </span>
            ) : (
              <span style="{{" fontWeight:="" 'bold'="" }}="">
                {challenge.participants}
              </span>
            )}
          </span>
          {intl.formatMessage({ id: 'challenge.collaborators' }).format('')}
        </span>
      ),
    },
    TG: {
      rank: (
        <span>
          <span style="{{" fontWeight:="" 'bold'="" }}="">{challenge.participants}</span>{' '}
          {intl.formatMessage({ id: 'challenge.team_groups' }).format('')}
        </span>
      ),
    },
  };

  const isTeamGroupChallenge = challenge.typeCode === 'TG';

  return (
    <div>
      <grid container="" spacing="{2}">
        <grid item="" xs="{12}">
          <div className="{classes.imageContainer}">
            <div className="{classes.timerContainer}">
              <timertag date="{challenge.end}"></timertag>
            </div>
            <challengeimage image="{challenge.custom_image" ||="" challenge.image}=""></challengeimage>
          </div>
        </grid>
        <grid item="" xs="{12}" style="{{" maxHeight:="" 49,="" overflow:="" 'hidden'="" }}="">
          <grid container="" spacing="{2}" style="{{" alignItems:="" 'baseline'="" }}="">
            <grid 0="" container="" spacing="{1}" direction="column" style="{{" padding:="" '5px="" 5px="" 5px'="" }}="">
              <grid item="">
                <boldtitle 1="" lowercase="" style="{{" lineHeight:="" }}="">
                  {challenge.name}
                </boldtitle>
              </grid>
              {!isTeamGroupChallenge && (
                <grid item="">
                  <grid container="" direction="row" spacing="{2}">
                    <grid item="">
                      <defaulttext lowercase="">
                        {!rankLoading && displayRank && (
                          <div>
                            <span 15="" style="{{" fontWeight:="" 'bold',="" fontSize:="" }}="">
                              {rank == 1
                                ? intl
                                    .formatMessage({
                                      id: 'challenge.first_rank',
                                    })
                                    .format(rank)
                                : intl
                                    .formatMessage({
                                      id: 'challenge.other_rank',
                                    })
                                    .format(rank)}
                            </span>
                            <infotext component="span">
                              {' '}
                              / {challenge.participants}
                            </infotext>
                          </div>
                        )}
                        {!rankLoading && !displayRank && (
                          <div>
                            &nbsp;
                            {challengeTypeInfos[challenge.typeCode].rank}
                          </div>
                        )}
                        {rankLoading && allowRank && (
                          <span>
                            <circularprogress style="{{" width:="" 20,="" height:="" marginLeft:="" 10,="" color:="" '#00E58D',="" marginBottom:="" -5,="" }}=""></circularprogress>
                          </span>
                        )}
                      </defaulttext>
                    </grid>
                    <grid item="">
                      {/* enable_manager_score is undefined for collaborator challenges */}
                      {challenge.enable_manager_score !== false && (
                        <grid item="">
                          <defaulttext lowercase="">
                            <span 15="" style="{{" fontWeight:="" 'bold',="" fontSize:="" }}="">
                              {intl
                                .formatMessage({ id: 'challenge.points' })
                                .format('')}
                            </span>
                            {goalPoints &&
                              parseFloat(goalPoints).toLocaleString()}
                          </defaulttext>
                        </grid>
                      )}
                    </grid>
                  </grid>
                </grid>
              )}
            </grid>
            {wonAwardsLoading && (
              <grid item="">
                <circularprogress style="{{" width:="" 20,="" height:="" marginLeft:="" 10,="" color:="" '#00E58D',="" marginBottom:="" -5,="" }}=""></circularprogress>
              </grid>
            )}

            {wonAwards && (
              <grid 37="" item="" style="{{" maxHeight:="" }}="">
                <grid container="" spacing="{1}">
                  <grid item="">
                    <defaulttext lowercase="" style="{{" fontSize:="" 15,="" fontWeight:="" 'bold'="" }}="">
                      {intl.formatMessage({ id: 'challenge.awards_title' })} :
                    </defaulttext>
                  </grid>
                  {wonAwards.length === 0 && (
                    <grid 15="" item="" style="{{" position:="" 'relative',="" fontSize:="" }}="">
                      <tooltip title="Aucun gain remporté">
                        <div>
                          <accenttext lowercase="" style="{{" position:="" 'absolute',="" top:="" -5,="" left:="" 15,="" color:="" '#00E58D',="" zIndex:="" 100,="" }}="">
                            <fontawesomeicon icon="{faLock}"></fontawesomeicon>
                          </accenttext>
                          <div style="{{" filter:="" 'grayscale(1)'="" }}="">
                            {_.get(challenge, 'rewardTypeCode') === 'G' ? (
                              <cardmedia image="{giftImage}" style="{{" height:="" 18,="" width:="" marginRight:="" 5,="" }}=""></cardmedia>
                            ) : (
                              <cardmedia image="{coinImage}" style="{{" height:="" 18,="" width:="" marginRight:="" 5,="" }}=""></cardmedia>
                            )}
                          </div>
                        </div>
                      </tooltip>
                    </grid>
                  )}
                  {wonAwards.length > 0 && (
                    <react.fragment>
                      {wonAwards[0].reward ? (
                        <grid item="">
                          <tooltip 320="" className="{`${classes.tooltip}" ${classes.transparentTooltip}`}="" title="{" <div="" style="{{" minWidth:="" }}="">
                                <card>
                                  <challengereward reward="{wonAwards[0].reward}"></challengereward>
                                </card>
                              </tooltip></grid></react.fragment></grid></grid></grid></grid></grid></div>
                            }
                          >
                            <cardmedia 5="" image="{giftImage}" style="{{" height:="" 18,="" width:="" marginRight:="" }}=""></cardmedia>
                          
                        
                      ) : (
                        <grid item="">
                          <tooltip title="{" <Grid="" container="" spacing="{1}">
                                <grid item="">{wonAwards[0].points}</grid>
                                <grid item="">
                                  <cardmedia 20="" image="{coinImage}" style="{{" height:="" 20,="" width:="" }}=""></cardmedia>
                                </grid>
                              </tooltip></grid>
                            }
                          >
                            <cardmedia 5="" image="{coinImage}" style="{{" height:="" 18,="" width:="" marginRight:="" }}=""></cardmedia>
                          
                        
                      )}
                    
                  )}
                
              
            )}
          
        
      
    