import React from 'react';
import { Route } from 'react-router-dom';
import {
  Container,
  CssBaseline,
  Grid,
  CardMedia,
  isWidthUp,
  withWidth,
} from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useClearCache } from 'react-clear-cache';
import { useIntl } from 'react-intl';
import { LoginText } from '../../../../components';
import Background from '../../../../assets/fond.jpg';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Nunito Sans',
  },
  palette: {
    background: {
      default: '#F7F8FC',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          // backgroundImage: `url(${Background})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'repeat',
          backgroundSize: 2000,
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 32,
  },
  loginImage: {
    backgroundSize: 'contain',
    width: '100%',
    height: '100%',
  },
}));

const Layout = ({ component: Component, width, ...rest }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const loginImageBottom = require('../../../../assets/login_image_bottom.png');
  const loginImageBadges = require('../../../../assets/login_image_badges.png');
  const isDesktop = isWidthUp('md', width);

  if (!isLatestVersion) {
    localStorage.clear();
    emptyCacheStorage();
  }

  return (
    <muithemeprovider theme="{theme}">
      <route {...rest}="" render="{(matchProps)" ==""> (
          <grid container="">
            {isDesktop && (
              <grid item="" xs="{0}" md="{6}" container="" justify="space-between" direction="column">
                <grid item="" style="{{" textAlign:="" 'center',="" padding:="" '50px="" 30px="" 0',="" display:="" isDesktop="" ?="" ''="" :="" 'none',="" }}="">
                  <logintext>
                    {intl.formatMessage({ id: 'login.description_line1' })}
                    <br>
                    {intl.formatMessage({ id: 'login.description_line2' })}
                    <br>
                    {intl.formatMessage({ id: 'login.description_line3' })}
                  </logintext>
                </grid>
                <grid item="" xs="">
                  <cardmedia className="{classes.loginImage}" image="{loginImageBadges}"></cardmedia>
                </grid>
                <grid item="" xs="">
                  <cardmedia className="{classes.loginImage}" style="{{" backgroundPosition:="" 'bottom'="" }}="" image="{loginImageBottom}"></cardmedia>
                </grid>
              </grid>
            )}
            <grid item="" xs="{12}" md="{6}">
              <div className="{classes.root}">
                <cssbaseline></cssbaseline>
                <main className="{classes.content}">
                  <container maxWidth="sm">
                    <component {...matchProps}=""></component>
                  </container>
                </main>
              </div>
            </grid>
          </grid>
        )}
      />
    </route></muithemeprovider>
  );
};
export default withWidth()(Layout);
