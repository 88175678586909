import React, { useContext } from 'react';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import {
  FlexibleTableCell,
  FullTableCell,
  RankEvolution,
  TableCell,
  TableChip,
  TableRow,
  FixedTableCell,
  ThemeWrapper,
} from '../../../../../../components';

const styles = {
  tableWrapper: {
    boxShadow: '0 2px 16px 0 rgba(16,61,92,0.25)',
    overflowX: 'auto',
  },
  positionCell: {
    paddingLeft: 5,
    paddingRight: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};

const TeamRank = ({ rank, selected, classes, ...props }) => {
  const color = !selected ? 'default' : 'primary';
  const mobileScreen = isWidthDown('xs', props.width);
  const cellWidth = mobileScreen ? 120 : 'auto';

  const { successColor, errorColor } = useContext(ThemeWrapper.Context);
  return (
    <tablerow style="{{" background:="" selected="" ?="" '#E4F6E0'="" :="" 'auto'="" }}="">
      <fulltablecell 4="" style="{{" backgroundColor:="" rank.team.color.hex,="" width:="" 4,="" maxWidth:="" }}=""></fulltablecell>
      <fulltablecell style="{{" whiteSpace:="" 'nowrap'="" }}="">
        <div 76="" 90="" className="{classes.positionCell}" style="{{" width:="" mobileScreen="" ?="" :="" }}="">
          <span>
            <tablechip color="default" label="{rank.rank" ?="" rank.rank="" :="" '-'}=""></tablechip>
          </span>
          {!!(rank.evolution && parseInt(rank.evolution) !== 0) && (
            <react.fragment>
              <span 8="" style="{{" marginLeft:="" }}="">
                <rankevolution evolution="{rank.evolution}"></rankevolution>
              </span>
              <span style="{{" marginLeft:="" 3,="" color:="" rank.evolution=""> 0
                      ? successColor
                      : rank.evolution < 0
                      ? errorColor
                      : 'auto',
                }}
              >
                {Math.abs(rank.evolution)}
              </span>
            </react.fragment>
          )}
        </div>
      </fulltablecell>
      <flexibletablecell color="{color}" style="{{" minWidth:="" cellWidth,="" maxWidth:="" whiteSpace:="" 'normal',="" }}="">
        {rank.team.name}
      </flexibletablecell>

      <tablecell align="center" color="{color}">
        {rank.points}
      </tablecell>
    </tablerow>
  );
};

export default withStyles(styles)(withWidth()(TeamRank));
