import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { Badge, Notifications } from './components';
import {
  AccentText,
  DefaultText,
  GridLink,
  InfoText,
  ProgressBar,
  AnimatedCounter,
  Avatar,
} from '../../../../../../../../..';
import * as Resources from '../../../../../../../../../../Resources';
import { useIntl } from 'react-intl';
import '../../../../../../../../../../helpers/NumberHelper';
import '../../../../../../../../../../helpers/StringHelper';
import * as accountDetailActions from '../../../../../../../../../../services/Account/AccountDetail/actions';
import { bindActionCreators } from 'redux';

const styles = {
  root: {
    marginLeft: 32,
    marginTop: 32,
    marginRight: 32,
  },
  avatar: {
    width: 72,
    height: 72,
  },
  infos: {
    marginTop: 8,
  },
  notificationContainer: {
    marginTop: 8,
  },
  notifications: {
    cursor: 'pointer',
  },
  menuLabel: {
    textTransform: 'none',
    fontSize: 14,
  },
};

const Account = ({ onNavigate, ...props }) => {
  const intl = useIntl();
  const { classes } = props;
  const { account } = props.accountDetail;
  const { count } = props.inAppNotificationCount;
  const isCollaborator = account.role.code == 'C';
  const isJti = account.isJtiEnv;
  const photo = account.photo ? account.photo : '/assets/img/user/avatar.svg';

  const percentage =
    isCollaborator && account.nextLevel && account.rank && account.level
      ? (
          (account.rank.points - account.level.points) /
          (account.nextLevel.points - account.level.points)
        ).toFullPercentage()
      : isCollaborator && !account.nextLevel && account.rank && account.level
      ? 100
      : 0;
  const [initialized, setInitialized] = React.useState(false);
  const [notificationOpen, setNotificationOpen] = React.useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      props.accountDetailActions.getAccountDetail();
    }
  });

  function handleNotificationClose() {
    setNotificationOpen(false);
    if (onNavigate) onNavigate();
  }

  return (
    <div className="{classes.root}">
      <grid container="" spacing="{2}">
        {isCollaborator && !isJti && (
          <gridlink item="" component="{Link}" to="{`/collaborators/${account.id}/detail`}">
            <avatar src="{photo}" className="{classes.avatar}" onClick="{onNavigate}" entityId="{account.id}" fallbackName="{account.fullname}" fontSize="{34}"></avatar>
          </gridlink>
        )}
        {isCollaborator && isJti && (
          <grid item="">
            <avatar src="{photo}" className="{classes.avatar}" onClick="{onNavigate}" entityId="{account.id}" fallbackName="{account.fullname}" fontSize="{34}"></avatar>
          </grid>
        )}
        {!isCollaborator && (
          <grid item="">
            <avatar src="{photo}" className="{classes.avatar}" entityId="{account.id}" fallbackName="{account.fullname}" fontSize="{34}"></avatar>
          </grid>
        )}
        <grid item="" xs="" zeroMinWidth="">
          <div>
            <grid container="">
              {isCollaborator && !isJti && (
                <grid item="" xs="">
                  <infotext>
                    {intl
                      .formatMessage({ id: 'menu.level_label' })
                      .format(account?.level?.number)}
                  </infotext>
                </grid>
              )}
              {isCollaborator && !isJti && (
                <grid item="">
                  <accenttext>
                    <animatedcounter counter="{account?.rank?.points" ||="" 0}="" timer="{750}" resource="{intl.formatMessage({" id:="" 'menu.points_label'="" })}=""></animatedcounter>
                  </accenttext>
                </grid>
              )}
              {isCollaborator && !isJti && (
                <grid item="" xs="{12}">
                  <progressbar value="{percentage}" animate=""></progressbar>
                </grid>
              )}
              <gridlink item="" xs="{12}" component="{Link}" to="/account" onClick="{onNavigate}" className="{classes.infos}">
                <accenttext>
                  <fontawesomeicon icon="{faEdit}"></fontawesomeicon>
                  &nbsp;&nbsp;
                  <span className="{classes.menuLabel}">
                    {intl.formatMessage({ id: 'menu.infos_button' })}
                  </span>
                </accenttext>
              </gridlink>
              <grid item="" xs="{12}" className="{classes.notificationContainer}" onClick="{()" ==""> setNotificationOpen(true)}
              >
                <defaulttext className="{classes.notifications}">
                  <badge badgeContent="{count}" color="secondary">
                    <fontawesomeicon icon="{faBell}"></fontawesomeicon>
                  </badge>
                  &nbsp;&nbsp;&nbsp;
                  <span className="{classes.menuLabel}">
                    {intl.formatMessage({ id: 'menu.notifications_button' })}
                  </span>
                </defaulttext>
              </grid>
            </grid>
          </div>
        </grid>
      </grid>
      <notifications open="{notificationOpen}" onClose="{handleNotificationClose}"></notifications>
    </div>
  );
};

const mapStateToProps = ({ accountDetail, inAppNotificationCount }) => ({
  accountDetail,
  inAppNotificationCount,
});

const mapDispatchToProps = (dispatch) => ({
  accountDetailActions: bindActionCreators(accountDetailActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Account));
