import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faTrashAlt,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { SubHeader, Filters } from './components';
import ReactDataSheet from 'react-datasheet';
import {
  Card,
  DefaultText,
  DefaultTitle,
  BigText,
  EmptyState,
  ErrorText,
  HiddenInput,
  IconButton,
  MainLayoutComponent,
  ProgressButton,
  TextField,
  BoldSpan,
  Tooltip,
  BlueText,
} from '../../../../components';
import { Tag } from '../../../../components/Teams/components/Team/components';
import * as configListActions from '../../../../services/Configs/ConfigList/actions';
import * as goalDefinitionDetailActions from '../../../../services/GoalDefinitions/GoalDefinitionDetail/actions';
import * as goalDefinitionLevelListActions from '../../../../services/GoalDefinitionLevels/GoalDefinitionLevelList/actions';
import * as goalDefinitionLevelListUpdateActions from '../../../../services/GoalDefinitionLevels/GoalDefinitionLevelListUpdate/actions';
import * as goalDefinitionPointRepartitionListActions from '../../../../services/GoalDefinitionPointRepartitions/GoalDefinitionPointRepartitionList/actions';
import * as goalDefinitionPointRepartitionModeListActions from '../../../../services/GoalDefinitionPointRepartitionModes/GoalDefinitionPointRepartitionModeList/actions';
import * as currentPeriodDetailActions from '../../../../services/Periods/CurrentPeriodDetail/actions';
import * as periodListActions from '../../../../services/Periods/PeriodList/actions';
import * as teamListActions from '../../../../services/Teams/TeamList/actions';
import { withStyles } from '@material-ui/core/styles';
import './helpers/GoalDefinitionLevelFormsyHelper';
import '../../../../helpers/FormsyHelper';
import '../../../../helpers/NumberHelper';
import * as Resources from '../../../../Resources';
import { useIntl, injectIntl } from 'react-intl';
import _ from 'lodash';
import { toast } from 'react-toastify';

const styles = {
  headerPoints: {
    '& p': {
      fontSize: 22,
      fontWeight: 'bold',
    },
  },
  usablePoints: {
    '& p': {
      color: '#00E58D',
    },
  },
  usedPoints: {
    '& p': {
      color: '#f2b666',
    },
  },
  currentPoints: {},
};

class AdminGoalPointConfig extends MainLayoutComponent {
  constructor(props) {
    super(props);
    this.id = null;
    this.new = 0;
    this.definitionInitialized = false;
    this.levelsInitialized = false;
    this.removedLevels = [];
    this.state = {
      levels: [],
      maxPoints: 0,
      usedPoints: 0,
    };
    this.props.goalDefinitionLevelListUpdateActions.clearGoalDefinitionLevelListUpdate();
  }

  loadData = () => {
    const periodId = this.props.match.params.periodId;
    const params = new URLSearchParams(window.location.search);
    const collaborator = params.get('collaborator');
    const team = params.get('team');

    this.props.teamListActions.getTeamList({ disableCollaborators: true });
    this.props.currentPeriodDetailActions.getCurrentPeriodDetail();

    this.props.goalDefinitionPointRepartitionModeListActions.getGoalDefinitionPointRepartitionModeList();
    if (team !== this.team || collaborator !== this.collaborator) {
      this.team = team;
      this.collaborator = collaborator;
      this.props.goalDefinitionDetailActions.getGoalDefinition(
        this.id,
        this.team,
        this.collaborator,
        true,
      );

      this.props.goalDefinitionLevelListActions.getGoalDefinitionLevelList(
        this.id,
        this.team,
        this.collaborator,
      );
    } else {
      this.props.goalDefinitionDetailActions.getGoalDefinition(this.id);
    }
  };

  handleAdd() {
    var levels = this.state.levels;
    this.new++;
    const id = `new${this.new}`;
    levels.push({
      id: id,
      percentage: 0,
      points: 0,
      definition: this.id,
      isNew: true,
    });
    this.setState({
      ...this.state,
      levels: levels,
    });
  }

  handleRemove = (index) => () => {
    var levels = this.state.levels;
    var removedLevels = levels.splice(index, 1);
    removedLevels = removedLevels.filter((level) => !level.isNew);
    this.removedLevels = removedLevels.concat(this.removedLevels);
    this.setState({
      ...this.state,
      levels: levels,
    });
  };

  handleSubmit() {
    const model = this.refs.form.getModel();
    var levels = this.state.levels;
    levels.map((level) => {
      const index = levels.indexOf(level);
      const percentTruncate = _.replace(model.percentage[index], ',', '.')
        .split('.')
        .map((numPart, index) => (index === 1 ? numPart.slice(0, 2) : numPart))
        .join('.');

      level.percentage = parseFloat(
        (parseFloat(percentTruncate) / 100).toFixed(4),
      );
      level.points = model.points[index];
    });
    const oldLevels = levels.filter((level) => !level.isNew);
    const newLevels = levels.filter((level) => level.isNew);

    this.props.goalDefinitionLevelListUpdateActions.updateGoalDefinitionLevelList(
      this.id,
      oldLevels,
      newLevels,
      this.removedLevels,
      this.team,
      this.collaborator,
    );
  }

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.props.activateReturn();
    this.props.handleTitle('Administration');
    this.props.handleSubHeader(<subheader></subheader>);
    // this.props.handleButtons(<iconbutton onClick="{this.handleAdd.bind(this)}" size="small"><fontawesomeicon icon="{faPlus}"></fontawesomeicon></iconbutton>);
    this.props.periodListActions.getPeriodList();
    this.props.configListActions.getConfigList(
      this.props.match.params.periodId,
    );
    this.loadData();
  }

  componentDidUpdate(props) {
    const { levels: previousLevels, loading: previousLevelLoading } =
      this.props.goalDefinitionLevelList;
    const { levels, loading: levelLoading } = props.goalDefinitionLevelList;
    const {
      definition: previousDefinition,
      loading: previousDefinitionLoading,
    } = this.props.goalDefinitionDetail;
    const { definition, loading: definitionLoading } =
      props.goalDefinitionDetail;
    if (
      (!previousDefinitionLoading || !definitionLoading) &&
      (_.get(previousDefinition, 'id') !== _.get(definition, 'id') ||
        !this.definitionInitialized)
    ) {
      this.props.goalDefinitionPointRepartitionListActions.getGoalDefinitionPointRepartitionList(
        _.get(definition, 'id') || _.get(previousDefinition, 'id'),
      );
      this.definitionInitialized = true;
    }

    if (
      _.differenceWith(levels, previousLevels, _.isEqual).length > 0 ||
      _.get(levels, 'length') !== _.get(previousLevels, 'length') ||
      !this.levelsInitialized
    ) {
      this.setState({
        ...this.state,
        levels: previousLevels || levels || [],
      });
      this.levelsInitialized = true;
    }
  }

  renderEmptyState() {
    return (
      <div>
        <emptystate title="Aucun palier trouvé" message="Commencez par ajouter un nouveau palier"></emptystate>
      </div>
    );
  }

  periodsByDefinition = (definition) => {
    // const { period: currentPeriod } = this.props.currentPeriodDetail;
    const { periods, loading: periodsLoading } = this.props.periodList;
    const currentPeriod = periods.find(
      (period) => period.id === parseInt(this.props.match.params.periodId),
    );
    const year = _.get(currentPeriod, 'name');
    let now = new Date();

    const endOfYear = new Date(now.getFullYear(), 11, 31);

    let result = {};
    if (definition.periodicity.code === 'Y') {
      result = {
        total: 1,
        remaining: 1,
      };
    } else if (definition.periodicity.code === 'S') {
      result = {
        total: endOfYear.getSemesterNumber(),
        remaining: endOfYear.getSemesterNumber() - now.getSemesterNumber() + 1,
      };
    } else if (definition.periodicity.code === 'Q') {
      result = {
        total: endOfYear.getQuarterNumber(),
        remaining: endOfYear.getQuarterNumber() - now.getQuarterNumber() + 1,
      };
    } else if (definition.periodicity.code === 'M') {
      result = {
        total: endOfYear.getMonth() + 1,
        remaining: endOfYear.getMonth() - now.getMonth() + 1,
      };
    } else if (definition.periodicity.code === 'W') {
      result = {
        total: endOfYear.getWeekNumber(),
        remaining: endOfYear.getWeekNumber() - now.getWeekNumber() + 1,
      };
    } else if (definition.periodicity.code === 'C') {
      const remainingPeriods = definition.kpi.periods.filter(
        (p) => new Date(p.end * 1000) > now,
      );
      result = {
        total: definition.kpi.periods.length,
        remaining: remainingPeriods.length,
      };
    }

    // handle other years config
    if (year && now.getFullYear() !== parseInt(year)) {
      result.remaining = result.total;
    }
    return result;
  };

  renderData() {
    const { intl } = this.props;
    const { classes } = this.props;
    const { configs } = this.props.configList;
    const { definition } = this.props.goalDefinitionDetail;
    const baseCollaboratorGoalPoints = parseInt(
      configs.find((x) => x.code == 'CPG').value,
    );
    const baseTeamGoalPoints = configs.find((x) => x.code == 'TPG').value;
    const baseGoalPoints =
      definition.type.code === 'T'
        ? baseTeamGoalPoints
        : baseCollaboratorGoalPoints;
    const { teams } = this.props.teamList;
    const { loading } = this.props.goalDefinitionLevelListUpdate;
    const { modes: repartitionModes } =
      this.props.goalDefinitionPointRepartitionModeList;
    const { period: currentPeriod } = this.props.currentPeriodDetail;

    // const usedPoints = this.state.levels && this.state.levels.length > 0 ? Math.max(...this.state.levels.map(x => x.points)) : 0;
    // const usablePoints = (definition.type.code == 'C' ? configs.find(x => x.code == 'CPG').value : definition.type.code == 'T' ? configs.find(x => x.code == 'TPG').value : 0) - definition.points + usedPoints;
    const {
      pointRepartitions,
      loading: goalDefinitionPointRepartitionLoading,
    } = this.props.goalDefinitionPointRepartitionList;

    const repartition = pointRepartitions.filter(
      (pointRepartition) =>
        (this.team &&
          !this.collaborator &&
          pointRepartition.team === parseInt(this.team)) ||
        (this.collaborator &&
          pointRepartition.collaborator === parseInt(this.collaborator)),
    )[0];

    const globalMode = !this.team && !this.collaborator;
    // const repartitionMode = repartition && repartitionModes.find(mode => mode.id === repartition.mode)
    // const currentTeam = this.team ? teams.find(team => team.id === parseInt(this.team)) : null
    // const playersNumber = teams.length && this.team && !this.collaborator ? teams.find(team => team.id === parseInt(this.team)).collaborators.length : null
    const periods = this.periodsByDefinition(definition);
    const usedPoints =
      this.state.levels && this.state.levels.length > 0
        ? Math.max(...this.state.levels.map((x) => x.points))
        : 0;

    // const usablePoints = repartition ? Number((repartition.points * baseGoalPoints / 100).toFixed(2)) : (
    //   (definition.type.code == 'C' ? configs.find(x => x.code == 'CPG').value : definition.type.code == 'T' ? configs.find(x => x.code == 'TPG').value : 0) - definition.points + usedPoints
    // )

    const usablePoints = repartition
      ? Number(((repartition.points * baseGoalPoints) / 100).toFixed(2))
      : 0;

    const dataByPlayer = {
      currentPoints: globalMode
        ? definition.currentPoints
        : usedPoints * periods.remaining,
    };
    dataByPlayer['usablePoints'] =
      usablePoints - dataByPlayer.currentPoints - definition.usedPoints;
    const maxByLevel = parseInt(
      (usablePoints - definition.usedPoints) / periods.remaining,
    );
    return (
      <formsy ref="form" onValidSubmit="{this.handleSubmit.bind(this)}">
        {!globalMode && (
          <hiddeninput name="usablePoints" value="{maxByLevel" ?="" maxByLevel="" :="" 0}=""></hiddeninput>
        )}
        <filters onChange="{()" ==""> {}}
          team={this.team}
          collaborator={this.collaborator}
        />

        <grid item="">
          <grid container="" direction="row" spacing="{4}">
            <grid 12="" item="" xs="{globalMode" ?="" :="" 8}="">
              <grid container="" spacing="{1}">
                <grid item="">
                  <grid container="" spacing="{1}">
                    <grid item="">
                      <bigtext>
                        {intl
                          .formatMessage({
                            id: 'admin.goal.point_config.title',
                          })
                          .format(
                            definition.type.code === 'T'
                              ? intl
                                  .formatMessage({ id: 'common.team' })
                                  .toLowerCase()
                              : intl
                                  .formatMessage({ id: 'common.player' })
                                  .toLowerCase(),
                          )}
                      </bigtext>
                    </grid>
                    <grid item="" style="{{" fontSize:="" '18px'="" }}="">
                      <tooltip title="{intl" .formatMessage({="" id:="" 'admin.goal.point_config.title_tooltip',="" })="" .format(="" intl.formatMessage({="" `admin.goal.point_config_tooltip_${_.get(="" definition,="" 'periodicity.code',="" '',="" ).toLowerCase()}`,="" }),="" definition.type.code="==" 'T'="" ?="" intl="" 'common.team'="" .toLowerCase()="" :="" 'common.player'="" .toLowerCase(),="" _.get(currentPeriod,="" 'name',="" ''),="" )}="">
                        <bluetext>
                          <fontawesomeicon icon="{faInfoCircle}"></fontawesomeicon>
                        </bluetext>
                      </tooltip>
                    </grid>
                  </grid>
                </grid>
                <grid item="">
                  <grid container="" spacing="{4}">
                    <grid item="" xs="{12}">
                      <card>
                        <grid container="" direction="row" spacing="{2}">
                          {!globalMode && (
                            <grid item="">
                              <grid container="" direction="column" alignItems="center" spacing="{2}">
                                <grid item="" className="{`${classes.headerPoints}" ${classes.usablePoints}`}="">
                                  <defaulttext>
                                    {dataByPlayer.usablePoints}
                                  </defaulttext>
                                </grid>
                                <grid item="">
                                  <defaulttext>
                                    {intl
                                      .formatMessage({
                                        id: 'admin.goal.point_config.available_points_title',
                                      })
                                      .format(
                                        definition.type.code === 'T'
                                          ? intl
                                              .formatMessage({
                                                id: 'common.team',
                                              })
                                              .toLowerCase()
                                          : intl
                                              .formatMessage({
                                                id: 'common.player',
                                              })
                                              .toLowerCase(),
                                      )}
                                  </defaulttext>
                                </grid>
                              </grid>
                            </grid>
                          )}
                          <grid item="">
                            <grid container="" direction="column" alignItems="center" spacing="{2}">
                              <grid item="" className="{`${classes.headerPoints}" ${classes.currentPoints}`}="">
                                <defaulttext>
                                  {dataByPlayer.currentPoints}
                                </defaulttext>
                              </grid>
                              <grid item="">
                                <defaulttext>
                                  {intl
                                    .formatMessage({
                                      id: 'admin.goal.point_config.current_points_title',
                                    })
                                    .format(
                                      definition.type.code === 'T'
                                        ? intl
                                            .formatMessage({
                                              id: 'common.team',
                                            })
                                            .toLowerCase()
                                        : intl
                                            .formatMessage({
                                              id: 'common.player',
                                            })
                                            .toLowerCase(),
                                    )}
                                </defaulttext>
                              </grid>
                            </grid>
                          </grid>
                          <grid item="">
                            <grid container="" direction="column" alignItems="center" spacing="{2}">
                              <grid item="" className="{`${classes.headerPoints}" ${classes.currentPoints}`}="">
                                <defaulttext>{periods.remaining}</defaulttext>
                              </grid>
                              <grid item="">
                                <defaulttext>
                                  {intl.formatMessage({
                                    id: `admin.goal.point_config_title_${_.get(
                                      definition,
                                      'periodicity.code',
                                      '',
                                    ).toLowerCase()}`,
                                  })}
                                </defaulttext>
                              </grid>
                            </grid>
                          </grid>
                        </grid>
                      </card>
                    </grid>

                    <grid item="" container="" direction="column" spacing="{1}">
                      <react.fragment>
                        {!globalMode && (
                          <grid item="">
                            <bigtext>
                              {intl
                                .formatMessage({
                                  id: 'admin.goal.point_config.max_points_title',
                                })
                                .format(
                                  definition.type.code === 'T'
                                    ? intl
                                        .formatMessage({ id: 'common.team' })
                                        .toLowerCase()
                                    : intl
                                        .formatMessage({ id: 'common.player' })
                                        .toLowerCase(),
                                )}
                              <span style="{{" fontWeight:="" 'bold'="" }}="">
                                {maxByLevel}
                              </span>
                            </bigtext>
                          </grid>
                        )}
                      </react.fragment>

                      <grid item="" container="" spacing="{2}">
                        {this.state.levels.map((level, index) => {
                          const number = index + 1;
                          const percentageValidations =
                            index > 0
                              ? {
                                  isMoreThanOrEquals: 0,
                                  isMoreThan: `percentage[${index - 1}]`,
                                }
                              : { isMoreThanOrEquals: 0 };
                          const pointValidations =
                            index > 0
                              ? {
                                  isMoreThanOrEquals: 0,
                                  isMoreThan: `points[${index - 1}]`,
                                  isGoalDefinitionLevelValid: true,
                                }
                              : {
                                  isMoreThanOrEquals: 0,
                                  isGoalDefinitionLevelValid: true,
                                };

                          return (
                            <grid key="{level.id}" item="" xs="{6}" container="" spacing="{1}">
                              <grid item="" xs="{12}">
                                <defaulttitle>
                                  {intl
                                    .formatMessage({
                                      id: 'admin.goal.point_config.level_title',
                                    })
                                    .format(number)}
                                </defaulttitle>
                              </grid>
                              <grid item="" xs="{12}">
                                <card>
                                  <grid container="" spacing="{2}" alignItems="flex-end">
                                    <grid item="" xs="">
                                      <textfield type="number" name="{`percentage[${index}]`}" label="{intl.formatMessage({" id:="" 'admin.goal.point_config.form.percent',="" })}="" initial="{level.percentage.toFullPercentage(" 2,="" )}="" fullWidth="" required="" disabled="{!definition.isActive}" validations="{percentageValidations}" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" isMoreThanOrEquals:="" 'admin.goal.point_config.errors.percent_more_equal',="" isMoreThan:="" 'admin.goal.point_config.errors.percent_more_than',="" }}=""></textfield>
                                    </grid>
                                    <grid item="" xs="">
                                      <textfield type="number" name="{`points[${index}]`}" label="{intl.formatMessage({" id:="" 'admin.goal.point_config.form.points',="" })}="" initial="{level.points}" fullWidth="" required="" disabled="{!definition.isActive}" validations="{pointValidations}" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" isMoreThanOrEquals:="" 'admin.goal.point_config.errors.points_more_equal',="" isMoreThan:="" 'admin.goal.point_config.errors.points_more_than',="" isGoalDefinitionLevelValid:="" 'Le="" nombre="" de="" points="" restant="" est="" insuffisant',="" }}=""></textfield>
                                    </grid>
                                    {definition.isActive === true && (
                                      <grid item="" xs="auto">
                                        <iconbutton color="secondary" size="small" onClick="{this.handleRemove(" index,="" ).bind(this)}="">
                                          <fontawesomeicon icon="{faTrashAlt}"></fontawesomeicon>
                                        </iconbutton>
                                      </grid>
                                    )}
                                  </grid>
                                </card>
                              </grid>
                            </grid>
                          );
                        })}
                        {definition.isActive && (
                          <grid item="" xs="{6}" container="" spacing="{1}">
                            <grid item="" xs="{12}">
                              <defaulttitle></defaulttitle>
                            </grid>
                            <grid item="" xs="{12}" onClick="{()" ==""> this.handleAdd()}
                              style={{ cursor: 'pointer', marginTop: '20px' }}
                            >
                              <card>
                                <grid container="" justify="center" alignItems="center" style="{{" height:="" '44px'="" }}="">
                                  <grid item="" style="{{" fontSize:="" '30px',="" color:="" '#00E58D',="" }}="">
                                    <fontawesomeicon icon="{faPlus}"></fontawesomeicon>
                                  </grid>
                                </grid>
                              </card>
                            </grid>
                          </grid>
                        )}
                      </grid>
                    </grid>
                    {this.state.levels.length == 0 && (
                      <grid item="" xs="{12}">
                        <div>
                          <emptystate title="{intl.formatMessage({" id:="" 'admin.goal.point_config.errors.no_level_title',="" })}="" message="{intl.formatMessage({" 'admin.goal.point_config.errors.no_level_message',=""></emptystate>
                        </div>
                      </grid>
                    )}
                    {!definition.isActive && (
                      <grid item="" container="" spacing="{2}" justify="center">
                        <grid item="">
                          <errortext className="{classes.error}" align="center">
                            {intl.formatMessage({
                              id: 'admin.goal.point_config.errors.archived_goal',
                            })}
                          </errortext>
                        </grid>
                      </grid>
                    )}
                    <grid item="" xs="{12}">
                      <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" disabled="{!definition.isActive}" loading="{loading}" centered=""></progressbutton>
                    </grid>
                  </grid>
                </grid>
              </grid>
            </grid>
            {!globalMode && (
              <grid item="" xs="{4}">
                <grid container="" direction="column" spacing="{1}">
                  <grid item="">
                    <grid container="" spacing="{1}">
                      <grid item="">
                        <bigtext>
                          {intl.formatMessage({
                            id: 'admin.goal.point_config.general_info_title',
                          })}
                        </bigtext>
                      </grid>
                      <grid item="" style="{{" fontSize:="" '18px'="" }}="">
                        <tooltip title="{intl" .formatMessage({="" id:="" 'admin.goal.point_config.allocated_points_year',="" })="" .format(_.get(currentPeriod,="" 'name',="" ''))}="">
                          <bluetext>
                            <fontawesomeicon icon="{faInfoCircle}"></fontawesomeicon>
                          </bluetext>
                        </tooltip>
                      </grid>
                    </grid>
                  </grid>
                  <grid item="">
                    <card>
                      <grid container="" spacing="{2}" direction="column">
                        <grid item="">
                          <grid container="" spacing="{2}">
                            <grid item="" xs="{12}" sm="">
                              <grid container="" spacing="{2}">
                                <grid item="" xs="{12}">
                                  <defaulttext>
                                    {intl.formatMessage({
                                      id: 'admin.goal.point_config.allocated_points',
                                    })}
                                    <boldspan component="span">
                                      {usablePoints}
                                    </boldspan>
                                  </defaulttext>
                                </grid>
                                {repartition && (
                                  <grid item="" xs="{12}">
                                    <defaulttext>
                                      {intl.formatMessage({
                                        id: 'admin.goal.point_config.importance_percent',
                                      })}
                                      <boldspan component="span">
                                        {Number(repartition.points).toFixed(2)}%
                                      </boldspan>
                                    </defaulttext>
                                  </grid>
                                )}
                                <grid item="" xs="{12}">
                                  <defaulttext>
                                    {intl.formatMessage({
                                      id: 'admin.goal.point_config.used_points',
                                    })}
                                    <boldspan component="span">
                                      {definition.usedPoints}
                                    </boldspan>
                                  </defaulttext>
                                </grid>
                                <grid item="" xs="{12}">
                                  <defaulttext>
                                    {intl.formatMessage({
                                      id: 'admin.goal.point_config.remaining_points',
                                    })}
                                    <boldspan component="span">
                                      {usablePoints - definition.usedPoints}
                                    </boldspan>
                                  </defaulttext>
                                </grid>
                              </grid>
                            </grid>
                          </grid>
                        </grid>
                      </grid>
                    </card>
                  </grid>
                </grid>
              </grid>
            )}
          </grid>
        </grid>
      </filters></formsy>
    );
  }

  render() {
    const { intl } = this.props;
    const { configs, loading: configListLoading } = this.props.configList;
    const { definition, loading: goalDefinitionDetailLoading } =
      this.props.goalDefinitionDetail;
    const { levels, loading: goalDefinitionLevelListLoading } =
      this.props.goalDefinitionLevelList;
    const { teams, loading: teamsLoading } = this.props.teamList;
    const {
      pointRepartitions,
      loading: goalDefinitionPointRepartitionLoading,
    } = this.props.goalDefinitionPointRepartitionList;
    const {
      modes: repartitionModes,
      loading: goalDefinitionPointRepartitionModesLoading,
    } = this.props.goalDefinitionPointRepartitionModeList;
    const { periods, loading: periodsLoading } = this.props.periodList;

    const loading =
      configListLoading ||
      goalDefinitionDetailLoading ||
      goalDefinitionLevelListLoading ||
      goalDefinitionPointRepartitionLoading ||
      goalDefinitionPointRepartitionModesLoading ||
      periodsLoading ||
      !this.definitionInitialized ||
      !this.levelsInitialized;

    const { success } = this.props.goalDefinitionLevelListUpdate;

    if (success) {
      this.props.goalDefinitionLevelListUpdateActions.clearGoalDefinitionLevelListUpdate();
      toast.success(
        intl.formatMessage({ id: 'common.update_success_message' }),
      );
      this.props.history.goBack();
    }

    return (
      <div>
        {!loading &&
          configs &&
          definition &&
          levels &&
          teams &&
          pointRepartitions &&
          repartitionModes &&
          periods &&
          this.renderData()}
      </div>
    );
  }
}

const mapStateToProps = ({
  currentPeriodDetail,
  configList,
  goalDefinitionDetail,
  goalDefinitionLevelList,
  goalDefinitionLevelListUpdate,
  goalDefinitionPointRepartitionList,
  goalDefinitionPointRepartitionModeList,
  teamList,
  periodList,
}) => ({
  configList,
  goalDefinitionDetail,
  goalDefinitionLevelList,
  goalDefinitionLevelListUpdate,
  goalDefinitionPointRepartitionList,
  goalDefinitionPointRepartitionModeList,
  teamList,
  currentPeriodDetail,
  periodList,
});

const mapDispatchToProps = (dispatch) => ({
  configListActions: bindActionCreators(configListActions, dispatch),
  teamListActions: bindActionCreators(teamListActions, dispatch),
  goalDefinitionDetailActions: bindActionCreators(
    goalDefinitionDetailActions,
    dispatch,
  ),
  goalDefinitionLevelListActions: bindActionCreators(
    goalDefinitionLevelListActions,
    dispatch,
  ),
  goalDefinitionLevelListUpdateActions: bindActionCreators(
    goalDefinitionLevelListUpdateActions,
    dispatch,
  ),
  goalDefinitionPointRepartitionListActions: bindActionCreators(
    goalDefinitionPointRepartitionListActions,
    dispatch,
  ),
  goalDefinitionPointRepartitionModeListActions: bindActionCreators(
    goalDefinitionPointRepartitionModeListActions,
    dispatch,
  ),
  currentPeriodDetailActions: bindActionCreators(
    currentPeriodDetailActions,
    dispatch,
  ),
  periodListActions: bindActionCreators(periodListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(injectIntl(AdminGoalPointConfig)));
