import React from 'react'
import {withStyles} from "@mui/styles";
import {Link, Paper, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {mainKeyClear} from "../../../../features/main/slices";

const styles = {
    root: {
        padding: '8px 8px 0px 16px',
        marginBottom: 16,
        '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            boxShadow: 'none !important',
            width: 288,
            overflow: 'auto',
            position: 'relative',
            padding: '0.5rem 1rem',
            borderRadius: 16,
        },
    },
    hierarchyNodeLogo: {
        width: '3.5rem',
        height: '3.5rem',
    },
    hierarchyNodeInfo: {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.5rem',
    },
    hierarchyNodeDetails: {
        display: 'flex',
        flexDirection: 'column'
    },
    hierarchyNodeAction: {
        display: 'flex',
        justifyContent: 'center'
    }
}

const OperationDialog = ({ ...props }) => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const currentHierarchyNode = useSelector(state => state.mainKey.hierarchyNode);

    const toNodes = () => {
        history.push('/nodes')
        dispatch(mainKeyClear())
    }

    return (
        <div className="{props.classes.root}">
            { currentHierarchyNode && <paper>
                <div className="{props.classes.hierarchyNodeInfo}">
                    <div className="{props.classes.hierarchyNodeLogo}">
                        <img height="100%" width="100%" src="{currentHierarchyNode.logo}" alt="platform-logo">
                    </div>
                    <div className="{props.classes.hierarchyNodeDetails}">
                        <typography variant="{'h4'}" component="{'h4'}">{currentHierarchyNode.name}</typography>
                        <typography>{currentHierarchyNode.description}</typography>
                    </div>
                </div>

                <div className="{props.classes.hierarchyNodeAction}">
                    <link className="{'bold" small'}="" onClick="{toNodes}" style="{{" textDecoration:="" 'none'="" }}="">
                        {intl.formatMessage({id: 'spider.org.contract_selection.switch'})}
                    
                </div>
            </paper> }
        </div>
    )
}

export default withStyles(styles)(OperationDialog);
