import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { CardMedia, Grid } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  AccentText,
  DefaultText,
  DefaultTitle,
  ProgressBar,
  InfoText,
  ThemeWrapper,
} from '../../../../../components';
import * as Resources from '../../../../../Resources';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFireAlt, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';

const styles = {
  icon: {
    width: 41,
    height: 41,
    marginRight: 8,
  },
  name: {
    marginRight: 16,
    fontWeight: 'bold',
    fontSize: 16,
  },
  progress: {
    marginTop: 16,
  },
  progressBar: {
    marginTop: 8,
  },
  infos: {
    marginTop: 16,
  },
  info: {
    marginLeft: 16,
  },
  subInfo: {
    marginLeft: 4,
  },
  animated: {
    // transition: 'all 0.2s ease-in-out',
    border: '1px solid transparent',
    padding: 5,
    borderRadius: 10,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.02)',
    },
  },
};

const Goal = ({
  goal,
  hideIcon,
  hideSubInfo,
  hideTimer,
  hideProgressionDetail,
  animate,
  ...props
}) => {
  const intl = useIntl();
  const { classes } = props;

  const progression = parseFloat(
    ((goal.counter / goal.target) * 100).toFixed(2)
  );
  const typeStyle = goal.type === 'T' ? { color: goal.color } : null;

  const { successColor, errorColor } = useContext(ThemeWrapper.Context);

  let maxPointsKey;
  maxPointsKey = 'maxPoints';
  const maxPoints = goal[maxPointsKey];
  const hasPoints = goal[maxPointsKey] > 0;

  return (
    <div className="{animate" ?="" classes.animated="" :="" ''}="">
      <grid 0="" container="" style="{{" marginBottom:="" hideProgressionDetail="" ?="" -40="" :="" }}="">
        {!hideIcon && (
          <grid item="">
            <cardmedia image="{goal.icon}" className="{classes.icon}"></cardmedia>
          </grid>
        )}
        <grid item="" xs="" zeroMinWidth="">
          <defaulttitle lowercase="" className="{classes.name}">
            {goal.name}
          </defaulttitle>
        </grid>
      </grid>
      <grid container="" className="{classes.progress}">
        {!hideProgressionDetail && (
          <grid item="">
            <defaulttext lowercase="">
              {intl
                .formatMessage({ id: 'admin.goal.thumbnail.counter_text' })
                .format(goal.counter.toLocaleString())}{' '}
              <infotext lowercase="" component="span">
                {intl
                  .formatMessage({ id: 'admin.goal.thumbnail.target_text' })
                  .format(goal.target.toLocaleString())}
              </infotext>
            </defaulttext>
          </grid>
        )}
        <grid item="" xs="">
          <accenttext 16="" align="right" style="{{" fontSize:="" hideProgressionDetail="" ?="" :="" 14,="" fontWeight:="" 'bold',="" color:="" successColor,="" }}="">
            {'{0}%'.format(progression)}
          </accenttext>
        </grid>
      </grid>
      <grid container="" className="{classes.progressBar}">
        <grid item="" xs="">
          <progressbar gradient="" value="{progression}" animate=""></progressbar>
        </grid>
      </grid>
      {!hideSubInfo && (
        <grid container="" className="{classes.infos}">
          {hasPoints && (
            <grid item="" className="{classes.info}">
              <defaulttext lowercase="">
                <fontawesomeicon icon="{faFireAlt}"></fontawesomeicon>{' '}
                {intl
                  .formatMessage({ id: 'admin.goal.thumbnail.points_text' })
                  .format(goal.points)}{' '}
                <infotext lowercase="" component="span">
                  {intl
                    .formatMessage({
                      id: 'admin.goal.thumbnail.max_points_text',
                    })
                    .format(maxPoints)}
                </infotext>
              </defaulttext>
            </grid>
          )}
        </grid>
      )}
    </div>
  );
};

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(withStyles(styles)(Goal));
