import React from 'react';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import {
  ProgressButton,
  Switch,
  TextField,
} from '../../../../../../components';
import * as Resources from '../../../../../../Resources';

const AircallForm = ({ kpis, onUpdate, updating, ...props }) => {
  const intl = useIntl();
  const acGetCallsKpi = kpis.find((x) => x.code === 'AC-GET-CALLS');
  const [acGetCallsActivation, setAcGetCallsActivation] = React.useState(
    acGetCallsKpi.isActive,
  );

  function handleValidSubmit(model) {
    const duration = Number(model.acGetCallsDuration);
    const params = duration ? { duration } : null;
    const kpis = [
      {
        id: acGetCallsKpi.id,
        isActive: model.acGetCallsActivation,
        params: params,
      },
    ];
    if (onUpdate) onUpdate(kpis);
  }

  return (
    <div>
      <formsy onValidSubmit="{handleValidSubmit}">
        <grid container="" spacing="{2}">
          <grid item="" xs="{12}">
            <switch initial="{acGetCallsActivation}" label="{intl.formatMessage({" id:="" 'admin.aircall.form_ac_get_calls_activation',="" })}="" name="acGetCallsActivation" onChange="{setAcGetCallsActivation}"></switch>
          </grid>
          {acGetCallsActivation && (
            <grid item="" xs="{12}">
              <textfield fullWidth="" initial="{acGetCallsKpi.params.duration}" label="{intl.formatMessage({" id:="" 'admin.aircall.form_ac_get_calls_duration',="" })}="" name="acGetCallsDuration" required="" type="number" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}=""></textfield>
            </grid>
          )}
          <grid item="" xs="{12}">
            <progressbutton text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" loading="{updating}"></progressbutton>
          </grid>
        </grid>
      </formsy>
    </div>
  );
};

export default AircallForm;
