import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useIntl } from 'react-intl';
import { withWidth, isWidthDown, makeStyles } from '@material-ui/core';
import * as coachingItemListActions from '../../../../../services/CoachingItems/CoachingItemList/actions';
import * as teamCollaboratorListActions from '../../../../../services/Teams/TeamCollaboratorList/actions';
import * as userListActions from '../../../../../services/Users/UserList/actions';
import { Collaborator } from '../../../../../components';
import { CoachingItem } from '../../../../../scenes/CoachingList/scenes/CoachingList/components/CoachingItem';
import api from '../../../../../data/api/api';
import {
  DefaultText,
  DefaultTitle,
  EmptyState,
  HalfRoundTab,
  HalfRoundTabs,
  Loader,
} from '../../../../Common';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { ChallengeSearchBar } from '../../../../../scenes/Challenges/components';
import { Grid, withStyles } from '@material-ui/core';
import _ from 'lodash';

const styles = {
  emptyState: {
    width: '70%',
    margin: 'auto',
  },
  scrollWrapper: {
    overflow: 'overlay',

    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(199, 199, 199, 0)',
      borderRadius: 5,
    },

    '&::-webkit-scrollbar-track': {
      background: 'rgba(0, 0, 0, 0)',
    },
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      '&:vertical': {
        // position: 'absolute',
        // right: -10,
        width: 10,
      },
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(199, 199, 199, 1)',
        borderRadius: 5,
      },
    },
  },
};

const useStyles = makeStyles((theme) => {
  return {
    colorNumberCollaborator: {
      fontWeight: 'bold',
      textTransform: 'none',
      color: theme.palette.primary.main,
      paddingLeft: 10,
    },
    activeColorPrimary: {
      backgroundColor: theme.palette.primary.main,
    },
    activeColorBoard: {
      backgroundColor: theme.palette.primary.main,
      border: `solid 5px ${theme.palette.primary.main}`,
      filter: 'opacity(40%)',
    },
  };
});

const CoachingTabs = ({
  loadingCollaborator,
  setUrl,
  classes,
  width,
  ...props
}) => {
  const [coachingList, setCoachingList] = useState([]);
  const [tabIndex, SetTabIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);

  const [nextPage, setNextPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);

  const { items } = props.coachingItemList;
  const { account } = props.accountDetail;
  const intl = useIntl();
  const [notDesktop, setNotDesktop] = useState(window.innerWidth < 1280);

  const isCollaborator = account.role.code === 'C';
  const isAdministrator = account.role.code === 'A';
  const isSuperManager = account.role.code === 'S';
  const isManager = account.role.code === 'M';

  const isMobile = isWidthDown('xs', width);

  const themeClasses = useStyles();

  const colorsTabs = [
    '#728B9E',
    '#0F3D5C',
    themeClasses.activeColorPrimary,
    '#DFDFDF',
  ];
  const colorsBody = ['#dde3ea', '#a5b3c1', themeClasses.activeColorBoard];

  const loadNextUsersPage = () => {
    setUsersLoading(true);
    api.users
      .list(true, false, nextPage + 1, search, null, 'C', null, true)
      .then((response) => {
        setUsersLoading(false);
        const newUsers = _.get(response, 'data.users');
        setUsers([...users, ...newUsers]);
        setNextPage(nextPage + 1);
      });
  };

  useEffect(() => {
    if (selectedCollaborator) {
      setUrl(`/coaching/${selectedCollaborator.id}`);
      props.coachingItemListActions.getCoachingItemList(
        selectedCollaborator.id
      );
    } else {
      setUrl('/coaching');
    }
  }, [selectedCollaborator]);

  useEffect(() => {
    if (isCollaborator) {
      props.coachingItemListActions.getCoachingItemList(account.id);
    } else {
      setUsersLoading(true);
      api.users
        .list(true, false, 1, search, null, 'C', null, true)
        .then((response) => {
          setUsersLoading(false);
          const newUsers = _.get(response, 'data.users');
          setTotalUsers(_.get(response, 'data.filtered_total'));
          setUsers(newUsers);
        });
    }
  }, [search]);

  useEffect(() => {
    setCoachingList(items);
  }, [items]);
  const tabs = [
    coachingList?.filter((item) => item.state === 1),
    coachingList?.filter((item) => item.state === 2),
    coachingList?.filter((item) => item.state === 3),
  ];

  const handleChangeTab = (event, newIndex) => {
    SetTabIndex(newIndex);
  };

  const handleSearch = (newValue) => {
    setSearch(newValue);
  };

  const activeBackgroundColor = colorsBody[tabIndex];
  const activeBorderColor = `solid 5px ${colorsBody[tabIndex]}`;

  const numberCollaborator = `${totalUsers} ${intl
    .formatMessage({
      id: 'collaborator.title',
    })
    .toLowerCase()}`;

  const handleCollaboratorClick = (collaborator) => {
    setSelectedCollaborator(collaborator);
    setSearch('');
  };

  const handleResize = () => {
    setNotDesktop(window.innerWidth < 1280);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const tabLabels = [
    intl.formatMessage({
      id: 'coaching_list.state.pending',
    }),
    intl.formatMessage({
      id: 'coaching_list.state.in_progress',
    }),
    intl.formatMessage({ id: 'coaching_list.state.done' }),
  ];

  const TableWithTabs = () => (
    <>
      <halfroundtabs value="{tabIndex}" onChange="{handleChangeTab}" indicatorColor="{colorsTabs[tabIndex]}" textColor="primary">
        {tabLabels.map((label, index) => (
          <halfroundtab style="{{" backgroundColor:="" tabIndex="==" index="" ?="" colorsTabs[index]="" :="" '',="" color:="" 'white'="" }}="" classes="{{" root:="" ''="" label="{label}" key="{index}"></halfroundtab>
        ))}
      </halfroundtabs>
      <div style="{{" height:="" 310,="" maxHeight:="" notDesktop="" ?="" '350px'="" :="" '650px',="" overflowY:="" 'hidden'="" 'auto',="" overflowX:="" 'hidden',="" backgroundColor:="" activeBackgroundColor,="" border:="" activeBorderColor,="" }}="" className="{colorsBody[tabIndex]}">
        {tabs[tabIndex]?.length === 0 ? (
          <grid container="" style="{{" padding:="" 5,="" backgroundColor:="" 'white',="" width:="" '100%',="" height:="" }}="" alignItems="center">
            <grid item="" xs="">
              <emptystate rootClass="{classes.emptyState}"></emptystate>
            </grid>
          </grid>
        ) : (
          tabs[tabIndex]?.map((item, index) => (
            <div style="{{" padding:="" 5,="" }}="" key="{index}">
              <coachingitem item="{item}"></coachingitem>
            </div>
          ))
        )}
      </div>
    </>
  );

  return (
    <>
      {isCollaborator ? (
        <div 350="" style="{{" borderRadius:="" 20,="" height:="" }}="">
          {loadingCollaborator || !items ? (
            <grid container="" style="{{" height:="" '100%'="" }}="" alignItems="center" justifyContent="center">
              <grid item="">
                <loader centered=""></loader>
              </grid>
            </grid>
          ) : (
            <tablewithtabs></tablewithtabs>
          )}
        </div>
      ) : (
        <>
          {selectedCollaborator ? (
            <>
              <defaulttitle lowercase="" style="{{" color:="" 'rgb(15,111,222)',="" cursor:="" 'pointer'="" }}="" onClick="{()" ==""> setSelectedCollaborator(null)}
              >
                <grid container="" alignItems="center">
                  <grid item="">
                    <chevronleftroundedicon style="{{" fontSize:="" 30,="" marginBottom:="" -3,="" }}=""></chevronleftroundedicon>
                  </grid>
                  <grid item="">
                    {intl.formatMessage({
                      id: 'challenge.kpi_results.back_button',
                    })}
                  </grid>
                </grid>
              </defaulttitle>
              <tablewithtabs></tablewithtabs>
            </>
          ) : (
            <>
              <div style="{{" width:="" '100%'="" }}="">
                <challengesearchbar search="{search}" onChange="{handleSearch}" fullSize=""></challengesearchbar>
              </div>

              <div className="{isMobile" ?="" ''="" :="" classes.scrollWrapper}="" style="{{" borderRadius:="" 20,="" padding:="" 10,="" paddingRight:="" height:="" 280,="" maxHeight:="" notDesktop="" '350px'="" '650px',="" overflowX:="" 'hidden',="" overflowY:="" 'overlay',="" }}="">
                {users.length > 0 && (
                  <defaulttext className="{themeClasses.colorNumberCollaborator}">
                    {numberCollaborator}
                  </defaulttext>
                )}

                {users?.map((collaborator) => {
                  return (
                    <div 10="" style="{{" marginBottom:="" }}="" onClick="{()" ==""> handleCollaboratorClick(collaborator)}
                    >
                      <collaborator key="{collaborator.id}" collaborator="{collaborator}" wrapName=""></collaborator>
                    </div>
                  );
                })}
                {usersLoading && (
                  <grid container="" style="{{" minHeight:="" users.length=""> 0 ? 'auto' : '300px' }}
                    alignItems='center'
                    justifyContent='center'
                  >
                    <grid item="">
                      <loader centered=""></loader>
                    </grid>
                  </grid>
                )}
                {!usersLoading && users.length < totalUsers && (
                  <grid container="">
                    <grid item="" xs="{12}">
                      <defaulttitle lowercase="" style="{{" color:="" 'rgb(15,111,222)',="" cursor:="" 'pointer',="" }}="" onClick="{loadNextUsersPage}">
                        <grid container="" justifyContent="center">
                          <grid 18="" item="" style="{{" fontSize:="" }}="">
                            {intl.formatMessage({ id: 'common.see_more' })}
                          </grid>
                        </grid>
                      </defaulttitle>
                    </grid>
                  </grid>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({
  accountDetail,
  coachingItemList,
  teamCollaboratorList,
  userList,
}) => ({
  accountDetail,
  coachingItemList,
  teamCollaboratorList,
  userList,
  loadingCollaborator: coachingItemList.loading,
});
const mapDispatchToProps = (dispatch) => ({
  coachingItemListActions: bindActionCreators(
    coachingItemListActions,
    dispatch
  ),
  teamCollaboratorListActions: bindActionCreators(
    teamCollaboratorListActions,
    dispatch
  ),
  userListActions: bindActionCreators(userListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withWidth()(CoachingTabs)));
