import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
// import {} from './components'
import { GridLink, Collaborator } from '../../../../../../components';

const CollaboratorList = ({ colaborators, ...props }) => {
  return (
    <div>
      <grid container="" spacing="{2}">
        {colaborators.map((collaborator) => {
          return (
            <gridlink key="{collaborator.id}" item="" xs="{12}" sm="{4}" component="{Link}" to="{`/collaborators/${collaborator.id}/detail`}">
              <collaborator collaborator="{collaborator}"></collaborator>
            </gridlink>
          );
        })}
      </grid>
    </div>
  );
};

export default CollaboratorList;
