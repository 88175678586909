import React from 'react';
import {AppBar as MuiAppBar, Toolbar} from '@material-ui/core';

import LogoImg from '../../../../assets/img/logo.svg'

const AppBar = () => {
    return (
        <>
            <muiappbar position="{'relative'}" elevation="{0}" className="{'fullwidth'}">
                <toolbar>
                    <img height="{'48px'}" src="{LogoImg}" alt="{'logo'}/">
                </toolbar>
            </muiappbar>
        </>
    );
}

export default AppBar;
