import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import Formsy from 'formsy-react';
import { withStyles } from '@material-ui/core/styles';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Link,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GoalDuplicationDialog } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  AppBarSubTitle,
  Card,
  Loader,
  MainLayoutComponent,
  ProgressButton,
  Select,
  TextField,
  I18nWrapper,
  LanguageSelect,
  DefaultTitle,
  InfoText,
  Avatar,
  FileInput,
  Button,
  Checkbox,
  HiddenInput,
  Tooltip,
  BlueText,
  PasswordField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '../../../../components';
import { useIntl, injectIntl } from 'react-intl';
import * as roleListActions from '../../../../services/Roles/RoleList/actions';
import * as teamListActions from '../../../../services/Teams/TeamList/actions';
import * as userIdentifierDefinitionListActions from '../../../../services/Users/UserIdentifierDefinitionList/actions';
import * as userDetailActions from '../../../../services/Users/UserDetail/actions';
import * as userUpdateActions from '../../../../services/Users/UserUpdate/actions';
import * as userUpdateActivationActions from '../../../../services/Users/UserUpdateActivation/actions';
import * as userUpdatePasswordActions from '../../../../services/Users/UserUpdatePassword/actions';
import * as Resources from '../../../../Resources';
import '../../../../helpers/FormsyHelper';
import _ from 'lodash';

const styles = {
  panel: {
    backgroundColor: 'initial',
    borderRadius: 'initial',
    boxShadow: 'none',
  },
  panelSummary: {
    padding: 0,
  },
  panelDetails: {
    padding: 0,
  },
};

class AdminUserUpdate extends MainLayoutComponent {
  state = {
    role: null,
    deletePromptOpen: false,
  };

  constructor(props) {
    super(props);
    this.id = null;
    this.initialized = false;
    this.props.userUpdateActions.clearUserUpdate();
    this.props.userUpdateActivationActions.clearUserActivationUpdate();
    this.fileInputRef = React.createRef();
    this.forceResetPasswordForm = React.createRef();
  }

  componentDidMount() {
    const { intl, classes } = this.props;
    const { user } = this.props.userDetail;
    this.id = this.props.match.params.id;
    this.props.handleTitle(intl.formatMessage({ id: 'admin.title' }));
    this.props.handleSubHeader(
      <appbarsubtitle title="{intl.formatMessage({" id:="" 'admin.user.update.title'="" })}=""></appbarsubtitle>
    );
    this.props.handleMaxWidth('sm');
    this.props.activateReturn();

    this.props.roleListActions.getRoleList();
    this.props.teamListActions.getTeamList({ disableCollaborators: true });
    this.props.userDetailActions.getUserDetail(this.id);
    this.props.userIdentifierDefinitionListActions.getUserIdentifierDefinitionList();
  }

  componentWillReceiveProps(props) {
    const { intl, classes } = this.props;
    const { user } = props.userDetail;
    if (!this.initialized && user) {
      this.state.role = user.role.id;
      const activationButtonText = _.get(user, 'isActive')
        ? intl.formatMessage({ id: 'admin.user.update.disable_button' })
        : intl.formatMessage({ id: 'admin.user.update.enable_button' });
      this.props.handleButtons(
        <div>
          {user.isActive && (
            <tooltip title="{activationButtonText}">
              <iconbutton size="{'small'}" onClick="{()" ==""> this.setDeletePromptOpen(true)}
                className={classes.iconMargin}
              >
                <fontawesomeicon icon="{faTrash}"></fontawesomeicon>
              </iconbutton>
            </tooltip>
          )}
        </div>
      );
      this.initialized = true;
    }
  }

  handleValueChange = (name) => (value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleChangeActivationClick = () => {
    const { user } = this.props.userDetail;

    this.props.userUpdateActivationActions.updateUserActivation(user);
  };

  handleValidSubmit(model) {
    model.id = this.id;

    const payload = Object.assign({}, model, {
      identifiers: model.identifiers.map((identifier, index) => {
        return { order: index, value: identifier ? identifier : '' };
      }),
      force_reset_password: this.state.force_reset_password,
    });
    const newAccountPhoto = new FormData();
    if (model.photo) {
      newAccountPhoto.append('photo', model.photo, model.photo.name);
    }
    this.props.userUpdateActions.updateUser(payload, newAccountPhoto);
    if (model.password && model.password != '') {
      this.props.userUpdatePasswordActions.updateUserPassword(
        this.id,
        model.password
      );
    }
  }

  handleForcePasswordResetSubmit(model) {
    model.id = this.id;
    this.props.userUpdateActions.updateUser(model);
  }

  onFileInputClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.getElementsByTagName('input')[0].click();
    }
  };

  renderLoader() {
    return <loader centered=""></loader>;
  }

  setDeletePromptOpen = (value) => {
    this.setState({
      ...this.state,
      deletePromptOpen: value,
    });
  };

  setDuplicationOpen = (value) => {
    this.setState({
      ...this.state,
      duplicationOpen: value,
    });
  };

  renderData() {
    const { intl, classes } = this.props;
    const { loading: userUpdateLoading } = this.props.userUpdate;
    const { roles } = this.props.roleList;
    const { teams } = this.props.teamList;
    const { user } = this.props.userDetail;
    const { definitions } = this.props.userIdentifierList;
    const collaboratorRole = roles.find((r) => r.code == 'C');
    const managerRole = roles.find((r) => r.code == 'M');

    let selectableTeams = [];
    if (teams) {
      selectableTeams =
        this.state.role == collaboratorRole.id
          ? teams
          : this.state.role == managerRole.id
          ? teams.filter(
              (t) => (t.manager && t.manager.id == user.id) || !t.manager
            )
          : null;
    }
    const initialTeam =
      selectableTeams &&
      user.team &&
      selectableTeams.find((x) => x.id == user.team.id) != null
        ? user.team.id
        : null;
    const { loading: userUpdateActivationLoading } =
      this.props.userUpdateActivation;
    const activationButtonText = user.isActive
      ? intl.formatMessage({ id: 'admin.user.update.disable_button' })
      : intl.formatMessage({ id: 'admin.user.update.enable_button' });

    const photo = user.photo ? user.photo : '/assets/img/user/avatar.svg';

    const LanguageField = ({ initial }) => {
      const context = useContext(I18nWrapper.Context);
      return (
        <languageselect name="locale" label="{intl.formatMessage({" id:="" 'admin.user.locale'="" })}="" initial="{initial" ||="" context.locale}="" noCard=""></languageselect>
      );
    };

    return (
      <div>
        <grid container="" spacing="{4}">
          <grid item="" xs="{12}">
            <formsy onValidSubmit="{this.handleValidSubmit.bind(this)}">
              <grid container="" spacing="{4}">
                <grid item="" xs="{12}">
                  <grid container="" spacing="{1}">
                    <grid item="">
                      <defaulttitle>
                        {intl.formatMessage({
                          id: 'admin.user.information_title',
                        })}
                      </defaulttitle>
                    </grid>
                    <grid item="">
                      <card>
                        <grid container="" spacing="{1}" justify="space-between">
                          <grid item="" xs="{8}" container="" alignItems="center" spacing="{2}">
                            <grid item="">
                              <avatar src="{photo}" className="{classes.photo}" entityId="{user.id}" fallbackName="{user.fullname}" fontSize="{20}"></avatar>
                            </grid>
                            <grid item="">
                              <fileinput name="photo" accept="image/*"></fileinput>
                            </grid>
                          </grid>
                          <grid item="" xs="{4}">
                            <grid container="" justify="flex-end">
                              <grid item="">
                                <languagefield initial="{user.locale}"></languagefield>
                              </grid>
                            </grid>
                          </grid>
                          <grid item="" xs="{6}">
                            <textfield name="firstname" label="{intl.formatMessage({" id:="" 'admin.user.firstname',="" })}="" initial="{user.firstname}" fullWidth="" required="" lowercase="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}=""></textfield>
                          </grid>
                          <grid item="" xs="{6}">
                            <textfield name="lastname" label="{intl.formatMessage({" id:="" 'admin.user.lastname',="" })}="" initial="{user.lastname}" fullWidth="" required="" lowercase="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}=""></textfield>
                          </grid>
                          <grid item="" xs="{12}">
                            <textfield name="email" label="{intl.formatMessage({" id:="" 'admin.user.email',="" })}="" validations="isEmail" initial="{user.email}" fullWidth="" required="" lowercase="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" isEmail:="" 'common.form.email_error',="" }}=""></textfield>
                          </grid>
                          <grid item="" xs="{6}">
                            <select name="role" label="{intl.formatMessage({" id:="" 'admin.user.type',="" })}="" options="{roles}" optionValueName="id" optionTextName="name" initial="{user.role" ?="" user.role.id="" :="" null}="" fullWidth="" required="" onChange="{this.handleValueChange('role').bind(" this="" )}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}=""></select>
                          </grid>
                          <grid item="" xs="{6}">
                            <select name="team" label="{intl.formatMessage({" id:="" 'admin.user.team',="" })}="" options="{selectableTeams}" optionValueName="id" optionTextName="name" initial="{initialTeam}" fullWidth="" updateInitial=""></select>
                          </grid>
                          <grid item="" xs="{12}">
                            <link to="/" onClick="{()" ==""> this.setDuplicationOpen(true)}
                              style={{
                                textDecoration: 'none',
                                color: 'rgb(15,111,222)',
                                fontSize: 16,
                                cursor: 'pointer',
                              }}
                            >
                              {intl.formatMessage({
                                id: 'admin.goal.duplication.dialog_title',
                              })}
                            
                            {this.state.duplicationOpen && (
                              <div>
                                <goalduplicationdialog user="{user}" open="{this.state.duplicationOpen}" setOpen="{this.setDuplicationOpen}"></goalduplicationdialog>
                              </div>
                            )}
                            <grid>
                              <checkbox name="is_fictive" label="{intl.formatMessage({" id:="" 'account.fictional_user',="" })}="" value="{user.is_fictive" ||="" false}=""></checkbox>
                            </grid>
                          </grid>
                        </grid>
                      </card>
                    </grid>
                  </grid>
                </grid>

                <grid 0="" item="" xs="{12}" style="{{" paddingTop:="" 0,="" paddingBottom:="" }}="">
                  <expansionpanel className="{classes.panel}">
                    <expansionpanelsummary expandIcon="{<ExpandMoreIcon"></expansionpanelsummary>}
                      className={classes.panelSummary}
                    >
                      <defaulttitle>
                        {intl.formatMessage({ id: 'admin.user.identifiers' })}
                      </defaulttitle>
                    
                    <expansionpaneldetails className="{classes.panelDetails}">
                      <card>
                        <grid container="" spacing="{4}">
                          {[...Array(5).keys()].map((order) => {
                            const definition = definitions.find(
                              (d) => d.order == order
                            );
                            return (
                              <grid item="" xs="{6}" key="{`identifier_${order}`}">
                                <textfield name="{`identifiers[${order}]`}" label="{" _.get(definition,="" `name`)="" ||="" intl="" .formatMessage({="" id:="" 'admin.user.identifier',="" })="" .format(order="" +="" 1)="" }="" initial="{_.get(" user,="" `identifiers[${order}].value`="" )}="" fullWidth="" lowercase=""></textfield>
                              </grid>
                            );
                          })}
                        </grid>
                      </card>
                    </expansionpaneldetails>
                  </expansionpanel>
                </grid>

                <grid item="" xs="{12}">
                  <grid container="" spacing="{1}">
                    <grid item="">
                      <defaulttitle>
                        {intl.formatMessage({
                          id: 'admin.user.update.password_title',
                        })}
                      </defaulttitle>
                    </grid>
                    <grid item="" xs="{12}">
                      <card>
                        <grid container="" spacing="{2}">
                          <grid item="" xs="{12}">
                            <infotext lowercase="">
                              {intl.formatMessage({
                                id: 'admin.user.password_info',
                              })}
                            </infotext>
                          </grid>
                          <grid item="" xs="{6}">
                            <passwordfield type="password" name="password" onChange="{this.handleValueChange('password').bind(" this="" )}="" label="{intl.formatMessage({" id:="" 'admin.user.password',="" })}="" fullWidth="" lowercase="" validations="{{" hasUppercaseCharacter:="" true,="" hasLowercaseCharacter:="" hasSpecialCharacter:="" hasMoreCharactersThan:="" 8,="" hasDigitCharacter:="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" 'common.form.has_uppercase_character',="" 'common.form.has_lowercase_character',="" 'common.form.has_special_character',="" intl="" .formatMessage({="" 'common.form.has_more_characters_than',="" })="" .format(8),="" 'common.form.has_digit_character',=""></passwordfield>
                          </grid>
                          <grid item="" xs="{6}">
                            <passwordfield type="password" name="paswwordConfirm" label="{intl.formatMessage({" id:="" 'admin.user.password_confirm',="" })}="" fullWidth="" lowercase="" validations="{" this.state.password="" ?="" 'equalsField:password'="" :="" null="" }="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" equalsField:="" 'common.form.password_not_match_error',="" }}=""></passwordfield>
                          </grid>
                          <grid item="" xs="{12}">
                            <link to="/" onClick="{()" =="">
                                _.get(
                                  this.forceResetPasswordForm,
                                  'current.submit'
                                )()
                              }
                              style={{
                                textDecoration: 'none',
                                color: 'rgb(15,111,222)',
                                fontSize: 16,
                                cursor: 'pointer',
                              }}
                            >
                              {intl.formatMessage({
                                id: 'admin.user.force_reset_password',
                              })}
                            
                            <span style="{{" marginLeft:="" 5,="" lineHeight:="" 1,="" verticalAlign:="" 'middle',="" }}="">
                              <tooltip title="{intl.formatMessage({" id:="" 'admin.user.force_reset_password_info',="" })}="" placement="{'right'}">
                                <bluetext style="{{" width:="" 'fit-content'="" }}="" component="{'span'}">
                                  <fontawesomeicon icon="{faInfoCircle}"></fontawesomeicon>
                                </bluetext>
                              </tooltip>
                            </span>
                          </grid>
                        </grid>
                      </card>
                    </grid>
                  </grid>
                </grid>

                <grid item="" xs="{12}">
                  <div>
                    <grid container="" justify="flex-end">
                      <grid item="">
                        <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" centered="" loading="{userUpdateLoading}"></progressbutton>
                      </grid>
                    </grid>
                  </div>
                </grid>
              </grid>
            </formsy>

            <formsy ref="{this.forceResetPasswordForm}" onValidSubmit="{this.handleForcePasswordResetSubmit.bind(this)}">
              <hiddeninput name="force_reset_password" value="{true}"></hiddeninput>
            </formsy>
          </grid>
        </grid>
      </div>
    );
  }

  render() {
    const { roles, loading: roleListLoading } = this.props.roleList;
    const { teams, loading: teamListLoading } = this.props.teamList;
    const { user, loading: userDetail } = this.props.userDetail;
    const { definitions, loading: userIdentifierListLoading } =
      this.props.userIdentifierList;
    const { success: userUpdateSuccess, hasError: userUpdateError } =
      this.props.userUpdate;
    const { success: userUpdateActivationSuccess, loading: activationLoading } =
      this.props.userUpdateActivation;
    const loading = roleListLoading || userDetail || userIdentifierListLoading;
    const { intl, classes } = this.props;

    if (userUpdateSuccess || userUpdateActivationSuccess) {
      this.props.userUpdateActions.clearUserUpdate();
      this.props.userUpdateActivationActions.clearUserActivationUpdate();
      this.props.history.goBack();
      toast.success(intl.formatMessage({ id: 'admin.user.update.success' }));
    }

    if (userUpdateError) {
      this.props.userUpdateActions.clearUserUpdate();
      this.props.userUpdateActivationActions.clearUserActivationUpdate();
      toast.error(intl.formatMessage({ id: 'admin.user.update.error' }));
    }

    return (
      <div>
        {loading && this.renderLoader()}
        {!loading && roles && user && definitions && this.renderData()}
        <dialog open="{this.state.deletePromptOpen}" onClose="{()" ==""> this.setDeletePromptOpen(false)}
        >
          <dialogtitle>
            {intl.formatMessage({ id: 'coaching_list.delete_prompt' })}
          </dialogtitle>
          <dialogcontent>
            {intl.formatMessage({ id: 'coaching_list.delete_prompt2' })}
          </dialogcontent>
          <dialogactions>
            <button onClick="{()" ==""> this.setDeletePromptOpen(false)}
              color='secondary'
            >
              {intl.formatMessage({ id: 'common.no' })}
            </button>
            <progressbutton type="button" text="{intl.formatMessage({" id:="" 'common.yes'="" })}="" onClick="{()" ==""> this.handleChangeActivationClick()}
              loading={activationLoading}
            />
          </progressbutton></dialogactions>
        </dialog>
      </div>
    );
  }
}

const mapStateToProps = ({
  roleList,
  teamList,
  userIdentifierList,
  userDetail,
  userUpdate,
  userUpdateActivation,
}) => ({
  roleList,
  teamList,
  userIdentifierList,
  userDetail,
  userUpdate,
  userUpdateActivation,
});

const mapDispatchToProps = (dispatch) => ({
  roleListActions: bindActionCreators(roleListActions, dispatch),
  teamListActions: bindActionCreators(teamListActions, dispatch),
  userIdentifierDefinitionListActions: bindActionCreators(
    userIdentifierDefinitionListActions,
    dispatch
  ),
  userDetailActions: bindActionCreators(userDetailActions, dispatch),
  userUpdateActions: bindActionCreators(userUpdateActions, dispatch),
  userUpdateActivationActions: bindActionCreators(
    userUpdateActivationActions,
    dispatch
  ),
  userUpdatePasswordActions: bindActionCreators(
    userUpdatePasswordActions,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles)(AdminUserUpdate)));
