import { Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Switch, TextField } from '../../../../../../components';
import Formsy from 'formsy-react';

const EmbedWidget = () => {
  const [embedId, setEmbedId] = useState(
    // '0253acbe-c25a-489a-8c32-cb6c3b3a99fd'
    // '3545a3f9-ed27-4671-8669-a1ae7ce8f0ff'
    'd8fc1b41-8c6a-4eed-a677-ca3cd51a73a5'
  );
  // const [useSize, setUseSize] = useState(false);
  const containerRef = useRef(null);
  const TOKEN = '_0XBPWQQ_2e1f720c-37fd-4b06-9fd0-630ac0082bae';

  const handleInputChange = (value) => {
    setEmbedId(value);
  };

  const handleAddEmbed = () => {
    if (embedId && containerRef.current) {
      const scriptSrc = `https://objow.toucantoco.com/scripts/embedLauncher.js?id=${embedId}&token=${TOKEN}`;
      // if (useSize) {
      //   scriptSrc += '&height=190px&width=320px';
      // }
      const script = document.createElement('script');
      script.src = scriptSrc;
      script.async = true;
      script.type = 'text/javascript';

      containerRef.current.innerHTML = '';
      containerRef.current.appendChild(script);
    }
  };
  useEffect(() => {
    handleAddEmbed();
    console.log('Call Embed');
  }, [embedId]);

  return (
    <>
      <formsy onSubmit="{handleAddEmbed}">
        <grid container="" spacing="{3}">
          <grid item="" xs="{12}">
            <textfield label="Embed ID" placeholder="enter embed" fullwidth="" onChange="{handleInputChange}" name="embedId" lowercase=""></textfield>
          </grid>
          {/* <grid item="" xs="{12}">
            <switch label="Utiliser les options de taille ?" onChange="{(value)" ==""> setUseSize(value)}
              initial={useSize}
              name='useSize'
            />
          </switch></grid> */}
          <grid item="" xs="{12}">
            <button type="submit">Add Embed</button>
          </grid>
          <grid item="" xs="{12}">
            <div ref="{containerRef}" id="embed-container" style="{{" height:="" '100vh'="" }}=""></div>
          </grid>
        </grid>
      </formsy>
    </>
  );
};

export default EmbedWidget;
