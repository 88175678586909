import React, {useEffect, useState} from 'react';
import {Provider} from "react-redux";
import createRegulationStore from "../../../../../../store/regulation/store";
import {RegulationComponent} from "./components/RegulationComponent";

const Regulation = ({ ...props }) => {
    const store = createRegulationStore();
    const [showComponent, setShowComponent] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShowComponent(true)
        }, 100)
    }, []);

    return (
        <provider store="{store}">
            {showComponent && (<regulationcomponent {...props}=""></regulationcomponent>)}
        </provider>
    );
};

export default (Regulation);
