import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CardMedia, Grid } from '@material-ui/core';
import {
  BoldSpan,
  Button,
  DefaultText,
  Dialog,
  DialogActions,
  DialogContent,
} from '../../../../components';
import { useIntl } from 'react-intl';
import * as Resources from '../../../../Resources';
import * as shoppingCartActions from '../../../../services/ShoppingCart/actions';

const ShoppingCartAddingConfirmation = ({ ...props }) => {
  const intl = useIntl();
  const { lastItem } = props.shoppingCart;

  function handleShoppingCartClick() {
    props.shoppingCartActions.clearLastItem();
    props.history.push('/rewards/shopping-cart');
  }

  function handleCloseClick() {
    props.shoppingCartActions.clearLastItem();
  }

  return (
    <div>
      <dialog open="{lastItem}">
        <dialogcontent>
          <grid container="" spacing="{2}">
            <grid item="" xs="{12}">
              <defaulttext>
                {intl.formatMessage({
                  id: 'reward.shopping_cart.adding_confirmation_message',
                })}
              </defaulttext>
              <defaulttext>
                <boldspan>{lastItem ? lastItem.reward.name : ''}</boldspan>
              </defaulttext>
            </grid>
            <grid item="" xs="{12}">
              <cardmedia 200="" image="{" lastItem="" ?="" lastItem.reward.customImage="" :="" lastItem.reward.image.path="" ''="" }="" style="{{" height:="" }}=""></cardmedia>
            </grid>
          </grid>
        </dialogcontent>
        <dialogactions>
          <grid container="" spacing="{2}" justify="flex-end">
            <grid item="">
              <button onClick="{handleShoppingCartClick}">
                {intl.formatMessage({
                  id: 'reward.shopping_cart.adding_confirmation_shopping_cart_button',
                })}
              </button>
            </grid>
            <grid item="">
              <button onClick="{handleCloseClick}">
                {intl.formatMessage({
                  id: 'reward.shopping_cart.adding_confirmation_close_button',
                })}
              </button>
            </grid>
          </grid>
        </dialogactions>
      </dialog>
    </div>
  );
};

const mapStateToProps = ({ shoppingCart }) => ({
  shoppingCart,
});

const mapDispatchToProps = (dispatch) => ({
  shoppingCartActions: bindActionCreators(shoppingCartActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ShoppingCartAddingConfirmation));
