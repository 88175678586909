import React from 'react';
import {
  IconButton,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useParams } from 'react-router-dom';
import { neutralColors, themeColors } from '../../../../../../../themes/colors';
import { useIntl } from 'react-intl';
import { Home2, UserOctagon, Cup, Setting2 } from 'iconsax-react';

const useStyles = makeStyles((theme) => ({
  expandedAppBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: themeColors.primaryContrastText,
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  toolbarContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  menuItems: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0px 16px',
  },
  iconButton: {
    backgroundColor: themeColors.primaryContrastText,
    color: themeColors.primaryRegular,
  },
  separator: {
    borderBottom: `1px solid ${neutralColors.neutral200}`,
    margin: '0px 32px',
  },
}));

const MobileMenu = ({
  handleDrawerToggle,
  selectedIndex,
  handleListeItemClick,
  LogoImg,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { contract } = useParams();

  const menuItems = [
    {
      text: intl.formatMessage({ id: 'spider.sidebar.home' }),
      icon: <home2></home2>,
      src: `/nodes/${contract}/home`,
    },
    {
      text: intl.formatMessage({ id: 'spider.sidebar.participants' }),
      icon: <useroctagon></useroctagon>,
      src: `/nodes/${contract}/users`,
    },
    {
      text: intl.formatMessage({ id: 'spider.sidebar.points' }),
      icon: <cup></cup>,
      src: `/nodes/${contract}/points`,
    },
    {
      text: intl.formatMessage({ id: 'spider.sidebar.administration' }),
      icon: <setting2></setting2>,
      src: `/nodes/${contract}/administration/personnalisation`,
    },
  ];

  return (
    <div className="{classes.expandedAppBar}">
      <toolbar style="{{" justifyContent:="" 'space-between'="" }}="">
        <img height="48px" src="{LogoImg}" alt="logo">
        <iconbutton aria-label="close drawer" onClick="{handleDrawerToggle}" className="{classes.iconButton}">
          <closeicon></closeicon>
        </iconbutton>
      </toolbar>
      <div className="{classes.separator}"></div>
      <div className="{classes.menuItems}">
        <list>
          {menuItems.map((item) => (
            <listitem button="" key="{item.text}" selected="{selectedIndex" =="=" item.src}="" component="{Link}" to="{item.src}" onClick="{(event)"> handleListeItemClick(event, item.src)}
            >
              <listitemicon>{item.icon}</listitemicon>
              <listitemtext primary="{item.text}"></listitemtext>
            </listitem>
          ))}
        </list>
      </div>
    </div>
  );
};

export default MobileMenu;
