import React from 'react';
import ObjowRoutes from '../Routes';
import SpiderRoutes from '../Spider/Routes';
import {useEdenredTheme} from '../Spider/themes';

export const objow = {
    routes: <objowroutes></objowroutes>,
}

export const edenred = {
    routes: <spiderroutes></spiderroutes>,
    useTheme: useEdenredTheme
}
