import React from 'react';
import { AvatarGroup } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { Avatar } from '../../../../../../components';

const useStyles = makeStyles({
  avatar: {
    border: '2px solid #ffffff',
    width: 30,
    height: 30,
    fontSize: 12,
  },
});

const CollaboratorList = ({ collaborators, ...props }) => {
  const classes = useStyles();

  return (
    <div>
      <avatargroup max="{6}" classes="{{" avatar:="" classes.avatar="" }}="">
        {collaborators.map((collaborator) => {
          return (
            <avatar key="{collaborator.id}" fontSize="{12}" src="{collaborator.photo}" entityId="{collaborator.id}" fallbackName="{collaborator.fullname}"></avatar>
          );
        })}
      </avatargroup>
    </div>
  );
};

export default CollaboratorList;
