import React, { Component } from 'react';
import lottie from 'lottie-web';
import * as animationData from '../../../../../assets/lottie/planet.json';

class PlanetAnimation extends Component {
  componentDidMount() {
    const animation = lottie.loadAnimation({
      container: document.getElementById('planet-animation'),
      renderer: 'svg',
      loop: true,
      autoplay: false,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
      },
    });
    animation.addEventListener('DOMLoaded', function () {
      animation.play();
    });
  }

  render() {
    return (
      <div 100="" id="planet-animation" style="{{" width:="" 150,="" height:="" }}="" ref="{(ref)" ==""> (this.ref = ref)}
      ></div>
    );
  }
}

export default PlanetAnimation;
