import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Goal } from './components';
import {
  DefaultTitle,
  DatePicker,
  Card,
  IconButton as MenuIconButton,
  DefaultText,
} from '../../../../../../components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import * as Resources from '../../../../../../Resources';
import { uuidv4 } from '../../../../../../helpers/UUIDHelper';

const Goals = ({
  categories,
  goals,
  kpis,
  challengeTypeCode,
  goalAdding,
  onGoalAdded,
  onEndChange,
  onStartChange,
  start,
  end,
  period,
  handleAddGoal,
  setNewKpiOpen,
  awardType,
  units,
  ...props
}) => {
  const intl = useIntl();

  const [currentGoals, setCurrentGoals] = React.useState(
    goals
      ? goals.map((x) => ({
          key: uuidv4(),
          category: x.kpi && x.kpi.category ? x.kpi.category.id : null,
          kpi: x.kpi,
          goalName: x.name,
          goalDescription: x.description,
          target: x.target,
          points: x.points,
          custom: x.custom,
          collaboratorEditable: x.collaboratorEditable,
          unit: x.unit,
        }))
      : [
          {
            key: uuidv4(),
            category: null,
            kpi: null,
            goalName: null,
            target: null,
            points: null,
            custom: false,
          },
        ]
  );

  const deletionDisabled = currentGoals.length === 1;

  const today = new Date();
  const startMinDate = new Date(today.getFullYear(), 0, 1);
  const startMaxDate = end ? end : period.end.toDate2();
  const endMinDate = start ? start : today;

  useEffect(() => {
    if (goalAdding) {
      setCurrentGoals((goals) => [
        ...goals,
        {
          key: uuidv4(),
          category: null,
          kpi: null,
          goalName: null,
          target: null,
          points: null,
        },
      ]);
      onGoalAdded();
    }
  }, [goalAdding]);

  function handleRemoveGoalClick(key) {
    setCurrentGoals((x) => x.filter((y) => y.key != key));
  }
  return (
    <div>
      <grid container="" spacing="{2}" direction="column">
        <grid item="">
          <card>
            <grid container="" spacing="{2}" justify="center" direction="row">
              <grid item="" xs="{6}" sm="{3}">
                <datepicker clearable="" format="dd/MM/yyyy" fullWidth="" initial="{start}" label="{intl.formatMessage({" id:="" 'challenge.form.info_start_label',="" })}="" maxDate="{startMaxDate}" minDate="{startMinDate}" name="start" required="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}="" onChange="{onStartChange}"></datepicker>
              </grid>
              <grid item="" xs="{6}" sm="{3}">
                <datepicker clearable="" format="dd/MM/yyyy" fullWidth="" initial="{end}" label="{intl.formatMessage({" id:="" 'challenge.form.info_end_label',="" })}="" maxDate="{period.end.toDate2()}" minDate="{endMinDate}" name="end" required="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}="" onChange="{onEndChange}"></datepicker>
              </grid>
            </grid>
          </card>
        </grid>
        {start && end && (
          <grid item="">
            <grid container="" spacing="{1}">
              <grid item="" xs="{12}">
                <grid container="" spacing="{1}">
                  <grid item="">
                    <defaulttitle isContrast="">
                      {intl.formatMessage({ id: 'challenge.form.goal_area' })}
                    </defaulttitle>
                  </grid>
                  <grid item="">
                    <defaulttitle>
                      <menuiconbutton size="{'small'}" onClick="{()" ==""> setNewKpiOpen(true)}
                        style={{
                          marginTop: '-2px',
                          color: '#555',
                          fontSize: '18px',
                        }}
                      >
                        <fontawesomeicon icon="{faPlus}" style="{{" color:="" '#00E58D'="" }}=""></fontawesomeicon>
                      </menuiconbutton>
                    </defaulttitle>
                  </grid>
                </grid>
              </grid>

              <grid item="" xs="{12}">
                <grid item="" container="" spacing="{2}">
                  {currentGoals.map((goal, index) => {
                    return (
                      <goal key="{goal.key}" categories="{categories}" deletionDisabled="{deletionDisabled}" challengeTypeCode="{challengeTypeCode}" goal="{goal}" index="{index}" kpis="{kpis}" units="{units}" onRemoveClick="{()" ==""> handleRemoveGoalClick(goal.key)}
                        setNewKpiOpen={setNewKpiOpen}
                        awardType={awardType}
                      />
                    );
                  })}
                  <grid item="" xs="{12}" sm="{6}">
                    <div onClick="{handleAddGoal}" style="{{" cursor:="" 'pointer'="" }}="">
                      <card>
                        <defaulttext style="{{" textAlign:="" 'center',="" fontSize:="" 15,="" color:="" '#00E58D',="" }}="" lowercase="">
                          {intl.formatMessage({
                            id: 'challenge.form.goals.add_button',
                          })}
                        </defaulttext>
                      </card>
                    </div>
                  </grid>
                </goal></grid>
              </grid>
            </grid>
          </grid>
        )}
      </grid>
    </div>
  );
};

export default Goals;
