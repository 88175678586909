import React, { useEffect, useState } from "react";
import Sketch from "@uiw/react-color-sketch";

const ColorPicker = ({ initialHex, name, onChange }) => {
  const [color, setColor] = useState(initialHex);

  useEffect(() => {
    setColor(initialHex);
  }, []);

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
    onChange(newColor.hex);
  };

  return (
    <>
      <sketch color="{color}" disableAlpha="{true}" name="{name}" onChange="{handleColorChange}"></sketch>
      <div 10="" style="{{" textAlign:="" "center",="" marginTop:="" }}="">
        <div style="{{" backgroundColor:="" color,="" width:="" 30,="" height:="" display:="" "inline-block",="" }}=""></div>
        <span 10="" style="{{" marginLeft:="" }}="">{color}</span>
      </div>
    </>
  );
};

export default ColorPicker;
