import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import { Switch, ProgressButton } from '../../../../../../components';
import * as configListUpdateActions from '../../../../../../services/Configs/ConfigListUpdate/actions';
import * as configListActions from '../../../../../../services/Configs/ConfigList/actions';
import '../../../../../../helpers/StringHelper';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const GoalSettings = ({ ...props }) => {
  const intl = useIntl();
  const { configs, loading } = props.configList;
  const { success, error } = props.configListUpdate;
  const [formState, setFormState] = useState({
    AFC: configs.find((x) => x.code === 'AFC').value.toBoolean(),
  });
  const history = useHistory();

  const handleChange = (code, value) => {
    setFormState((prev) => ({ ...prev, [code]: value }));
  };

  const handleSubmit = () => {
    const configsToUpdate = [
      {
        id: configs.find((x) => x.code === 'AFC').id,
        value: formState.AFC,
      },
    ];
    props.configListUpdateActions.updateConfigList(configsToUpdate);
  };

  useEffect(() => {
    if (success) {
      props.configListUpdateActions.clearConfigListUpdate();
      toast.success(
        intl.formatMessage({ id: 'common.update_success_message' })
      );
      history.push('/admin');
    }
    if (error) {
      toast.error(intl.formatMessage({ id: 'common.update_error_message' }));
    }
  }, [success, error]);

  return (
    <dev>
      <formsy onValidSubmit="{handleSubmit}">
        <switch isContrast="" name="activation_advice_from_coach" label="{intl.formatMessage({" id:="" 'admin.goal.settings.activation_advice_from_coach',="" })}="" initial="{formState.AFC}" onChange="{(value)" ==""> handleChange('AFC', value)}
          disabled={loading}
        />
        <grid item="" xs="{12}">
          <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" centered="" loading="{loading}"></progressbutton>
        </grid>
      </switch></formsy>
    </dev>
  );
};

const mapStateToProps = ({ configList, configListUpdate }) => ({
  configList,
  configListUpdate,
});

const mapDispatchToProps = (dispatch) => ({
  configListActions: bindActionCreators(configListActions, dispatch),
  configListUpdateActions: bindActionCreators(
    configListUpdateActions,
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(GoalSettings);
