import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CardMedia, Grid, Hidden, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faBalanceScale,
  faCalendarAlt,
  faFolderOpen,
  faInfoCircle,
  faTimes,
  faPencilAlt as faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { AdviceList, LiveStatus, ReadonlyAdviceList } from './components';
import {
  AnimationController,
  BlueText,
  Card,
  DefaultText,
  DefaultTitle,
  InfoText,
  Linkify,
  Table,
  TableBody,
  TableCell,
  TableChip,
  TableRow,
  Tooltip,
  RichText,
  Collaborator,
  KpiResultUpdate,
  Dialog,
  IconButton as MenuIconButton,
} from '../../../../components';
import * as configListActions from '../../../../services/Configs/ConfigList/actions';
import * as collaboratorDataListActions from '../../../../services/CollaboratorData/CollaboratorDataList/actions';
import * as collaboratorInputListActions from '../../../../services/CollaboratorInput/CollaboratorInputList/actions';
import { useIntl } from 'react-intl';
import { getDifferenceWithToday } from '../../../../helpers/DateHelper';
import _ from 'lodash';

const styles = {
  icon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  kpiResultDialog: {
    width: 'auto',

    maxWidth: '100%',
    overflow: 'visible',
  },
  dialogCloseIcon: {
    position: 'absolute',
    color: 'white',
    top: -10,
    right: -10,
    width: 25,
    height: 25,
    fontSize: 20,
    zIndex: 100,
    background: '#00E58D',
    '&:hover': {
      background: '#00E58D',
      color: 'white',
    },
  },
  link: {
    fontSize: 14,
    cursor: 'pointer',
    color: 'rgb(15,111,222)',
    opacity: 1,
  },
};

const GoalIndication = ({
  goal,
  type,
  customRepartitions,
  classes,
  ...props
}) => {
  const intl = useIntl();
  const { account } = props.accountDetail;
  const difference = getDifferenceWithToday(goal.end);
  const isTeamManager =
    (account.team && account.team.id == goal.teamId) ||
    (account.team_group &&
      account.team_group.allTeamIds.indexOf(goal.teamId) >= 0);
  const canEdit =
    ((['M', 'S'].indexOf(account.role.code) >= 0 && isTeamManager) ||
      account.role.code == 'A') &&
    difference <= 0;
  const hasLevels = goal.levels && goal.levels.length > 0;
  const lastUpdate = goal.last_sync ? goal.last_sync.toDate() : null;
  const lastUpdateTime = lastUpdate
    ? `${lastUpdate.getHours() < 10 ? '0' : ''}${lastUpdate.getHours()}:${
        lastUpdate.getMinutes() < 10 ? '0' : ''
      }${lastUpdate.getMinutes()}`
    : null;
  const [editResultsOpen, setEditResultsOpen] = useState(false);

  const beginningOfLastMonth = new Date();
  beginningOfLastMonth.setMonth(beginningOfLastMonth.getMonth() - 1);
  beginningOfLastMonth.setDate(1);

  const closeKpiModal = () => {
    props.collaboratorDataListActions.getCollaboratorDataListClear();
    props.collaboratorInputListActions.getCollaboratorInputListClear();
    setEditResultsOpen(false);
  };

  const openKpiModal = () => {
    props.collaboratorDataListActions.getCollaboratorDataListClear();
    setEditResultsOpen(true);
  };

  const { configs, loading: configListLoading } = props.configList;
  const AdviceFromCoach = configs
    ? configs.find((x) => x.code === 'AFC').value.toBoolean()
    : null;

  const customRepartitionsTooltip = (
    <grid container="" spacing="{1}" direction="column">
      {customRepartitions &&
        customRepartitions.map((repartition) => (
          <grid item="">
            <collaborator collaborator="{repartition.collaborator}"></collaborator>
          </grid>
        ))}
    </grid>
  );

  return (
    <div>
      <grid container="" spacing="{2}">
        {lastUpdate && (
          <grid 0="" item="" xs="{12}" style="{{" paddingTop:="" }}="">
            <defaulttext isContrast="" lowercase="" style="{{" fontSize:="" 12,="" opacity:="" 0.8="" }}="">
              {intl
                .formatMessage({ id: 'challenge.condition.last_update' })
                .format(lastUpdate.toLocaleDateString(), lastUpdateTime)}
              <span style="{{" fontWeight:="" 'bold'="" }}="">
                {intl
                  .formatMessage({
                    id: 'challenge.condition.last_update_time',
                  })
                  .format(lastUpdate.toLocaleDateString(), lastUpdateTime)}
              </span>
            </defaulttext>
          </grid>
        )}
        {(hasLevels ||
          (customRepartitions && customRepartitions.length > 0)) && (
          <grid item="" xs="{12}">
            <grid container="" spacing="{1}">
              <grid item="" xs="{12}">
                <defaulttitle isContrast="">
                  {intl.formatMessage({
                    id: 'admin.goal.indication.level_area',
                  })}
                </defaulttitle>
              </grid>

              <grid item="" xs="{12}">
                <card marginDisabled="">
                  <grid container="" direction="column" spacing="{2}">
                    <grid item="">
                      <grid container="" justify="space-between">
                        <grid item="">
                          <table backgroundDisabled="">
                            <tablebody>
                              {goal.levels.map((level, index) => {
                                return (
                                  <tablerow key="{level.id}">
                                    <tablecell>
                                      <tablechip label="{index" +="" 1}=""></tablechip>
                                    </tablecell>
                                    <tablecell>
                                      <defaulttext noWrap="">
                                        {'{0}%'.format(
                                          parseFloat(
                                            (level.percentage * 100).toFixed(2)
                                          )
                                        )}
                                      </defaulttext>
                                    </tablecell>
                                    <tablecell>
                                      <fontawesomeicon icon="{faAngleRight}"></fontawesomeicon>
                                    </tablecell>
                                    <tablecell align="right">
                                      <defaulttext noWrap="">
                                        {'{0} PTS'.format(level.points)}
                                      </defaulttext>
                                    </tablecell>
                                  </tablerow>
                                );
                              })}
                            </tablebody>
                          </table>
                        </grid>
                        <grid item="">
                          <animationcontroller></animationcontroller>
                        </grid>
                      </grid>
                    </grid>
                    {customRepartitions && customRepartitions.length > 0 && (
                      <grid item="" style="{{" margin:="" '10px'="" }}="">
                        <grid container="" direction="column" spacing="{1}">
                          <grid item="">
                            <defaulttitle>
                              ⚠️ Paliers personnalisés&nbsp;
                              <tooltip title="{customRepartitionsTooltip}" placement="{'right'}">
                                <bluetext style="{{" width:="" 'fit-content'="" }}="" component="{'span'}">
                                  <fontawesomeicon icon="{faInfoCircle}"></fontawesomeicon>
                                </bluetext>
                              </tooltip>
                            </defaulttitle>
                          </grid>
                          <grid item="">
                            <grid container="" direction="column" spacing="{2}"></grid>
                          </grid>
                        </grid>
                      </grid>
                    )}
                  </grid>
                </card>
              </grid>
            </grid>
          </grid>
        )}
        <grid item="" xs="{12}">
          <grid container="" spacing="{1}">
            <grid item="" xs="{12}">
              <defaulttitle isContrast="">
                {intl.formatMessage({
                  id: 'admin.goal.indication.description_area',
                })}
              </defaulttitle>
            </grid>
            <grid item="" xs="{12}">
              <card>
                <grid container="" spacing="{3}">
                  <grid item="" xs="{12}" sm="">
                    <grid container="" spacing="{2}">
                      <grid item="" xs="{12}">
                        <grid container="" spacing="{1}" alignItems="center">
                          <grid item="">
                            <defaulttitle lowercase="">{goal.name}</defaulttitle>
                          </grid>

                          {((goal.definition.kpi.custom && canEdit) ||
                            (goal.definition.kpi.custom &&
                              !canEdit &&
                              goal.definition.kpi.collaborator_editable &&
                              goal.end.toDate2().getTime() >
                                beginningOfLastMonth.getTime())) && (
                            <grid item="" onClick="{openKpiModal}" className="{classes.link}">
                              <fontawesomeicon 5="" icon="{faPencil}" style="{{" marginRight:="" }}=""></fontawesomeicon>
                              {intl.formatMessage({
                                id: 'challenge.kpi_results.edit_results',
                              })}
                            </grid>
                          )}
                        </grid>
                      </grid>
                      <grid item="" xs="{12}">
                        <defaulttext>
                          <fontawesomeicon icon="{faCalendarAlt}"></fontawesomeicon>{' '}
                          {intl
                            .formatMessage({
                              id: 'admin.goal.indication.period_text',
                            })
                            .format(
                              goal.start.toDate2().toLocaleDateString(),
                              goal.end.toDate2().toLocaleDateString()
                            )}
                        </defaulttext>
                      </grid>

                      <grid item="" xs="{12}">
                        <linkify>
                          <richtext initial="{JSON.parse(goal.definition.indication)}" readOnly="{true}" onChange="{()" ==""> {}}
                          />
                        </richtext></linkify>
                      </grid>
                    </grid>
                  </grid>
                  <grid item="" xs="{12}" sm="{5}" md="{3}">
                    <grid container="" spacing="{2}">
                      <grid item="" xs="{12}" zeroMinWidth="">
                        <grid container="" justify="flex-start">
                          <grid item="">
                            <cardmedia image="{goal.icon}" className="{classes.icon}"></cardmedia>
                          </grid>
                          <grid item="">
                            <defaulttext noWrap="">
                              {goal.definition.category.name}
                            </defaulttext>
                          </grid>
                        </grid>
                      </grid>
                      <grid item="" xs="{12}">
                        <defaulttext>
                          <grid container="" spacing="{1}" justify="flex-start">
                            <grid item="">
                              <fontawesomeicon icon="{faBalanceScale}"></fontawesomeicon>
                            </grid>
                            <grid item="">
                              {goal.definition.kpi.unit.symbol
                                ? intl
                                    .formatMessage({
                                      id: 'admin.goal.indication.unit_with_symbol_text',
                                    })
                                    .format(
                                      goal.definition.kpi.unit.name,
                                      goal.definition.kpi.unit.symbol
                                    )
                                : intl
                                    .formatMessage({
                                      id: 'admin.goal.indication.unit_without_symbol_text',
                                    })
                                    .format(goal.definition.kpi.unit.name)}
                            </grid>
                          </grid>
                        </defaulttext>
                      </grid>
                      <grid item="" xs="{12}">
                        <grid container="" spacing="{1}" justify="flex-start">
                          <grid item="">
                            <livestatus live="{goal.definition.live}"></livestatus>
                          </grid>
                          <grid item="">
                            <defaulttext>
                              {intl.formatMessage({
                                id: 'admin.goal.indication.live_label',
                              })}
                              &nbsp;
                              <tooltip title="{intl.formatMessage({" id:="" 'admin.goal.indication.live_info',="" })}="" placement="{'right'}">
                                <bluetext style="{{" width:="" 'fit-content'="" }}="" component="{'span'}">
                                  <fontawesomeicon icon="{faInfoCircle}"></fontawesomeicon>
                                </bluetext>
                              </tooltip>
                            </defaulttext>
                          </grid>
                        </grid>
                      </grid>
                    </grid>
                  </grid>
                </grid>
              </card>
            </grid>
          </grid>
        </grid>
        <grid item="" xs="{12}">
          {!canEdit && <readonlyadvicelist advices="{goal.advices}"></readonlyadvicelist>}
          {AdviceFromCoach && canEdit && (
            <advicelist advices="{goal.advices}" goal="{goal}" type="{type}"></advicelist>
          )}
        </grid>
      </grid>
      <dialog open="{editResultsOpen}" classes="{{" paper:="" classes.kpiResultDialog="" }}="" onClose="{()" ==""> closeKpiModal()}
      >
        <hidden smDown="">
          <iconbutton size="small" onClick="{()" ==""> closeKpiModal()}
            className={classes.dialogCloseIcon}
          >
            <fontawesomeicon icon="{faTimes}"></fontawesomeicon>
          </iconbutton>
        </hidden>
        <hidden mdUp="">
          <iconbutton size="small" onClick="{()" ==""> closeKpiModal()}
            className={classes.dialogCloseIcon}
            style={{ top: 5, right: 5 }}
          >
            <fontawesomeicon icon="{faTimes}"></fontawesomeicon>
          </iconbutton>
        </hidden>
        <grid container="" spacing="{1}" direction="column" style="{{" marginTop:="" '0',="" width:="" '100%'="" }}="">
          <grid item="" style="{{" width:="" '100%'="" }}="">
            {(canEdit ||
              (_.get(goal, 'definition.kpi.collaborator_editable') &&
                goal.end.toDate2().getTime() >
                  beginningOfLastMonth.getTime())) && (
              <kpiresultupdate kpi="{goal.definition.kpi}" onClose="{()" ==""> closeKpiModal()}
                collaboratorEdit={_.get(account, 'role.code') === 'C'}
                start={parseInt(goal.start)}
                end={parseInt(goal.end)}
                participantIds={goal.participantIds}
                participantTeamIds={goal.participantTeamIds}
              />
            )}
          </kpiresultupdate></grid>
        </grid>
      </dialog>
    </div>
  );
};

const mapStateToProps = ({ accountDetail, configList }) => ({
  accountDetail,
  configList,
});

const mapDispatchToProps = (dispatch) => ({
  collaboratorDataListActions: bindActionCreators(
    collaboratorDataListActions,
    dispatch
  ),
  collaboratorInputListActions: bindActionCreators(
    collaboratorInputListActions,
    dispatch
  ),
  configListActions: bindActionCreators(configListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GoalIndication));
