import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullseye,
  faSortAmountDown,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons';
import {
  FlexibleTableCell,
  RankEvolution,
  Table,
  TableBody,
  TableCell,
  FullTableCell,
  TableChip,
  TableHead,
  TableHeadCell,
  TableRow,
  Avatar,
  TableRowHighlight,
  ThemeWrapper,
} from '../../../../components';
import * as Resources from '../../../../Resources';
import { useIntl } from 'react-intl';
import '../../../../helpers/NumberHelper';
import _ from 'lodash';

const styles = {
  photo: {
    height: 34,
    width: 34,
  },
  tableWrapper: {
    boxShadow: '0 2px 16px 0 rgba(16,61,92,0.25)',
    overflowX: 'auto',
  },
  positionCell: {
    paddingLeft: 5,
    paddingRight: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};

const CollaboratorGoalRankList = ({
  ranks,
  collaboratorId,
  account,
  goal,
  ...props
}) => {
  const intl = useIntl();
  const { classes } = props;
  const mobileScreen = isWidthDown('xs', props.width);

  const hasPoints = _.get(goal, 'levels.length', 0) > 0;
  const cellWidth = mobileScreen ? 90 : 'auto';

  const { successColor, errorColor } = useContext(ThemeWrapper.Context);
  return (
    <div className="{classes.tableWrapper}">
      <table>
        <tablehead>
          <tablerow>
            <tableheadcell colSpan="{2}">
              <fontawesomeicon icon="{faSortAmountDown}"></fontawesomeicon>
            </tableheadcell>
            <tableheadcell colSpan="{2}">
              {intl.formatMessage({ id: 'admin.goal.rank_list.player_column' })}
            </tableheadcell>
            <tableheadcell style="{{" textAlign:="" 'center'="" }}="">
              <fontawesomeicon icon="{faBullseye}"></fontawesomeicon>
            </tableheadcell>
            {hasPoints && goal.allow_ranking_points && (
              <tableheadcell style="{{" textAlign:="" 'center'="" }}="">
                {Resources.COLLABORATOR_GOAL_RANK_LIST_POINTS_COLUMN}
              </tableheadcell>
            )}
            {goal.allow_ranking_latest_value && (
              <tableheadcell style="{{" textAlign:="" 'center'="" }}="">
                <fontawesomeicon icon="{faChartLine}"></fontawesomeicon>
              </tableheadcell>
            )}
          </tablerow>
        </tablehead>
        <tablebody>
          {ranks.map((rank) => {
            const photo = rank.collaborator.photo
              ? rank.collaborator.photo
              : '/assets/img/user/avatar.svg';
            const selected = rank.collaborator
              ? rank.collaborator.id == collaboratorId
              : false;
            const color = !selected ? 'default' : 'primary';
            const TableRowComponent = selected ? TableRowHighlight : TableRow;
            const teamColor = _.get(rank, 'collaborator.team.color.hex');
            return (
              <tablerowcomponent key="{rank.id}" style="{{" background:="" selected="" ?="" '#E4F6E0'="" :="" 'auto'="" }}="">
                <fulltablecell 4="" style="{{" backgroundColor:="" teamColor,="" width:="" 4,="" minWidth:="" }}=""></fulltablecell>
                <fulltablecell>
                  <div 60="" 90="" className="{classes.positionCell}" style="{{" width:="" mobileScreen="" ?="" :="" }}="">
                    <span>
                      <tablechip color="default" label="{rank.rank" ?="" rank.rank="" :="" '-'}=""></tablechip>
                    </span>
                    {rank.evolution !== 0 && (
                      <react.fragment>
                        <span 8="" style="{{" marginLeft:="" }}="">
                          <rankevolution evolution="{rank.evolution}"></rankevolution>
                        </span>
                        <span style="{{" marginLeft:="" 3,="" color:="" rank.evolution=""> 0
                                ? successColor
                                : rank.evolution < 0
                                ? errorColor
                                : 'auto',
                          }}
                        >
                          {Math.abs(rank.evolution)}
                        </span>
                      </react.fragment>
                    )}
                  </div>
                </fulltablecell>
                <tablecell 50="" style="{{" width:="" }}="">
                  <avatar src="{photo}" className="{classes.photo}" entityId="{_.get(rank," 'collaborator.id')}="" fallbackName="{_.get(rank," 'collaborator.fullname')}=""></avatar>
                </tablecell>
                <flexibletablecell color="{color}" style="{{" minWidth:="" cellWidth,="" maxWidth:="" cellWidth="" }}="">
                  {mobileScreen ? (
                    <react.fragment>
                      <div style="{{" whiteSpace:="" 'nowrap',="" overflow:="" 'hidden',="" textOverflow:="" 'ellipsis',="" }}="">
                        {rank.collaborator.firstname}
                      </div>
                      <div style="{{" whiteSpace:="" 'nowrap',="" overflow:="" 'hidden',="" textOverflow:="" 'ellipsis',="" }}="">
                        {rank.collaborator.lastname}
                      </div>
                    </react.fragment>
                  ) : (
                    <react.fragment>
                      <div style="{{" whiteSpace:="" 'nowrap',="" overflow:="" 'hidden',="" textOverflow:="" 'ellipsis',="" }}="">
                        {rank.collaborator.firstname}{' '}
                        {rank.collaborator.lastname}
                      </div>
                    </react.fragment>
                  )}
                </flexibletablecell>
                <tablecell color="{color}" style="{{" textAlign:="" 'center',="" verticalAlign:="" 'middle'="" }}="">
                  <div>
                    {parseFloat(
                      ((rank.counter / rank.target) * 100).toFixed(2)
                    )}
                    %
                  </div>
                  {(_.get(account, 'role.code') === 'A' ||
                    _.get(account, 'role.code') === 'M' ||
                    _.get(account, 'role.code') === 'S') && (
                    <div style="{{" fontSize:="" 10,="" opacity:="" 0.6="" }}="">
                      {rank.counter.toLocaleString()}&nbsp;/&nbsp;
                      {rank.target.toLocaleString()}
                    </div>
                  )}
                </tablecell>
                {hasPoints && goal.allow_ranking_points && (
                  <tablecell color="{color}" style="{{" textAlign:="" 'center'="" }}="">
                    {rank.points}
                  </tablecell>
                )}
                {goal.allow_ranking_latest_value && (
                  <tablecell color="{color}" style="{{" textAlign:="" 'center',="" color:="" rank.latest_result="" ?="" successColor="" :="" 'auto',="" }}="">
                    {rank.latest_result ? `+${rank.latest_result}` : 0}
                  </tablecell>
                )}
              </tablerowcomponent>
            );
          })}
        </tablebody>
      </table>
    </div>
  );
};

export default withStyles(styles)(withWidth()(CollaboratorGoalRankList));
