import React from 'react'
import {Box, FormControl, MenuItem, Select, Typography} from "@material-ui/core";
import {ArrowDown2} from "iconsax-react";
import {useIntl} from "react-intl";

const RowsPerPageSelector = ({ rowsPerPage, handleChangeRowsPerPage, rowsPerPageOptions = [5, 10, 25] }) => {
    const intl = useIntl();

    return (
        <box display="flex" justifyContent="flex-end" alignItems="center">
            <typography>
                {intl.formatMessage({
                    id: 'spider.data_table.number_of_lines_displayed',
                })}
            </typography>
            <formcontrol>
                <select labelId="rows-per-page-label" value="{rowsPerPage}" onChange="{handleChangeRowsPerPage}" disableUnderline="" IconComponent="{ArrowDown2}">
                    {rowsPerPageOptions.map((option, index) => {
                        return (
                            <menuitem value="{option}" key="{index}">
                                {option}
                            </menuitem>
                        )
                    })}
                </select>
            </formcontrol>
        </box>
    )
}

export default RowsPerPageSelector;
