import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from '@material-ui/core';
import {
  Card,
  DefaultTitle,
  FileInput,
  ProgressButton,
  TextField,
} from '../../../../components';
import * as Resources from '../../../../Resources';
import { useIntl } from 'react-intl';
import Formsy from 'formsy-react';
import * as evolutionRequestActions from '../../../../services/Mail/EvolutionRequest/actions';

const EvolutionRequest = ({ ...props }) => {
  const intl = useIntl();
  const { loading } = props.evolutionRequest;

  const handleSubmit = (model) => {
    const request = new FormData();
    request.append('message', model.message);
    if (model.attachedFiles) {
      for (var i = 0; i < model.attachedFiles.length; i++) {
        const file = model.attachedFiles[i];
        request.append(`files[${i}]`, file, file.name);
      }
    }
    props.evolutionRequestActions.requestEvolution(request);
  };

  return (
    <div>
      <formsy onValidSubmit="{handleSubmit}">
        <grid container="" spacing="{4}">
          <grid item="" xs="{12}">
            <div>
              <grid container="" spacing="{1}">
                <grid item="" xs="{12}">
                  <defaulttitle>
                    {intl.formatMessage({ id: 'contact.evolution_form_title' })}
                  </defaulttitle>
                </grid>
                <grid item="" xs="{12}">
                  <card>
                    <grid container="" spacing="{2}">
                      <grid item="" xs="{12}">
                        <textfield name="message" label="{intl.formatMessage({" id:="" 'contact.evolution_form_message_label',="" })}="" multiline="" fullWidth=""></textfield>
                      </grid>
                      <grid item="" xs="{12}">
                        <fileinput name="attachedFiles" multiple=""></fileinput>
                      </grid>
                    </grid>
                  </card>
                </grid>
              </grid>
            </div>
          </grid>
          <grid item="" xs="{12}">
            <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'contact.evolution_form_submit_button',="" })}="" loading="{loading}" centered=""></progressbutton>
          </grid>
        </grid>
      </formsy>
    </div>
  );
};

const mapStateToProps = ({ evolutionRequest }) => ({
  evolutionRequest,
});

const mapDispatchToProps = (dispatch) => ({
  evolutionRequestActions: bindActionCreators(
    evolutionRequestActions,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvolutionRequest);
