import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import {
  AppBarSubTitle,
  BlueText,
  Card,
  DefaultText,
  ErrorText,
  BigText,
  InfoText,
  Loader,
  MainLayoutComponent,
  ProgressButton,
  Select,
  Switch,
  TextField,
  HiddenInput,
  Tooltip,
  Stepper,
  RichTextField,
  TransferList,
  GreenRadio,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DefaultTitle,
  LabelText,
} from '../../../../../../../../components';
import { CategoryIconInput } from '../../../../../../components';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const LevelStep = ({ initial, levels, remainingPoints }) => {
  const [badgeLevels, setLevels] = useState(initial || []);
  const intl = useIntl();

  const addLevel = () => {
    setLevels([
      ...badgeLevels,
      {
        rank: null,
        target: 0,
        points: 0,
        level: null,
        percentage: 0,
        isNew: true,
      },
    ]);
  };
  const removeLevel = (index) => {
    setLevels(badgeLevels.filter((level, i) => i !== index));
  };

  const currentRemainingPoints =
    remainingPoints - _.sum(badgeLevels.map((level) => level.points));

  return (
    <react.fragment>
      <grid container="" spacing="{2}" justify="center">
        <grid item="" xs="{12}">
          <card>
            <grid container="">
              <grid item="">
                <infotext>Points restants à attribuer</infotext>
                <defaulttext>{currentRemainingPoints} PTS</defaulttext>
              </grid>
            </grid>
          </card>
        </grid>
        <grid item="" xs="{12}">
          <grid container="" spacing="{1}">
            <grid item="" xs="{12}">
              <defaulttitle>
                {intl.formatMessage({ id: 'badge.creation.levels_title' })}
              </defaulttitle>
            </grid>
            <grid item="" xs="{12}">
              <grid container="" spacing="{2}">
                {badgeLevels.map((level, index) => {
                  const number = index + 1;
                  const disabled = level.percentage > 0;
                  const removeButtonVisibility = disabled
                    ? 'collapse'
                    : 'visible';

                  return (
                    <grid key="{level.id}" item="" container="" xs="{6}" spacing="{2}">
                      <grid item="" xs="{12}">
                        <defaulttitle>Palier {number}</defaulttitle>
                      </grid>
                      <grid item="" xs="{12}">
                        <card>
                          <grid container="" spacing="{2}">
                            <grid item="" xs="">
                              <textfield type="number" name="{`target[${index}]`}" label="Objectif" initial="{level.target}" disabled="{disabled}" fullWidth="" required="" validations="isMoreThanOrEquals:0" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" id:="" 'common.form.required_error',="" }),="" isMoreThanOrEquals:="" "L'objectif="" doit="" être="" supérieur="" ou="" égal="" à="" 0",="" }}=""></textfield>
                            </grid>
                            <grid item="" xs="">
                              <select name="{`level[${index}]`}" label="Condition" options="{levels}" optionValueName="id" optionTextName="number" optionTextPrefix="Lvl " initial="{level.level}" disabled="{disabled}" fullWidth="" required="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" id:="" 'common.form.required_error',="" }),="" }}=""></select>
                            </grid>
                            <grid item="" xs="">
                              <textfield type="number" name="{`points[${index}]`}" label="Nbre de point si atteint" initial="{level.points}" disabled="{disabled}" fullWidth="" required="" onChange="{(value)" =="">
                                  setLevels(
                                    badgeLevels.map((level, currentIndex) => {
                                      if (index === currentIndex) {
                                        return Object.assign({}, level, {
                                          points: value,
                                        });
                                      }
                                      return level;
                                    }),
                                  )
                                }
                                validations="isMoreThanOrEquals:0"
                                validationErrors={{
                                  isDefaultRequiredValue: intl.formatMessage({
                                    id: 'common.form.required_error',
                                  }),
                                  isMoreThanOrEquals:
                                    'Le nombre de points doit être supérieur ou égal à 0',
                                }}
                              />
                            </textfield></grid>
                            {index === badgeLevels.length - 1 && (
                              <grid item="" style="{{" visibility:="" removeButtonVisibility="" }}="">
                                <iconbutton 16="" size="small" style="{{" marginTop:="" }}="" onClick="{()" ==""> removeLevel(index)}
                                >
                                  <fontawesomeicon icon="{faTrashAlt}"></fontawesomeicon>
                                </iconbutton>
                              </grid>
                            )}
                          </grid>
                        </card>
                      </grid>
                    </grid>
                  );
                })}

                <grid item="" xs="{6}" container="" spacing="{2}">
                  <grid item="" xs="{12}"></grid>
                  <grid item="" xs="{12}">
                    <div onClick="{addLevel}" style="{{" cursor:="" 'pointer'="" }}="">
                      <card>
                        <defaulttext style="{{" textAlign:="" 'center',="" fontSize:="" 18,="" color:="" '#00E58D',="" }}="" lowercase="">
                          <fontawesomeicon icon="{faPlus}" style="{{" color:="" '#00E58D',="" textAlign:="" 'center'="" }}=""></fontawesomeicon>
                        </defaulttext>
                      </card>
                    </div>
                  </grid>
                </grid>
              </grid>
            </grid>
          </grid>
        </grid>
        <grid item="">
          <errortext lowercase="">
            <hiddeninput name="maxPoints" validations="isMoreThanOrEquals:0" validationErrors="{{" isMoreThanOrEquals:="" 'Les="" points="" alloués="" aux="" rangs="" ne="" doivent="" pas="" excéder="" le="" nombre="" de="" disponibles',="" }}="" value="{currentRemainingPoints}"></hiddeninput>
          </errortext>
        </grid>
      </grid>
    </react.fragment>
  );
};

export default LevelStep;
