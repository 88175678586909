import React from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';

import {GuestRoutes, UserRoutes} from './components';
import * as scenes from './scenes';
import {AuthCallback} from '../auth';

export default () => {
    return (
        <browserrouter>
            <switch>
                {/* Auth routes */}
                <userroutes exact="" path="/" component="{AuthCallback}/">
                <guestroutes exact="" path="/callback" component="{AuthCallback}/">
                <userroutes exact="" path="/nodes" component="{scenes.ContractSelection}" useGuestLayout=""></userroutes>

                {/* Home routes */}
                <userroutes exact="" path="/nodes/:contract/home" component="{scenes.Home}/">

                {/* Users routes */}
                <userroutes exact="" path="/nodes/:contract/users" component="{scenes.Participants}/">

                {/* Points routes */}
                <userroutes exact="" path="/nodes/:contract/points" component="{scenes.Points}/">

                {/* Administration routes */}
                <userroutes exact="" path="/nodes/:contract/administration/:tab" component="{scenes.Admin}"></userroutes>

                {/* Account activation routes */}
                <guestroutes exact="" path="/nodes/:contract/activate" component="{scenes.AccountActivation}/">
                <guestroutes exact="" path="/nodes/:contract/finalize-activation" component="{scenes.AccountActivationFinalization}/">
                <guestroutes exact="" path="/nodes/:contract/account-activation-key-expired" component="{scenes.AccountActivationKeyExpired}/">
                <guestroutes exact="" path="/nodes/:contract/inaccessible" component="{scenes.ContractInaccessible}/">
            </guestroutes></guestroutes></guestroutes></guestroutes></userroutes></userroutes></userroutes></guestroutes></userroutes></switch>
        </browserrouter>
    );
};
