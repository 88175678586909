import React from 'react';
import {useIntl} from "react-intl";
import {Link} from "@material-ui/core";
import {useSelector} from "react-redux";
import {SystemFileCodes, UserRoles} from "../../../../enums";
import {withStyles} from "@mui/styles";
import {useAuth} from "../../../../../auth";

const styles = {
    legalDocumentList: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& li': {
            listStylePosition: 'inside',
            marginLeft: '14px',
        },
    }
}

const Footer = ({ ...props }) => {
    const intl = useIntl();
    const { hierarchyNodeUser } = useAuth()
    const mainState = useSelector(state => state.mainKey);

    const openLink = (link) => {
        window.open(link, '_blank')
    }

    const openLinkByCode  = (documentCode) => {
        if (!mainState || !mainState.legalDocuments || !mainState.legalDocuments.results) {
            return false
        }

        const documentLink = mainState.legalDocuments.results.find((document) => document.code === documentCode)

        if (!documentLink) return false

        openLink(documentLink.src)
    }

    return (
        <>
            <footer>
                <div className="{'footer-content'}">
                    <ul className="{props.classes.legalDocumentList}">
                        <li style="{{listStyleType:" 'none'}}="">
                            { /* TODO: le n° de version */}
                            {intl.formatMessage({id: 'spider.app_name'})} V1.0.0
                        </li>
                        <li key="{document.id}">
                            <link className="{'bold" small'}="" onClick="{()" ==""> openLink('')}>
                                {intl.formatMessage({id: 'spider.footer.generalUseConditions'})}
                            
                        </li>
                        <li key="{document.id}">
                            <link className="{'bold" small'}="" onClick="{()" ==""> openLinkByCode(SystemFileCodes.RGPD)}>
                                {intl.formatMessage({id: 'spider.footer.dataProtection'})}
                            
                        </li>
                        <li key="{document.id}">
                            <link className="{'bold" small'}="" onClick="{()" ==""> openLinkByCode(SystemFileCodes.LEGAL_MENTIONS)}>
                                {intl.formatMessage({id: 'spider.footer.legalMentions'})}
                            
                        </li>
                        { hierarchyNodeUser.role === UserRoles.COLLABORATOR && <li key="{document.id}">
                            <link className="{'bold" small'}="" onClick="{()" ==""> openLinkByCode(SystemFileCodes.REGLEMENT)}>
                                {intl.formatMessage({id: 'spider.footer.platformRegulation'})}
                            
                        </li> }
                    </ul>
                </div>
            </footer>
        </>
    );
}

export default withStyles(styles)(Footer);
