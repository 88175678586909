import React, { useEffect } from "react";
import * as currentCollaboratorBadgeSummaryListActions from "../../../services/CollaboratorBadges/CurrentCollaboratorBadgeSummaryList/actions";
import * as currentPeriodDetailActions from "../../../services/Periods/CurrentPeriodDetail/actions";
import * as badgeListActions from "../../../services/Badges/BadgeList/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { DefaultText, EmptyState, WrapperWidget } from "../../../components";
import { Badge } from "../../../scenes/Collaborators/scenes/CollaboratorDetail/components";
import { Grid, withStyles, makeStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import _ from "lodash";

const style = (theme) => {
  return {
    emptyState: {
      width: "70%",
      margin: "auto",
    },
    spanBadge: {
      fontWeight: "bold",
      textTransform: "none",
      color: theme.palette.primary.main,
    },
  };
};

const BadgeWidget = ({
  badges,
  loading,
  currentCollaboratorBadgeSummaryListActions,
  currentPeriodDetailActions,
  badgeListActions,
  account,
  period,
  adminBadges,
  classes,
}) => {
  const intl = useIntl();
  const numberBadges = `${badges?.length} ${intl
    .formatMessage({ id: "badge.title" })
    .toLowerCase()}`;

  const numberAdminBadges = `${adminBadges?.length} ${intl
    .formatMessage({ id: "badge.title" })
    .toLowerCase()}`;

  useEffect(() => {
    if (
      account.role.code === "A" ||
      account.role.code === "M" ||
      account.role.code === "S"
    ) {
      currentPeriodDetailActions.getCurrentPeriodDetail();
    }
  }, []);

  useEffect(() => {
    if (
      account.role.code === "A" ||
      account.role.code === "M" ||
      account.role.code === "S"
    ) {
      if (period && !adminBadges) {
        badgeListActions.getBadgeList(period.id, {
          simple: true,
          withLevels: true,
        });
      }
    } else if (account.role.code === "C") {
      currentCollaboratorBadgeSummaryListActions.getCurrentCollaboratorBadgeSummaryList(
        account.id
      );
    }
  }, [account.role.code, period, adminBadges]);

  return (
    <wrapperwidget loading="{" (account.role.code="==" "C"="" &&="" (!badges="" ||="" loading))="" !="=" (!adminBadges="" }="" url="{" account.role.code="==" ?="" `="" collaborators="" ${account.id}="" detail`="" :="" badges`="" title="{intl.formatMessage({" id:="" "badge.title"="" })}="">
      {account.role.code === "A" ||
      account.role.code === "M" ||
      account.role.code === "S" ? (
        <div 300="" style="{{" height:="" }}="">
          <defaulttext 15="" lowercase="" align="center" style="{{" fontSize:="" 16,="" marginTop:="" }}="">
            <span className="{classes.spanBadge}">{numberAdminBadges}</span>{" "}
            {intl.formatMessage({ id: "widget.badge.count2" })}
          </defaulttext>
          {adminBadges === 0 ? (
            <grid container="" style="{{" padding:="" 5,="" width:="" "100%",="" height:="" }}="" alignItems="center">
              <grid item="" xs="">
                <emptystate rootClass="{classes.emptyState}"></emptystate>
              </grid>
            </grid>
          ) : (
            <grid 20="" container="" spacing="{1}" justifyContent="center" alignItems="center" style="{{" padding:="" }}="">
              {adminBadges &&
                _.shuffle(adminBadges)
                  .slice(0, 4)
                  .map((badge) => (
                    <grid 15="" item="" xs="{6}" style="{{" paddingBottom:="" }}="">
                      <badge key="{badge.id}" badge="{badge}"></badge>
                    </grid>
                  ))}
            </grid>
          )}
        </div>
      ) : (
        <div 330="" style="{{" height:="" }}="">
          <defaulttext 15="" lowercase="" align="center" style="{{" fontSize:="" 16,="" marginTop:="" }}="">
            {intl.formatMessage({ id: "widget.badge.count1" })}{" "}
            <span className="{classes.spanBadge}">{numberBadges}</span>{" "}
            {intl.formatMessage({ id: "widget.badge.count2" })}
          </defaulttext>
          {badges === 0 ? (
            <grid container="" style="{{" padding:="" 5,="" width:="" "100%",="" height:="" }}="" alignItems="center">
              <grid item="" xs="">
                <emptystate rootClass="{classes.emptyState}"></emptystate>
              </grid>
            </grid>
          ) : (
            <grid 20="" container="" spacing="{1}" justifyContent="center" alignItems="center" style="{{" padding:="" }}="">
              {badges?.slice(0, 4).map((badge) => (
                <grid 15="" item="" xs="{6}" style="{{" paddingBottom:="" }}="">
                  <navlink style="{{" textDecoration:="" "none"="" }}="" to="{`/badges/detail/${badge.levelId}`}">
                    <badge key="{badge.id}" badge="{badge}"></badge>
                  </navlink>
                </grid>
              ))}
            </grid>
          )}
        </div>
      )}
    </wrapperwidget>
  );
};

const mapStateToProps = ({
  currentCollaboratorBadgeSummaryList,
  accountDetail,
  currentPeriodDetail,
  badgeList,
}) => ({
  badges: currentCollaboratorBadgeSummaryList.badges,
  loading: currentCollaboratorBadgeSummaryList.loading,
  account: accountDetail.account,
  period: currentPeriodDetail.period,
  adminBadges: badgeList.badges,
});

const mapDispatchToProps = (dispatch) => ({
  currentCollaboratorBadgeSummaryListActions: bindActionCreators(
    currentCollaboratorBadgeSummaryListActions,
    dispatch
  ),
  currentPeriodDetailActions: bindActionCreators(
    currentPeriodDetailActions,
    dispatch
  ),
  badgeListActions: bindActionCreators(badgeListActions, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(BadgeWidget));
