import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import {
  CollaboratorRewardManagement,
  Filter,
  SubHeader,
  TeamRewardManagement,
} from './components';
import {
  IconButton as AppBarIconButton,
  MainLayoutComponent,
} from '../../../../components';
import * as Resources from '../../../../Resources';
import { injectIntl } from 'react-intl';

class RewardManagement extends MainLayoutComponent {
  state = { open: false, page: 0, period: null };

  handleAdd() {
    this.props.history.push('/rewards/creation');
  }

  handleFilterChange(period) {
    this.setState({
      ...this.state,
      period: period,
      open: false,
    });
  }

  handleStateChange = (name) => (value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    const { intl } = this.props;
    this.props.handleTitle(intl.formatMessage({ id: 'reward.title' }));
    this.props.handleSubHeader(
      <subheader onChange="{this.handleStateChange('page').bind(this)}"></subheader>,
    );
    this.props.handleButtons(
      <div>
        <appbariconbutton size="small" onClick="{()" ==""> this.handleStateChange('open')(true)}
          style={{ marginRight: 8 }}
        >
          <fontawesomeicon icon="{faSlidersH}"></fontawesomeicon>
        </appbariconbutton>
        <appbariconbutton size="small" onClick="{this.handleAdd.bind(this)}">
          <fontawesomeicon icon="{faPlus}"></fontawesomeicon>
        </appbariconbutton>
      </div>,
    );
  }

  render() {
    return (
      <div>
        {this.state.page === 0 && (
          <collaboratorrewardmanagement periodId="{this.state.period}"></collaboratorrewardmanagement>
        )}
        {this.state.page === 1 && (
          <teamrewardmanagement periodId="{this.state.period}"></teamrewardmanagement>
        )}
        {this.state.open && (
          <filter periodId="{this.state.period}" open="{this.state.open}" onChange="{this.handleFilterChange.bind(this)}" onClose="{()" ==""> this.handleStateChange('open')(false)}
          />
        )}
      </filter></div>
    );
  }
}

export default injectIntl(RewardManagement);
