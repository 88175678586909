import React from 'react';
import {Route} from "react-router-dom";

import {UserLayout} from './index';
import {GuestLayout} from '../GuestLayout';
import {AuthProvider} from '../../../../auth';

const UserRoutes = ({component: Component, useGuestLayout = false, ...rest}) => {
    const Layout = !useGuestLayout ? UserLayout : GuestLayout;

    return (
        <route {...rest}="" render="{props" ==""> (
                <authprovider>
                    <layout component="{Component}" {...props}=""></layout>
                </authprovider>
            )}
        />
    );
};

export default UserRoutes;
</route>