import React from 'react';
import {Crown, TickCircle, User} from "iconsax-react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

import {default as HierarchyNodeUserStatus} from '../../../../../../../../../HierarchyNodeUserStatus';
import {UserRoleName} from "../../../../../../../../../Localization";
import {HierarchyNodeUserStatuses, UserRoles} from "../../../../../../../../../../enums";
import {neutralColors, themeColors} from "../../../../../../../../../../themes";
import {useAuth} from "../../../../../../../../../../../auth";

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        alignSelf: 'stretch',
        background: neutralColors.neutralWhite,
        border: `1px solid ${neutralColors.neutral900}`,
        borderRadius: '16px',
        cursor: 'pointer',
        display: 'flex',
        gap: '16px',
        padding: '24px',
        '&.active': {
            border: `2px solid ${themeColors.secondaryRegular}`,
        },
        '&.inactive': {
            border: `1px solid ${neutralColors.neutral450}`,
            cursor: 'not-allowed',
        },
        '& .MuiChip-root': {
            cursor: 'pointer',
        },
        '&.inactive .MuiChip-root': {
            cursor: 'not-allowed',
        }
    },
    content: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '8px',
    },
    title: {
        alignItems: 'center',
        display: 'flex',
        gap: '8px',
        '&.active': {
            color: themeColors.secondaryRegular,
        },
        '&.inactive': {
            color: `${neutralColors.neutral500} !important`,
        }
    },
    role: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    details: {
        color: neutralColors.neutral900,
        fontSize: '18px',
        '&.inactive': {
            color: `${neutralColors.neutral500} !important`,
        }
    },
    activeIcon: {
        color: themeColors.secondaryRegular,
    }
}));

const HierarchyNodeUser = ({onClick, user}) => {
    const {hierarchyNodeUser} = useAuth();
    const classes = useStyles();
    const active = hierarchyNodeUser?.uuid === user.uuid;
    const activeClass = active ? 'active' : '';
    const inactiveClass = user.status === HierarchyNodeUserStatuses.DELETED || user.status === HierarchyNodeUserStatuses.INACTIVE ? 'inactive' : '';

    const renderRoleIcon = () => {
        switch (user.role) {
            case UserRoles.ADMINISTRATOR:
                return <crown size="{24}/">;
            default:
                return <user size="{24}/">;
        }
    }

    return (
        <div key="{user.uuid}" className="{`${classes.root}" ${activeClass}="" ${inactiveClass}`}="" onClick="{onClick}">
            <div className="{classes.content}">
                <div>
                    <typography variant="{'h2'}" component="{'h2'}" className="{`${classes.title}" ${activeClass}="" ${inactiveClass}`}="">
                        {renderRoleIcon()} <span className="{classes.role}"><userrolename role="{user.role}/"></userrolename></span> <hierarchynodeuserstatus status="{user.status}/">
                    </hierarchynodeuserstatus></typography>
                </div>

                <div>
                    <typography className="{`${classes.details}" ${inactiveClass}`}="">
                        {user.firstname} {user.lastname} {user?.first_identifier ? ` - ${user?.first_identifier}` : ''}
                    </typography>
                </div>
            </div>

            {active && <tickcircle size="{32}" className="{classes.activeIcon}/">}
        </tickcircle></div>
    );
}

export default HierarchyNodeUser;
</user></crown>