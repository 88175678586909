import React from 'react';
import {Route} from 'react-router-dom';

import {GuestLayout} from './index';
import {AuthProvider} from '../../../../auth';

const GuestRoutes = ({component: Component, ...rest}) => {
    return (
        <route {...rest}="" render="{props" ==""> (
                <authprovider disableAutoRedirect="{true}">
                    <guestlayout component="{Component}" {...props}=""></guestlayout>
                </authprovider>
            )}
        />
    );
};

export default GuestRoutes;
</route>