import React from 'react';
import { Grid } from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import {
  AccentText,
  Badge,
  BoldSpan,
  Button,
  ProgressButton,
  Card,
  DefaultText,
  DefaultTitle,
  ErrorText,
} from '../../../../components';
import api from '../../../../data/api/api';
import { useIntl } from 'react-intl';
import '../../../../helpers/StringHelper';
import * as Resources from '../../../../Resources';
import { toast } from 'react-toastify';
import _ from 'lodash';

const PointSummary = ({
  points,
  usedPoints,
  validatedValues,
  waitingPoints,
  onTrackingClick,
  orders,
  displayPanopliButton,
  ...props
}) => {
  const intl = useIntl();
  const [panopliLoading, setPanopliLoading] = React.useState(false);
  const usablePoints = points - usedPoints - waitingPoints;

  const usablePointsText = intl
    .formatMessage({ id: 'point_summary.usable_points_value' })
    .format(usablePoints);
  const alignItems = isWidthUp('sm') ? 'flex-end' : 'flex-start';

  return (
    <div>
      <grid container="" spacing="{1}">
        <grid item="" xs="{12}">
          <defaulttitle isContrast="">
            {intl.formatMessage({ id: 'point_summary.title' })}
          </defaulttitle>
        </grid>
        <grid item="" xs="{12}">
          <card>
            <grid container="" spacing="{2}">
              <grid item="" xs="{12}" sm="">
                <grid container="" spacing="{2}">
                  <grid item="" xs="{12}">
                    <defaulttext lowercase="">
                      {intl.formatMessage({ id: 'point_summary.points_label' })}{' '}
                      :{' '}
                      <boldspan component="span" lowercase="">
                        {intl
                          .formatMessage({ id: 'point_summary.points_value' })
                          .format(points || 0)}
                      </boldspan>
                    </defaulttext>
                  </grid>
                  <grid item="" xs="{12}">
                    <defaulttext lowercase="">
                      {intl.formatMessage({
                        id: 'point_summary.used_points_label',
                      })}{' '}
                      :{' '}
                      <boldspan component="span">
                        {intl
                          .formatMessage({
                            id: 'point_summary.used_points_value',
                          })
                          .format(usedPoints || 0)}
                      </boldspan>
                    </defaulttext>
                  </grid>
                  <grid item="" xs="{12}">
                    <defaulttext lowercase="">
                      {intl.formatMessage({
                        id: 'point_summary.validated_values_label',
                      })}{' '}
                      :{' '}
                      <boldspan component="span" lowercase="">
                        {intl
                          .formatMessage({
                            id: 'point_summary.validated_values_value',
                          })
                          .format(validatedValues || 0)}
                      </boldspan>
                    </defaulttext>
                  </grid>
                  <grid item="" xs="{12}">
                    <defaulttext lowercase="">
                      {intl.formatMessage({
                        id: 'point_summary.waiting_points_label',
                      })}{' '}
                      :{' '}
                      <boldspan component="span">
                        {intl
                          .formatMessage({
                            id: 'point_summary.waiting_points_value',
                          })
                          .format(waitingPoints || 0)}
                      </boldspan>
                    </defaulttext>
                  </grid>
                </grid>
              </grid>
              <grid item="" xs="{12}" sm="auto">
                <grid container="" direction="column" justify="space-between" alignItems="{alignItems}" style="{{" height:="" '100%'="" }}="">
                  <grid item="">
                    {usablePoints >= 0 && (
                      <accenttext 16="" style="{{" textTransform:="" 'none',="" fontSize:="" }}="">
                        {intl.formatMessage({
                          id: 'point_summary.usable_points_label',
                        })}{' '}
                        : <boldspan>{usablePointsText}</boldspan>
                      </accenttext>
                    )}
                    {usablePoints < 0 && (
                      <errortext 16="" style="{{" textTransform:="" 'none',="" fontSize:="" }}="">
                        {intl.formatMessage({
                          id: 'point_summary.usable_points_label',
                        })}{' '}
                        : <boldspan>{usablePointsText}</boldspan>
                      </errortext>
                    )}
                  </grid>
                  {onTrackingClick && (
                    <grid item="">
                      <badge badgeContent="{orders}" color="secondary">
                        <button onClick="{onTrackingClick}">
                          {intl.formatMessage({
                            id: 'point_summary.orders_button',
                          })}
                        </button>
                      </badge>
                    </grid>
                  )}
                  {displayPanopliButton && (
                    <grid item="">
                      <progressbutton loading="{panopliLoading}" text="{intl.formatMessage({" id:="" 'point_summary.panopli_button',="" })}="" onClick="{()" ==""> {
                          setPanopliLoading(true);
                          api.partners
                            .panopli_url()
                            .then((response) => {
                              const url = _.get(response, 'data.loginLink');
                              setPanopliLoading(false);
                              if (url) {
                                window.open(url, '_blank');
                                // window.location.href = url;
                              } else {
                                toast.error(
                                  intl.formatMessage({
                                    id: 'point_summary.panopli_error',
                                  })
                                );
                              }
                            })
                            .catch(() => {
                              setPanopliLoading(false);
                              toast.error(
                                intl.formatMessage({
                                  id: 'point_summary.panopli_error',
                                })
                              );
                            });
                        }}
                      />
                    </progressbutton></grid>
                  )}
                </grid>
              </grid>
            </grid>
          </card>
        </grid>
      </grid>
    </div>
  );
};

export default withWidth()(PointSummary);
